import { useState } from "react"
import AppHeaderLayout from "src/shared/components/AppHeaderLayout"
import PageHeader, { PageHeaderType } from "src/shared/components/PageHeader"
import { useApplicationPolicyEvaluationStyles } from "./ApplicationPolicyEvaluation.styles"
import ApplicationPolicyEvaluationForm from "./ApplicationPolicyEvaluationForm"
import ApplicationTroubleshooting from "./ApplicationTroubleshooting"
import PolicyEvaluationResult from "./PolicyEvaluationResult"

const ApplicationPolicyEvaluation: React.FC = () => {
  const classes = useApplicationPolicyEvaluationStyles()
  const [fetchEvaluationResult, setFetchEvaluationResult] = useState(false)
  const [fetchingEvaluationResult, setFetchingEvaluationResult] = useState(false)
  const [evaluationId, setEvaluationId] = useState<null | string>(null)
  const [isAgentless, setIsAgentless] = useState(false)
  const [zeroActivePolicies, setZeroActivePolicies] = useState(true)

  const pageHeaderProps: PageHeaderType = {
    title: "Policy Evaluation & Troubleshooting",
    marginBottom: 17.5,
  }

  return (
    <>
      <AppHeaderLayout
        slots={{
          pageHeader: <PageHeader noPortal title={pageHeaderProps.title} />,
        }}
      />

      <div className={classes.root}>
        <ApplicationPolicyEvaluationForm
          fetchingEvaluationResult={fetchingEvaluationResult}
          setFetchingEvaluationResult={setFetchingEvaluationResult}
          setFetchEvaluationResult={setFetchEvaluationResult}
          setEvaluationId={setEvaluationId}
          setIsAgentless={setIsAgentless}
        />

        {evaluationId && (
          <>
            <PolicyEvaluationResult
              fetchEvaluationResult={fetchEvaluationResult}
              setFetchEvaluationResult={setFetchEvaluationResult}
              setFetchingEvaluationResult={setFetchingEvaluationResult}
              setZeroActivePolicies={setZeroActivePolicies}
            />

            <ApplicationTroubleshooting
              evaluationId={evaluationId}
              isAgentless={isAgentless}
              disableStartBtn={fetchingEvaluationResult ? true : zeroActivePolicies}
            />
          </>
        )}
      </div>
    </>
  )
}

export default ApplicationPolicyEvaluation
