import dayjs, { Dayjs } from "dayjs"
import timezonePlugin from "dayjs/plugin/timezone"
import timezoneList from "../AddEditTimeCondition/timezones.json"

dayjs.extend(timezonePlugin)

const zeroOffset = timezoneList.filter((zone) => zone.utc === "+00:00").map((zone) => zone.tzCode)

export const WeekMapping: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

export const WeekMappingReverse: string[] = Object.keys(WeekMapping)

export const parseTimezone = (timezone: string, val?: string | Dayjs, keepLocal: boolean = false) => {
  if (zeroOffset.includes(timezone)) {
    if (!val) {
      return dayjs.utc()
    }
    return dayjs.utc(val)
  } else {
    if (!val) {
      return dayjs().tz(timezone, keepLocal)
    }
    return dayjs(val).tz(timezone, keepLocal)
  }
}

export const addTimeToDate = (date: Dayjs | null | undefined, time: Dayjs | null | undefined, currentDate?: Dayjs) => {
  let dateTimeCombined = !!date
    ? date.clone().hour(0).minute(0).second(0)
    : currentDate
    ? currentDate.hour(0).minute(0).second(0)
    : null

  if (dateTimeCombined) {
    dateTimeCombined = time?.hour() ? dateTimeCombined.hour(time.hour()) : dateTimeCombined
    dateTimeCombined = time?.minute() ? dateTimeCombined.minute(time.minute()) : dateTimeCombined
    dateTimeCombined = time?.second() ? dateTimeCombined.second(time.second()) : dateTimeCombined
  }

  return dateTimeCombined
}

export const mapDaysToNumberArray = (daysArray: string[]): number[] => {
  // @ts-ignore
  const numArr = daysArray?.map((day) => WeekMapping[day.toLowerCase()]).toSorted((a, b) => a - b)
  return numArr
}

export const mapNumberArrayToDays = (numArray: number[]): string[] => {
  const daysArr = numArray.map((day) => WeekMappingReverse[day - 1])
  return daysArr
}
