import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { useDataGridStyles } from "./DataGrid.styles"

export const DataGridLoader = () => {
  const classes = useDataGridStyles()

  return (
    <div className={classes.loader}>
      <CircularLoader size="xl" />
    </div>
  )
}
