import { createUseStyles } from "react-jss"

export const useLicenseValidationBannerStyle = createUseStyles(() => ({
  messageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 9,
  },
  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  icon: {
    flex: "0 0 24px",
  },
}))
