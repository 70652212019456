import axios, { AxiosRequestConfig } from "axios"
import { CRAAS_BASE_URL } from "src/craas/apiURLs"
import { DEFAULT_ERROR_MESSAGE } from "src/craas/utils/craasUserMessages"
import { keysToCamelCase, keysToSnakeCase } from "src/utils"
import { UAA } from "src/utils/constants"

const REQUEST_HEADERS = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "Content-Type": "application/json",
  Accept: "application/json",
  UAA,
}

const config: AxiosRequestConfig = {
  baseURL: CRAAS_BASE_URL,
  headers: REQUEST_HEADERS,
}

export const craasAxios = axios.create(config)
craasAxios.interceptors.response.use((response) => {
  return response?.data?.type !== "application/octet-stream" ? keysToCamelCase(response) : response
})

const errorResponseHandler = (err: any) => {
  const responseData = err?.response?.data
  if (responseData) return keysToCamelCase(responseData)
  return DEFAULT_ERROR_MESSAGE
}

export const postRequest = async ({ url, data, isFormData }: { url: string; data?: any; isFormData?: boolean }) => {
  try {
    return await craasAxios.post(url, data ? (isFormData ? data : keysToSnakeCase(data)) : undefined)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

export const putRequest = async ({
  url,
  data,
  headers = {},
  responseType,
}: {
  url: string
  data?: any
  headers?: Record<string, string>
  responseType?: "json" | "text" | "arraybuffer" | "blob" | "document" | "stream"
}) => {
  try {
    return await craasAxios.put(url, data ? keysToSnakeCase(data) : undefined, {
      ...(headers ? { headers } : {}),
      ...(responseType ? { responseType } : {}),
    })
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

export const getCraasRequest = async ({
  url,
  headers = {},
  responseType,
}: {
  url: string
  headers?: Record<string, string>
  responseType?: "json" | "text" | "arraybuffer" | "blob" | "document" | "stream"
}) => {
  try {
    return await craasAxios.get(url, {
      ...(headers ? { headers } : {}),
      ...(responseType ? { responseType } : {}),
    })
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

export const deleteRequest = async ({ url }: { url: string }) => {
  try {
    return await craasAxios.delete(url)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

export const patchRequest = async ({ url, data }: { url: string; data?: any }) => {
  try {
    return await craasAxios.patch(url, data ? keysToSnakeCase(data) : undefined)
  } catch (err: any) {
    throw errorResponseHandler(err)
  }
}

export const postFetcher = (payload: any): ((url: string, headers: Record<string, string>) => Promise<any>) => {
  return async (url: string, headers: Record<string, string>): Promise<any> => {
    try {
      const formattedPayload = payload ? keysToSnakeCase(payload) : undefined
      const response = await craasAxios.post(url, formattedPayload, { headers })
      return response.data
    } catch (err) {
      throw errorResponseHandler(err)
    }
  }
}
