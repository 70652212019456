import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Roles } from "src/routes/config/Roles"
import logger from "src/services/logger"
import { RootState } from "src/store"

const DefaultPendoPayload = {
  visitor: {
    id: "",
    role: "",
    email: "",
    full_name: "",
  },
  account: {
    id: "",
    name: "",
    country: "",
    city: "",
    license: "",
    cuid: "",
  },
}

const LoggerWrapper: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const user = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    let payload = DefaultPendoPayload
    if (user.email && user.email !== "" && user.role !== Roles.END_USER) {
      payload = {
        ...payload,
        visitor: {
          id: user.id?.toString() ?? "",
          role: user.role,
          email: user.email,
          full_name: user.name ?? "",
        },
        account: {
          ...payload.account,
          id: user.workspaceData?.companyId,
          name: user.workspaceData?.companyName,
          country: user?.workspaceData?.country ?? "",
          cuid: user?.workspaceData?.cuid === "UNKNOWN_CUID" ? "" : user?.workspaceData?.cuid,
        },
      }
      logger.initialize(payload)
    }
  }, [user])

  return <>{children}</>
}

export default LoggerWrapper
