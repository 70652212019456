import { ALPHA_NUM_WO_HYP_REGEX, URL_WITH_PROTOCOL_REGEX } from "src/utils/constants"
import * as yup from "yup"
import {
  ALPHANUMERIC_SPACE_HYPHEN_REGEX,
  DEVICE_ALIAS_REGEX,
  MAC_ADDRESS_REGEX,
  PARTIAL_MAC_ADDRESS,
} from "./constants"
import { ERROR_MESSAGES } from "./craasUserMessages"

export const deployRadSecProxyValidation = yup.object().shape({
  name: yup
    .string()
    .required("RadSec Proxy Name is Required")
    .matches(ALPHANUMERIC_SPACE_HYPHEN_REGEX, ERROR_MESSAGES.INVALID_NAME_INPUT)
    .min(1, "RadSec Proxy Name must not be smaller than 1 character")
    .max(31, "RadSec Proxy Name must not be longer than 31 characters"),
  site: yup.object().shape({ id: yup.string(), name: yup.string() }).required("Network Site is required"),
  certificatesRotationTimeInDays: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Certificate Rotation Time is required")
    .min(30, "Certificate Rotation Time in days must be greater than or equal to 30")
    .max(365, "Certificate Rotation Time in days must be less than or equal to 365"),
  secret: yup
    .string()
    .required("Shared secret is required")
    .matches(ALPHA_NUM_WO_HYP_REGEX, "Shared Secret can only include alphanumeric characters")
    .min(3, "Please ensure shared secret is between 3 and 32 characters in length")
    .max(32, "Please ensure shared secret is between 3 and 32 characters in length"),
})

export const addDevicesValidation = yup.object().shape({
  macAddress: yup.string().required("MAC Address is required").matches(MAC_ADDRESS_REGEX, "Invalid MAC Address"),
  description: yup.string().max(255, "Description must not be longer than 255 characters"),
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

export const addCustomMacValidation = yup.object().shape({
  partialMacAddress: yup
    .string()
    .required("Partial MAC Address is required")
    .matches(PARTIAL_MAC_ADDRESS, "Invalid MAC Address"),
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

export const addMacOuiValidation = yup.object().shape({
  alias: yup
    .string()
    .notRequired()
    .test(
      "is-valid-alias",
      "Alias must contain only alphanumeric characters, hyphens, underscores, and periods.",
      (value) => value === undefined || value === "" || DEVICE_ALIAS_REGEX.test(value),
    )
    .test(
      "min-length",
      "Alias must not be smaller than 3 characters",
      (value) => value === undefined || value === "" || value.length >= 3,
    )
    .test(
      "max-length",
      "Alias must not be longer than 31 characters",
      (value) => value === undefined || value === "" || value.length <= 31,
    ),
})

export const connectDevicesValidation = yup.object().shape({
  deviceName: yup
    .string()
    .required("Device Name is required")
    .min(1, "Device Name must not be smaller than 1 character")
    .max(31, "Device Name must not be longer than 31 characters"),
  serialNumber: yup.string().required("Serial Number is required"),
})

export const connectWithOcspResponderValidation = yup.object().shape({
  url: yup.string().required("URL is Required").matches(URL_WITH_PROTOCOL_REGEX, "Invalid URL"),
})

export const matchingCriteriaForClientsValidation = yup.object().shape({
  //Just uncoment if we are showing matcher from UI
  // matcher: yup
  //   .string()
  //   .required("Pattern is required")
  //   .matches(/^([\S]*)([*])+([\S]*)$/, "Pattern must be without spaces and include at least one asterisk (*)")
  //   .max(63, "Please ensure that the patterns falls within the range of 1 to 63"),
})

export const rolloutUpgradeValidation = yup.object().shape({
  connectorVersionCheck: yup.boolean(),
  proxyVersionCheck: yup.boolean(),
})
