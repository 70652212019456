import { createUseStyles } from "react-jss"
import { ZtnaButtonProps } from "./ZtnaButton.types"

export const useZtnaButtonStyles = createUseStyles(() => ({
  button: {
    alignItems: "center",
    display: "flex",
    gap: 10,
  },
  customContentColor: {
    color: (props: ZtnaButtonProps) => props?.contentColor,
  },
}))
