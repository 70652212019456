import consoleLogger from "./console"
import pendoLogger from "./pendo"
import { Logger } from "./types"

/**
 * Logging module
 * This file provides the public API and logs to
 * the correct place based on the current environment
 */
const production: boolean = process.env.NODE_ENV === "production"

const logger: Logger = production ? pendoLogger : consoleLogger

export default logger
