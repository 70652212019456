import axios, { AxiosResponse } from "axios"
import { hideToaster } from "src/components/TopNav"
import { XIQ_PROFILES } from "src/constants"
import { END_USER_BASE_ROUTE_PREFIX, Roles } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { getCookie, keysToCamelCase } from "src/utils"
import {
  DEFAULT_CANCEL_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  FEATURE_FLAGS_LOCAL_STORAGE_KEY,
  UAA,
} from "src/utils/constants"
import { getExternalApiRdc } from "./api/swrHooks/useAlerts"

const ztnaAxios = axios

export const REQUEST_HEADERS = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "Content-Type": "application/json",
  Accept: "application/json",
  UAA,
}

export const API_URL = process.env.NODE_ENV === "development" ? "" : `${window.location.origin}`
export const XIQ_API_URL: string = getExternalApiRdc("cls") ?? ""

class CustomError extends Error {
  response: any

  constructor(message: string, response: any) {
    super(message)
    this.response = response
    this.name = "CustomError"
  }
}

export const getRequest = <T = any>(
  request: string,
  headers?: any,
  useBaseURL: boolean = true,
): Promise<AxiosResponse<T>> => {
  const updatedHeaders = { ...REQUEST_HEADERS, ...headers }
  const url = useBaseURL ? API_URL + request : request
  return ztnaAxios.get(url, {
    headers: updatedHeaders,
  })
}

export const postRequest = <T = any>(
  request: string,
  body?: any,
  headers?: any,
  useBaseURL: boolean = true,
  useDefaultHeaders: boolean = true,
): Promise<AxiosResponse<T>> => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = useDefaultHeaders ? { ...REQUEST_HEADERS, ...headers } : headers
  if (token) updatedHeaders["X-CSRFToken"] = token
  const url = useBaseURL ? API_URL + request : request
  return ztnaAxios.post(url, body, {
    headers: updatedHeaders,
  })
}

export const patchRequest = (request: string, body?: any, headers?: any): Promise<AxiosResponse<any>> => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = { ...REQUEST_HEADERS, ...headers }
  if (token) updatedHeaders["X-CSRFToken"] = token
  return ztnaAxios.patch(API_URL + request, body, {
    headers: updatedHeaders,
  })
}

export const deleteRequest = (request: string, body?: any): Promise<AxiosResponse<any>> => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = { ...REQUEST_HEADERS }
  if (token) updatedHeaders["X-CSRFToken"] = token
  return ztnaAxios.delete(API_URL + request, {
    headers: updatedHeaders,
    data: body,
  })
}

export const putRequest = (request: string, body?: any): Promise<AxiosResponse<any>> => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = { ...REQUEST_HEADERS }
  if (token) updatedHeaders["X-CSRFToken"] = token
  return ztnaAxios.put(API_URL + request, body, {
    headers: updatedHeaders,
  })
}

const errorHandler = (error: any): any => {
  const { role = null } = navigator.cookieEnabled
    ? JSON.parse(localStorage.getItem("userData") || JSON.stringify({}))
    : {}

  if (error?.response?.status === 401 || error?.response?.status === 409) {
    if (!role && !error?.config?.url.includes("/accounts/validate/end-user/")) {
      throw Object.assign(new Error(error))
    }

    const url = error?.config?.url || ""
    const isLoginPage = url.includes("/accounts/login/")
    const isSignUpPage = url.includes("/accounts/sign-up/")
    const isInviteSignUpPage = url.includes("/accounts/invite/signup/")
    const isProfilePage = url.includes("/accounts/profile/")
    const isUserInfoPage = url.includes("/accounts/user-info")
    const isXIQCookieExpire = url.includes("accounts/xiq-cookies/")
    const featureFlags = url.includes("feature-flags")

    if (
      isLoginPage ||
      isSignUpPage ||
      isInviteSignUpPage ||
      isProfilePage ||
      isUserInfoPage ||
      isXIQCookieExpire ||
      featureFlags
    ) {
      throw Object.assign(new Error(error))
    }

    if (window.location.href.includes(END_USER_BASE_ROUTE_PREFIX) && isTenantRole(role)) {
      window.location.href = `${window.location.origin}/forbidden-page`
      hideToaster()
      throw Object.assign(new Error(error))
    }

    if (!url.includes("/accounts/validate/end-user/") && !window.location.href.includes("/forbidden-page")) {
      localStorage.removeItem("userData")
      localStorage.removeItem("eulaAccepted")
      localStorage.removeItem(FEATURE_FLAGS_LOCAL_STORAGE_KEY)
    }

    if (!window.location.href.includes("/forbidden-page")) {
      window.location.href =
        error.response.status === 401
          ? isTenantRole(role) && !error?.config?.url?.includes("/accounts/validate/end-user/")
            ? `${window.location.origin}/xiq-session-expire`
            : role === Roles.SUPER_ADMIN
            ? `${window.location.origin}/console/login`
            : `${window.location.origin}/login`
          : `${window.location.origin}/permissions-updated`
      hideToaster()
    }
  }

  if (error?.response?.status === undefined) throw Object.assign(new Error(DEFAULT_CANCEL_MESSAGE), { ...error })

  if (
    error?.response?.status !== 409 &&
    error?.config?.url.includes("/accounts/login/") &&
    window.location.href.includes("/zta")
  ) {
    throw new CustomError("An error occurred", error?.response)
  }

  if (error?.response?.status !== 409)
    throw Object.assign(new Error(error?.response?.data?.description || DEFAULT_ERROR_MESSAGE), { ...error })
}

const responseHandler = (res: any): any => {
  if (res?.config?.url === API_URL + XIQ_PROFILES && res?.config?.method === "post") {
    return res
  }
  const parsedResponse = keysToCamelCase(res)
  return parsedResponse
}

ztnaAxios.interceptors.request.use(
  (config) => {
    config.headers.UAAV = `${localStorage.getItem("UAAV")}`
    return config
  },
  (error) => errorHandler(error),
)

ztnaAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
)

export default ztnaAxios
