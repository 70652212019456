import { ACCOUNTS_XCD_USER_INFO } from "src/constants"
import useFetch from "src/hooks/useFetch"

const useRefreshBrowserCookie = ({ shouldFetch }: { shouldFetch: boolean }) => {
  useFetch({
    apiUrl: shouldFetch && process.env.NODE_ENV !== "development" ? ACCOUNTS_XCD_USER_INFO : null,
    options: { refreshInterval: 30000 },
  })
}

export default useRefreshBrowserCookie
