import { createUseStyles } from "react-jss"

export const useEnSelectStyles = createUseStyles((theme) => ({
  noOptionText: {
    padding: "6px 10px",
    fontSize: 14,
  },
  creationBtn: {
    color: theme.color.content.accentDefault,
    fontWeight: 600,
  },
  editableOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  editableLabel: {
    width: 450,
  },
}))
