import { createUseStyles } from "react-jss"

export const useRadioGroupStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  iconWrapper: {
    marginTop: 4,
  },
}))
