import { createUseStyles } from "react-jss"

export const useZtnaEnTimePickerStyles = createUseStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
  fullWidthInput: {
    flex: 1,
  },
}))
