import { SEARCH_ACCESS_GROUP_USERS_URL } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { IconNameType } from "src/shared/components/Icons"

export type UserGroupUserType = {
  id: number
  email: string
  isSelected?: boolean
  isDeleted?: boolean
  status?: string
}

type UseSearchUsersResponseType = {
  users: (UserGroupUserType & {
    isSynced: false
    syncedTooltip: string
    syncedIcon: string
    idpCompanyType?: "GSuite" | "Azure" | "OnPremAzure"
  })[]
  idpCompanyType?: "GSuite" | "Azure" | "OnPremAzure"
}

type UseSearchUsersParsedType = (UserGroupUserType & {
  isSynced: boolean
  syncedIcon: IconNameType | null
  syncedTooltip: string
})[]

const parser = (data: UseSearchUsersResponseType) =>
  data?.users?.map(({ id, email, isSynced }) => ({
    id,
    email,
    isSynced,
    syncedIcon: !!isSynced ? ((data?.idpCompanyType === "GSuite" ? "googleSuite" : "synced") as IconNameType) : null,
    syncedTooltip: "AD Synced User Group",
  }))

type UseSearchUsersType = {
  searchParam?: string
  status?: string
}

const useSearchUsers = ({ searchParam = "", status }: UseSearchUsersType) => {
  const { data, error, isValidating } = useFetch<UseSearchUsersResponseType, UseSearchUsersParsedType>({
    apiUrl: `${SEARCH_ACCESS_GROUP_USERS_URL}?q=${searchParam}&status=${status}`,
    parser,
  })

  return {
    data: data || [],
    error,
    isLoading: !data && !error,
    isValidating,
  }
}

export default useSearchUsers
