import { createUseStyles } from "react-jss"
import { PaperProps } from "./Paper"

type PaperStyleProps = Omit<PaperProps, "contentClass">

export const usePaperStyles = createUseStyles<string, PaperStyleProps>((theme) => ({
  root: {
    backgroundColor: ({ surface = 1 }) =>
      surface === "transparent"
        ? theme.color.background.transparentEmphasis
        : theme.color.background[`surfaceElevation${surface}`],

    width: "100%",
    height: "100%",
    display: "inline-block",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: ({ raised = true }) =>
      raised
        ? "0px 2px 2px 0px rgba(30, 31, 42, 0.24), 0px 4px 4px 0px rgba(30, 31, 42, 0.24), 0px 8px 8px 0px rgba(30, 31, 42, 0.24)"
        : "none",
    // border: `1px solid ${theme.color.border.divider}`,
    // borderRadius: `calc(${theme.shape.borderRadius.xs} * 12)`,
    borderRadius: "4px",
    padding: ({ padding }) =>
      padding ? (Array.isArray(padding) ? theme.spacing(padding[0], padding[1]) : theme.spacing(padding)) : 0,
    // height: 324
  },
}))
