import { createUseStyles } from "react-jss"
export const useZtnaLaunchPageStyles = createUseStyles((theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    gridTemplate: "row",
    alignContent: "end",
    gridTemplateRows: "0.5fr 1fr",
    display: "grid",
  },
  backgroundImage: {
    height: 200,
    width: "100%",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      height: 80,
    },
    [theme.breakpoints.up("sm")]: {
      height: 160,
    },
    [theme.breakpoints.up("md")]: {
      height: 250,
    },
  },
  groupImage: {
    display: "flex",
    height: 105,
    width: "100%",
    alignSelf: "center",
    backgroundColor: "transparent !important",
    [theme.breakpoints.down("xs")]: {
      width: "55%",
      height: 100,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    alignItems: "center",
    gap: 10,
  },
  bottomText: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    position: "absolute",
    bottom: 0,
  },
  linkStyle: {
    color: theme.color.primary[600],
  },
  greyText: {
    color: theme.color.grey[700],
  },
  footerText: {
    //styleName: Content/Medium - 16/Regular;
    fontSize: 16,
    fontWeight: 400,
    color: theme.color.neutralDark[12],
  },
}))
