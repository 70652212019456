import fetcher from "../fetcher"

const SWRGlobalConfig = {
  fetcher,
  revalidateOnFocus: false,
  revalidateOnMount: true,
  onErrorRetry: (...args: any[]): any => {
    const {
      0: error,
      3: revalidate,
      4: { retryCount },
    } = args
    // Never retry on 404.
    if (error?.response?.status === 404 || error?.response?.status === 403 || error?.response?.status === 401) return

    if (retryCount >= 10)
      // Only retry up to 10 times.
      return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000)
  },
}

export default SWRGlobalConfig
