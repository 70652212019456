import { createUseStyles } from "react-jss"

export const useDialogActionsStyles = createUseStyles((theme) => ({
  dialogActionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
    minHeight: 72,
    position: "sticky",
    bottom: 0,
    backgroundColor: theme.color.background.surfaceElevation1,
    zIndex: theme.zIndex.appBar,

    marginTop: theme.spacing(1),
    outline: `2px solid ${theme.color.background.surfaceElevation1}`,
  },
}))
