import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import CircularLoader from "../CicularLoader/CircularLoader"
import { useLoadingSlateStyles } from "./LoadingSlate.styles"

interface LoadingSlateProps {
  title?: string
  text?: string
}

const LoadingSlate = (props: LoadingSlateProps) => {
  const classes = useLoadingSlateStyles()
  const { title, text } = props

  return (
    <div className={classes.loadingContainer}>
      <CircularLoader size="xl" />
      <div>
        {title && <ENHeading>{title}</ENHeading>}
        {text && (
          <ENTextPassage>
            <p>{text}</p>
          </ENTextPassage>
        )}
      </div>
    </div>
  )
}

export default LoadingSlate
