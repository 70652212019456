export const KeyIcon: React.FC<{ width?: string; height?: string; color?: string }> = ({
  width = "11.197",
  height = "11.197",
  color = "#009fb8",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 11.197 11.197">
    <path
      data-name="Icon awesome-key"
      d="M11.2 3.849a3.851 3.851 0 0 1-4.57 3.782l-.525.591a.525.525 0 0 1-.392.176H4.9v.875a.525.525 0 0 1-.525.525H3.5v.875a.525.525 0 0 1-.525.525H.525A.525.525 0 0 1 0 10.672V8.965a.525.525 0 0 1 .154-.371l3.538-3.538a3.849 3.849 0 1 1 7.5-1.206zm-4.81-.085a1.05 1.05 0 1 0 1.05-1.05 1.05 1.05 0 0 0-1.052 1.05z"
      fill={color}
    />
  </svg>
)
