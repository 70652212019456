import { RELAY_NODE_NOTIFICATIONS } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { NotificationsParsedType } from "./UpgradeBanner"

const mapStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

type RelayNodeNotificationsResponseType = {
  status: keyof typeof mapStatus
  message: string
  aboutToExpire: boolean
  latestVersion?: string
}

type UseRelayNodeNotifications = {
  shouldFetch?: boolean
}

const parser = (response: RelayNodeNotificationsResponseType): NotificationsParsedType => ({
  ...response,
  status: mapStatus[response?.status],
})

export const useRelayNodeNotifications = ({ shouldFetch }: UseRelayNodeNotifications) => {
  const { data, mutate } = useFetch({
    apiUrl: shouldFetch ? RELAY_NODE_NOTIFICATIONS : null,
    options: { refreshInterval: 15000 },
    parser,
  })

  return { relayNodeNotificationsData: data, getRelayNodeNotifications: mutate }
}
