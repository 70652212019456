import { Suspense } from "react"
import * as Icons from "./Icons/Index"

export const IconsMap = {
  admin: Icons.AdminIcon,
  actions: Icons.ActionsIcon,
  add: Icons.AddIcon,
  alert: Icons.AlertIcon,
  alertAcknowledge: Icons.AlertsAcknowledgeIcon,
  alertActive: Icons.AlertsActiveIcon,
  alertSeverityHigh: Icons.AlertsSeverityHighIcon,
  alertSeverityMedium: Icons.AlertsSeverityMediumIcon,
  alertSeverityLow: Icons.AlertsSeverityLowIcon,
  alertSeverityInfo: Icons.AlertsSeverityInfoIcon,
  android: Icons.AndroidIcon,
  applicationObject: Icons.ApplicationObjectIcon,
  applications: Icons.ApplicationsIcon,
  arrowUp: Icons.ArrowUpIcon,
  arrowUp2: Icons.ArrowUpIcon2,
  arrowRedo: Icons.ArrowRedoIcon,
  arrowUndo: Icons.ArrowUndoIcon,
  atlassianJira: Icons.AtlassianJiraIcon,
  automatedNetworkService: Icons.AutomatedNetworkServiceIcon,
  automaticHostCreation: Icons.AutomaticHostCreationIcon,
  awsColored: Icons.AwsColoredIcon,
  aws: Icons.AwsIcon,
  azure: Icons.AzureIcon,
  azureIntegration: Icons.AzureIntegrationIcon,
  azureLogo: Icons.AzureLogoIcon,
  actionsDataGrid: Icons.ActionDataGridIcon,
  appStore: Icons.AppStoreIcon,
  backArrow: Icons.BackArrowIcon,
  barChart: Icons.BarChartIcon,
  basicLicense: Icons.BasicLicenseIcon,
  billing: Icons.BillingIcon,
  blankSlate: Icons.BlankSlateIcon,
  boxedCross: Icons.BoxedCross,
  brightCircularTick: Icons.BrightCircularTickIcon,
  building: Icons.BuildingIcon,
  buildingPrivate: Icons.BuildingPrivateIcon,
  buildingOnPrem: Icons.BuildingOnPremIcon,
  calendar: Icons.CalendarIcon,
  calendar2: Icons.CalendarIcon2,
  camera: Icons.CameraIcon,
  caret: Icons.CaretIcon,
  caret2: Icons.CaretIcon2,
  case: Icons.CaseIcon,
  chart: Icons.ChartIcon,
  checkCircle: Icons.CheckCircleIcon,
  chromebook: Icons.ChromebookIcon,
  circleAdd: Icons.CircleAddIcon,
  circleTick: Icons.CircleTickIcon,
  circleGreenTick: Icons.CircleGreenTickIcon,
  circleCheck: Icons.CircleCheckIcon,
  circleWarning: Icons.CircleWarningIcon,
  clientObject: Icons.ClientObjectIcon,
  cleanUpAllowed: Icons.CleanupAllowed,
  clock: Icons.ClockIcon,
  close: Icons.CloseIcon,
  closeIconGrey: Icons.CloseIconGrey,
  closeCircle: Icons.CloseCircleIcon,
  cloud: Icons.CloudIcon,
  cloudIntegration: Icons.CloudIntegrationIcon,
  collapse: Icons.CollapseIcon,
  configTools: Icons.ConfigToolsIcon,
  monitor: Icons.MonitorIcon,
  copy: Icons.CopyIcon,
  cube: Icons.CubeIcon,
  danger: Icons.DangerIcon,
  dangerOct: Icons.DangerOctIcon,
  device: Icons.deviceIcon,
  userIdentity: Icons.userIdentityIcon,
  defaultUser: Icons.DefaultUserIcon,
  delete: Icons.DeleteIcon,
  devices: Icons.DevicesIcon,
  deviceGroup: Icons.DeviceGroupIcon,
  document: Icons.DocumentIcon,
  downArrow: Icons.DownArrowIcon,
  downArrowFeather: Icons.DownArrowFeatherIcon,
  downFeatherArrow: Icons.DownFeatherArrowIcon,
  download: Icons.DownloadIcon,
  download2: Icons.DownloadIcon2,
  downloadBlue: Icons.DownloadBlueIcon,
  dragIndicator: Icons.DragIndicatorIcon,
  drap: Icons.DrapIcon,
  diamondIcon: Icons.DiamondIcon,
  doneIcon: Icons.DoneIcon,
  dropdownDown: Icons.DropdownDownIcon,
  dropdownUp: Icons.DropdownUpIcon,
  edit: Icons.EditIcon,
  emptyPencilEdit: Icons.EmptyPencilEditIcon,
  emptyDelete: Icons.EmptyDeleteIcon,
  encircledArrowUp: Icons.EncircledArrowUpIcon,
  encircledCross: Icons.EncircledCrossIcon,
  endUser: Icons.EndUserIcon,
  encircledExclamation: Icons.EncircledExclamationIcon,
  encircledInfo: Icons.EncircledInfoIcon,
  encircledTick: Icons.EncircledTickIcon,
  endUserPortal: Icons.EndUserPortalIcon,
  enQuestionMark: Icons.EnQuestionMarkIcon,
  endUserSecurity: Icons.EndUserSecurityIcon,
  entraId: Icons.EntraIDIcon,
  error: Icons.ErrorIcon,
  expand: Icons.ExpandIcon,
  expandRight: Icons.ExpandRightIcon,
  expandTree: Icons.ExpandTreeIcon,
  extreme: Icons.ExtremeIcon,
  emptyRelay: Icons.EmptyRelayIcon,
  extremeAppsManagement: Icons.ExtremeAppsManagementIcon,
  extremeAppsNavigation: Icons.ExtremeAppsNavigationIcon,
  extremeCloudIQ: Icons.ExtremeCloudIQIcon,
  extremeFull: Icons.ExtremeFullIcon,
  extremeIntuitiveInsights: Icons.ExtremeIntuitiveInsightsIcon,
  extremeLicenseManagement: Icons.ExtremeLicenseManagementIcon,
  extremeLock: Icons.ExtremeLockIcon,
  extremeZta: Icons.ExtremeZtaIcon,
  eye: Icons.EyeIcon,
  eyeOutline: Icons.EyeOutlineIcon,
  eyeSlash: Icons.EyeSlashIcon,
  eyeOutlineSlash: Icons.EyeOutlineSlashIcon,
  emptyIntegrationsIcon: Icons.EmptyIntegrationsIcon,
  emptyUserGroups: Icons.EmptyUserGroupIcon,
  emptyDeviceGroups: Icons.EmptyDeviceGroupIcon,
  emptyNetworks: Icons.EmptyNetworksIcon,
  emptyNetworkGroups: Icons.EmptyNetworkGroupsIcon,
  emptyRadiusServers: Icons.EmptyRadiusServerIcon,
  emptySites: Icons.EmptySitesIcon,
  emptyDnsServers: Icons.EmptyDnsServersIcon,
  emptyNetworkDevices: Icons.EmptyNetworkDevicesIcon,
  emptySsids: Icons.EmptySsidsIcon,
  emptyRecords: Icons.EmptyRecordsIcon,
  emptyTenant: Icons.EmptyTenantIcon,
  feedback: Icons.FeedbackIcon,
  file: Icons.FileIcon,
  file2: Icons.FileIcon2,
  file3: Icons.FileIcon3,
  fileUpload: Icons.FileUploadIcon,
  fileInvalidUpload: Icons.FileInvalidUploadIcon,
  filter: Icons.FilterIcon,
  GCPIntegration: Icons.GCPIntegrationIcon,
  gcp: Icons.GCPIcon,
  github: Icons.GitHubIcon,
  globe: Icons.GlobeIcon,
  globeWithGear: Icons.GlobeWithGearIcon,
  globProxy: Icons.GlobeProxyIcon,
  google: Icons.GoogleIcon,
  greyGoogle: Icons.GreyGoogleIcon,
  group: Icons.GroupIcon,
  groupDots: Icons.GroupDotsIcon,
  googleSuite: Icons.GSuiteIcon,
  hamburger: Icons.HamburgerIcon,
  home: Icons.HomeIcon,
  hostsStat: Icons.HostsStatIcon,
  info: Icons.InfoIcon,
  invalidCircleIcon: Icons.InvalidCircleIcon,
  infoCircle: Icons.InfoCircleIcon,
  jiraIcon: Icons.JiraIcon,
  terminalAccess: Icons.TerminalAccessIcon,
  remoteDesktop: Icons.RemoteDesktopIcon,
  instruction: Icons.InstructionIcon,
  integrations: Icons.IntegrationsIcon,
  ios: Icons.IosIcon,
  key: Icons.KeyIcon,
  leftFeatherChevron: Icons.LeftFeatherChevronIcon,
  link: Icons.LinkIcon,
  linux: Icons.LinuxIcon,
  lock: Icons.LockIcon,
  localAccount: Icons.LocalAccountIcon,
  location: Icons.LocationIcon,
  locationOutline: Icons.LocationOutlineIcon,
  logout: Icons.LogoutIcon,
  letterEnvelope: Icons.LetterEnvelopeIcon,
  manualHostCreation: Icons.ManualHostCreationIcon,
  menu: Icons.MenuIcon,
  microsoft: Icons.MicrosoftIcon,
  microsoftIntune: Icons.MicrosoftIntuneIcon,
  minus: Icons.MinusIcon,
  network: Icons.NetworkIcon,
  networks: Icons.NetworksIcon,
  noInternetConnection: Icons.NoInternetConnectionIcon,
  noResultsFound: Icons.NoResultsFoundIcon,
  noServerConnection: Icons.NoServerConnectionIcon,
  notAuthorized: Icons.NotAuthorizedIcon,
  notification: Icons.NotificationIcon,
  o365: Icons.O365Icon,
  onboardingStep1: Icons.OnboardingStep1Icon,
  onboardingStep2: Icons.OnboardingStep2Icon,
  onboardingStep3: Icons.OnboardingStep3Icon,
  onboardingStep4: Icons.OnboardingStep4Icon,
  openNew: Icons.OpenNewIcon,
  others: Icons.OthersIcon,
  pencilEdit: Icons.PencilEditIcon,
  personWithLock: Icons.PersonWithLockIcon,
  playStore: Icons.PlayStoreIcon,
  pointer: Icons.PointerIcon,
  policies: Icons.PoliciesIcon,
  policies2: Icons.PoliciesIcon2,
  privateHosted: Icons.PrivateHostedIcon,
  project: Icons.ProjectIcon,
  publicSaas: Icons.PublicSaasIcon,
  rangeArrow: Icons.RangeArrowIcon,
  redCancel: Icons.RedCancelIcon,
  redCircularFilledCross: Icons.RedCircularFilledCrossIcon,
  refresh: Icons.RefreshIcon,
  refresh2: Icons.RefreshIcon2,
  refresh3: Icons.RefreshIcon3,
  refresh4: Icons.RefreshIcon4,
  refresh5: Icons.refreshIcon5,
  registeredDevicesStat: Icons.RegisteredDevicesStatIcon,
  relayNodesStat: Icons.RelayNodesStatIcon,
  remoteDesktopLogo: Icons.RemoteDesktopLogo,
  reset: Icons.ResetIcon,
  resources: Icons.ResourcesIcon,
  resources2: Icons.ResourcesIcon2,
  rightArrow: Icons.RightArrowIcon,
  rightFeatherChevron: Icons.RightFeatherChevronIcon,
  rollout: Icons.RolloutIcon,
  roundEdgeCopy: Icons.RoundEdgeCopyIcon,
  routerObject: Icons.RouterObjectIcon,
  search: Icons.SearchIcon,
  secureApplicationAccess: Icons.SecureApplicationAccessIcon,
  secureNetworkAccess: Icons.SecureNetworkAccessIcon,
  securityShield: Icons.SecurityShieldIcon,
  squareShield: Icons.SquareShieldIcon,
  service: Icons.ServiceIcon,
  serviceManagement: Icons.ServiceManagementIcon,
  services: Icons.ServicesIcon,
  servicesStats: Icons.ServicesStatsIcon,
  settings: Icons.SettingsIcon,
  severityLocation: Icons.SeverityLocationIcon,
  settings2: Icons.SettingsIcon2,
  shieldWarning: Icons.ShieldWarningIcon,
  shieldWithTick: Icons.ShieldWithTickIcon,
  simpleCircle: Icons.SimpleCircleIcon,
  statusRed: Icons.StatusRedIcon,
  statusYellow: Icons.StatusYellowIcon,
  statusGreen: Icons.StatusGreenIcon,
  greyCircle: Icons.GreyCircleIcon,
  sitesStat: Icons.SitesStatIcon,
  sitesStat2: Icons.SitesStatIcon2,
  sort: Icons.SortIcon,
  sortAlphabetical: Icons.SortAlphabeticalIcon,
  sortArrow: Icons.SortArrowIcon,
  splunk: Icons.SplunkIcon,
  sshIcon: Icons.SshIcon,
  star: Icons.StarIcon,
  stop: Icons.StopIcon,
  stopwatch: Icons.StopwatchIcon,
  synced: Icons.SyncedIcon,
  tenantAdminPortal: Icons.TenantAdminPortalIcon,
  tick: Icons.TickIcon,
  tourWizardClose: Icons.TourWizardCloseIcon,
  triangleWarning: Icons.TriangleWarningIcon,
  triangleWarningEmpty: Icons.TriangleWarningEmptyIcon,
  upArrow: Icons.UpArrowIcon,
  upArrowFeather: Icons.UpArrowFeatherIcon,
  user: Icons.UserIcon,
  userAuth: Icons.UserAuthIcon,
  userLock: Icons.UserLockIcon,
  users: Icons.UsersIcon,
  users2: Icons.UsersIcon2,
  usersStat: Icons.UsersStatIcon,
  upgradeProgress: Icons.UpgradeProgressIcon,
  upgradeFailed: Icons.UpgradeFailedIcon,
  urlHidden: Icons.UrlHiddenIcon,
  vCenterLogo: Icons.VcenterLogo,
  vpnLock: Icons.VpnLockIcon,
  warningCircle: Icons.WarningCircleIcon,
  wikiIcon: Icons.WikiIcon,
  windows: Icons.WindowsIcon,
  wireguard: Icons.WireGuardIcon,
  xiqSynced: Icons.XIQSyncedIcon,
  xcuztna: Icons.XCUZTNA,
  webApp: Icons.WebAppIcon,
  zoom: Icons.ZoomIcon,
  questionMark: Icons.QuestionMarkIcon,
  masonary: Icons.MasonaryIcon,
  // ENs
  enAccessPoint: Icons.ENIconAccessPoint,
  enAccessPointExtreme: Icons.ENIconAccessPointExtreme,
  enAccessPointGeneric: Icons.ENIconAccessPointGeneric,
  enAdd: Icons.ENIconAdd,
  enAddSquare: Icons.ENIconAddSquare,
  enAddUser: Icons.ENIconAddUser,
  enAlertDisable: Icons.ENIconAlertDisable,
  enAntenna1: Icons.ENIconAntenna1,
  enApi: Icons.ENIconApi,
  enApplications: Icons.ENIconApplications,
  enArrowDown: Icons.ENIconArrowDown,
  enArrowLeft: Icons.ENIconArrowLeft,
  enArrowRight: Icons.ENIconArrowRight,
  enArrowUp: Icons.ENIconArrowUp,
  enAttachment: Icons.ENIconAttachment,
  enAutoSense: Icons.ENIconAutoSense,
  enBell: Icons.ENIconBell,
  enBlock: Icons.ENIconBlock,
  enBook: Icons.ENIconBook,
  enBookmark: Icons.ENIconBookmark,
  enBridge: Icons.ENIconBridge,
  enBrowserAlert: Icons.ENIconBrowserAlert,
  enBrowserChrome: Icons.ENIconBrowserChrome,
  enBuilding: Icons.ENIconBuilding,
  enBuilding2: Icons.ENIconBuilding2,
  enBulb: Icons.ENIconBulb,
  enCalendar: Icons.ENIconCalendar,
  enCalendar2: Icons.ENIconCalendar2,
  enCameraSurveillance: Icons.ENIconCameraSurveillance,
  enCartEmpty: Icons.ENIconCartEmpty,
  enCartFull: Icons.ENIconCartFull,
  enCautionFilled: Icons.ENIconCautionFilled,
  enCelsius: Icons.ENIconCelsius,
  enCenter: Icons.ENIconCenter,
  enChannelized: Icons.ENIconChannelized,
  enChart: Icons.ENIconChart,
  enChartNotification: Icons.ENIconChartNotification,
  enCharts: Icons.ENIconCharts,
  enChatHistory: Icons.ENIconChatHistory,
  enChatNew: Icons.ENIconChatNew,
  enChevronDown: Icons.ENIconChevronDown,
  enChevronLeft: Icons.ENIconChevronLeft,
  enChevronRight: Icons.ENIconChevronRight,
  enChevronUp: Icons.ENIconChevronUp,
  enCircle: Icons.ENIconCircle,
  enCircles: Icons.ENIconCircles,
  enClock: Icons.ENIconClock,
  enClose: Icons.ENIconClose,
  enCloud: Icons.ENIconCloud,
  enComputer: Icons.ENIconComputer,
  enComputerMac: Icons.ENIconComputerMac,
  enController: Icons.ENIconController,
  enCookies: Icons.ENIconCookies,
  enCopy: Icons.ENIconCopy,
  enCustomerSupport: Icons.ENIconCustomerSupport,
  enDataCenter: Icons.ENIconDataCenter,
  enDelete: Icons.ENIconDelete,
  enDeploy: Icons.ENIconDeploy,
  enDevices: Icons.ENIconDevices,
  enDocument: Icons.ENIconDocument,
  enDocument2: Icons.ENIconDocument2,
  enDone: Icons.ENIconDone,
  enDoneCircle: Icons.ENIconDoneCircle,
  enDotsHorizontal: Icons.ENIconDotsHorizontal,
  enDotsVertical: Icons.ENIconDotsVertical,
  enDoubleArrowLeft: Icons.ENIconDoubleArrowLeft,
  enDoubleArrowRight: Icons.ENIconDoubleArrowRight,
  enDownload: Icons.ENIconDownload,
  enDragHandle: Icons.ENIconDragHandle,
  enDraw: Icons.ENIconDraw,
  enEc: Icons.ENIconEc,
  enEdit: Icons.ENIconEdit,
  enEii: Icons.ENIconEii,
  enElevator: Icons.ENIconElevator,
  enEmailClosed: Icons.ENIconEmailClosed,
  enEmailOpen: Icons.ENIconEmailOpen,
  enEmoji: Icons.ENIconEmoji,
  enEncrypted: Icons.ENIconEncrypted,
  enEndpoint: Icons.ENIconEndpoint,
  enEnterpriseRouter: Icons.ENIconEnterpriseRouter,
  enEnterpriseSwitch: Icons.ENIconEnterpriseSwitch,
  enEvaBw16px: Icons.ENIconEva_bw_16px,
  enEvaBw20px: Icons.ENIconEva_bw_20px,
  enEvaBw24px: Icons.ENIconEva_bw_24px,
  enEvaBw32px: Icons.ENIconEva_bw_32px,
  enEvaBw40px: Icons.ENIconEva_bw_40px,
  enEvaRgb16px: Icons.ENIconEva_rgb_16px,
  enEvaRgb20px: Icons.ENIconEva_rgb_20px,
  enEvaRgb24px: Icons.ENIconEva_rgb_24px,
  enEvaRgb32px: Icons.ENIconEva_rgb_32px,
  enEvaRgb40px: Icons.ENIconEva_rgb_40px,
  enExport: Icons.ENIconExport,
  enFabric: Icons.ENIconFabric,
  enFanStatus: Icons.ENIconFanStatus,
  enFeedback: Icons.ENIconFeedback,
  enFile: Icons.ENIconFile,
  enFileAlert: Icons.ENIconFileAlert,
  enFilter: Icons.ENIconFilter,
  enFilterFilled: Icons.ENIconFilterFilled,
  enFirewall: Icons.ENIconFirewall,
  enFloorplan: Icons.ENIconFloorplan,
  enFolder: Icons.ENIconFolder,
  enFolder2: Icons.ENIconFolder2,
  enFridge: Icons.ENIconFridge,
  enGlobe: Icons.ENIconGlobe,
  enGroupedNode: Icons.ENIconGroupedNode,
  enHand: Icons.ENIconHand,
  enHandLocked: Icons.ENIconHandLocked,
  enHelp: Icons.ENIconHelp,
  enHidden: Icons.ENIconHidden,
  enHistory: Icons.ENIconHistory,
  enHostname: Icons.ENIconHostname,
  enHouse: Icons.ENIconHouse,
  enImage: Icons.ENIconImage,
  enImport: Icons.ENIconImport,
  enIncomplete: Icons.ENIconIncomplete,
  enIncorrect: Icons.ENIconIncorrect,
  enInfo: Icons.ENIconInfo,
  enInspect: Icons.ENIconInspect,
  enInternet: Icons.ENIconInternet,
  enIot: Icons.ENIconIot,
  enIpPhone: Icons.ENIconIpPhone,
  enKey: Icons.ENIconKey,
  enLaptop: Icons.ENIconLaptop,
  enLaptop2: Icons.ENIconLaptop2,
  enLaunch: Icons.ENIconLaunch,
  enLayers: Icons.ENIconLayers,
  enLayout: Icons.ENIconLayout,
  enLayoutForceLink: Icons.ENIconLayoutForceLink,
  enLayoutMasonry: Icons.ENIconLayoutMasonry,
  enLegend: Icons.ENIconLegend,
  enLicensedUser: Icons.ENIconLicensedUser,
  enLink: Icons.ENIconLink,
  enLinkBlocked: Icons.ENIconLinkBlocked,
  enLinkDownThick: Icons.ENIconLinkDownThick,
  enLinkDownThin: Icons.ENIconLinkDownThin,
  enList: Icons.ENIconList,
  enLldpSwitch: Icons.ENIconLldpSwitch,
  enLocationPin: Icons.ENIconLocationPin,
  enLocationSecurity: Icons.ENIconLocationSecurity,
  enLock: Icons.ENIconLock,
  enMap: Icons.ENIconMap,
  enMaximize: Icons.ENIconMaximize,
  enMenu: Icons.ENIconMenu,
  enMenuTree: Icons.ENIconMenuTree,
  enMessage: Icons.ENIconMessage,
  enMessage2: Icons.ENIconMessage2,
  enMicrowave: Icons.ENIconMicrowave,
  enMinimize: Icons.ENIconMinimize,
  enMinus: Icons.ENIconMinus,
  enMirror: Icons.ENIconMirror,
  enMobile: Icons.ENIconMobile,
  enMode: Icons.ENIconMode,
  enMpls: Icons.ENIconMpls,
  enMplsIpVpn: Icons.ENIconMplsIpVpn,
  enMultiFactorAuthenticator: Icons.ENIconMultiFactorAuthenticator,
  enNac: Icons.ENIconNac,
  enNearMe: Icons.ENIconNearMe,
  enNewSession: Icons.ENIconNewSession,
  enNodes: Icons.ENIconNodes,
  enOrg1: Icons.ENIconOrg1,
  enOrg2: Icons.ENIconOrg2,
  enPhoneData: Icons.ENIconPhoneData,
  enPicker: Icons.ENIconPicker,
  enPin: Icons.ENIconPin,
  enPinFilled: Icons.ENIconPinFilled,
  enPinOutline: Icons.ENIconPinOutline,
  enPlug: Icons.ENIconPlug,
  enPort: Icons.ENIconPort,
  enPower: Icons.ENIconPower,
  enPowerSupply: Icons.ENIconPowerSupply,
  enPrint: Icons.ENIconPrint,
  enProtect: Icons.ENIconProtect,
  enProtocol: Icons.ENIconProtocol,
  enQrBarcode: Icons.ENIconQrBarcode,
  enRadio: Icons.ENIconRadio,
  enRecalibrate: Icons.ENIconRecalibrate,
  enRecenterZoom: Icons.ENIconRecenterZoom,
  enRedo: Icons.ENIconRedo,
  enRefresh: Icons.ENIconRefresh,
  enRepeater: Icons.ENIconRepeater,
  enReserved: Icons.ENIconReserved,
  enReset: Icons.ENIconReset,
  enRotateRight: Icons.ENIconRotateRight,
  enRouter: Icons.ENIconRouter,
  enRouterExtreme: Icons.ENIconRouterExtreme,
  enRouterGeneric: Icons.ENIconRouterGeneric,
  enRouting: Icons.ENIconRouting,
  enSave: Icons.ENIconSave,
  enSdWanAppliance: Icons.ENIconSdWanAppliance,
  enSearch: Icons.ENIconSearch,
  enSecureDevices: Icons.ENIconSecureDevices,
  enSecurity: Icons.ENIconSecurity,
  enSend: Icons.ENIconSend,
  enServer: Icons.ENIconServer,
  enSettings: Icons.ENIconSettings,
  enShare: Icons.ENIconShare,
  enSignIn: Icons.ENIconSignIn,
  enSignOut: Icons.ENIconSignOut,
  enSignal: Icons.ENIconSignal,
  enSignalHealthy: Icons.ENIconSignalHealthy,
  enSingleArrowLeft: Icons.ENIconSingleArrowLeft,
  enSingleArrowRight: Icons.ENIconSingleArrowRight,
  enSocialFacebookCircle: Icons.ENIconSocialFacebookCircle,
  enSocialInstagramCircle: Icons.ENIconSocialInstagramCircle,
  enSocialLinkedinCircle: Icons.ENIconSocialLinkedinCircle,
  enSocialXCircle: Icons.ENIconSocialXCircle,
  enSocialYoutubeCircle: Icons.ENIconSocialYoutubeCircle,
  enStar: Icons.ENIconStar,
  enStarFilled: Icons.ENIconStarFilled,
  enStop: Icons.ENIconStop,
  enStorage: Icons.ENIconStorage,
  enSwitch: Icons.ENIconSwitch,
  enSwitch2u: Icons.ENIconSwitch2u,
  enSwitch4k: Icons.ENIconSwitch4k,
  enSwitch5k: Icons.ENIconSwitch5k,
  enSwitch7k: Icons.ENIconSwitch7k,
  enSwitch8k: Icons.ENIconSwitch8k,
  enSwitchAccount: Icons.ENIconSwitchAccount,
  enSwitchExtreme: Icons.ENIconSwitchExtreme,
  enSwitchFabricEnabled: Icons.ENIconSwitchFabricEnabled,
  enSwitchGeneric: Icons.ENIconSwitchGeneric,
  enSwitchIsw: Icons.ENIconSwitchIsw,
  enSwitchL2: Icons.ENIconSwitchL2,
  enSwitchL3: Icons.ENIconSwitchL3,
  enSwitchThirdParty: Icons.ENIconSwitchThirdParty,
  enSwitchUser: Icons.ENIconSwitchUser,
  enSwitchesStack: Icons.ENIconSwitchesStack,
  enSwitching: Icons.ENIconSwitching,
  enSwitching2: Icons.ENIconSwitching2,
  enTable: Icons.ENIconTable,
  enTableCross: Icons.ENIconTableCross,
  enTableFilter: Icons.ENIconTableFilter,
  enTableSearch: Icons.ENIconTableSearch,
  enTablet: Icons.ENIconTablet,
  enTag: Icons.ENIconTag,
  enTerminal: Icons.ENIconTerminal,
  enTexture: Icons.ENIconTexture,
  enThumbsDown: Icons.ENIconThumbsDown,
  enThumbsDownFilled: Icons.ENIconThumbsDownFilled,
  enThumbsUp: Icons.ENIconThumbsUp,
  enThumbsUpFilled: Icons.ENIconThumbsUpFilled,
  enTime: Icons.ENIconTime,
  enTree: Icons.ENIconTree,
  enTroubleshoot: Icons.ENIconTroubleshoot,
  enTrunk: Icons.ENIconTrunk,
  enTv: Icons.ENIconTv,
  enUndo: Icons.ENIconUndo,
  enUnlink: Icons.ENIconUnlink,
  enUnlock: Icons.ENIconUnlock,
  enUp: Icons.ENIconUp,
  enUpload: Icons.ENIconUpload,
  enUrl1: Icons.ENIconUrl1,
  enUser: Icons.ENIconUser,
  enUserGroup: Icons.ENIconUserGroup,
  enVideo: Icons.ENIconVideo,
  enView: Icons.ENIconView,
  enVisible: Icons.ENIconVisible,
  enVlan: Icons.ENIconVlan,
  enVpls: Icons.ENIconVpls,
  enVrf: Icons.ENIconVrf,
  enWalk: Icons.ENIconWalk,
  enWall1: Icons.ENIconWall1,
  enWall2: Icons.ENIconWall2,
  enWan: Icons.ENIconWan,
  enWarningCircle: Icons.ENIconWarningCircle,
  enWarningFilled: Icons.ENIconWarningFilled,
  enWarningTriangle: Icons.ENIconWarningTriangle,
  enWebhook: Icons.ENIconWebhook,
  enWifi: Icons.ENIconWifi,
  enWifiOff: Icons.ENIconWifiOff,
  enXcEdWan: Icons.ENIconXcEdWan,
  enXcUztna: Icons.ENIconXcUztna,
  enXiq: Icons.ENIconXiq,
  enXiqCoPilot: Icons.ENIconXiqCoPilot,
  enXiqCompanion: Icons.ENIconXiqCompanion,
  enXiqConnect: Icons.ENIconXiqConnect,
  enXiqNavigator: Icons.ENIconXiqNavigator,
  enXiqPilot: Icons.ENIconXiqPilot,
  enXiqSiteEngine: Icons.ENIconXiqSiteEngine,
  enZoomIn: Icons.ENIconZoomIn,
  enZoomOut: Icons.ENIconZoomOut,
}

export type IconNameType = keyof typeof IconsMap

interface ZtnaIconProps {
  name: IconNameType
}

type ZtnaIconType = ZtnaIconProps & Record<string, any>

const ZtnaIcon: React.FC<ZtnaIconType> = ({ name, ...otherProps }) => {
  const IconComponent = IconsMap[name]
  return (
    <Suspense fallback={<></>}>
      <IconComponent {...otherProps} />
    </Suspense>
  )
}

export default ZtnaIcon
