export type LayoutPoints = {
  i: string
  x: number
  y: number
  w: number
  h: number
}

export type CustomerAdminLayout = {
  connectors: LayoutPoints[]
  services: LayoutPoints[]
  policies: LayoutPoints[]
  devices: LayoutPoints[]
  tiles: LayoutPoints[]
}

export const TOP_NAV_HEIGHT = 65
export const TABS_BANNER_HEIGHT = 53
export const UPGRADE_BANNER_HEIGHT = 52
