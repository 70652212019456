import { createUseStyles } from "react-jss"

export const useBannerStyles = createUseStyles((theme) => ({
  root: {
    "&.MuiSnackbarContent-root": {
      display: "grid",
      gridTemplateColumns: "auto auto",
      minWidth: 0,
      overflow: "hidden",
      borderRadius: 6,
      color: theme.color.content.default,
      background: theme.color.background.surfaceElevation1,
      border: `1px solid ${theme.color.border.divider}`,
      padding: 10
    },
    "& .MuiSnackbarContent-message": {
      overflow: "hidden",
    },
    "& .MuiSnackbarContent-action": {
      textWrap: "nowrap",
      display: "flex",
      gap: 10,
      marginRight: 0
    },
  },
  divider: {
    height: 30,
    backgroundColor: theme.color.neutralLight[16],
    marginRight: theme.spacing(1),
  },
  button: {
    backgroundColor: "transparent",
    "& .MuiButton-label": {
      color: theme.color.primary[600],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      border: 0,
      backgroundColor: "transparent",
    },
  },
  marginRight: { marginRight: theme.spacing(1) },
}))
