import clsx from "clsx"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { Link } from "react-router-dom"
import { BreadcrumbsType } from "src/store/ui/types"
import { useBreadcrumbsStyles } from "./Breadcrumbs.styles"

const AppBreadcrumbs: React.FC<BreadcrumbsType> = ({ data, badge, secondaryBadge, secondaryText }) => {
  const classes = useBreadcrumbsStyles()

  return (
    <>
      {(data.length || badge) && (
        <div className={clsx(classes.root)}>
          {data.map(({ title, url, callback }, idx) => {
            const isActive = data.length - 1 === idx
            return (
              <div key={`Breadcrumbs-${title}-${idx}`} className={classes.nodeRoot}>
                {callback ? (
                  <ENHeading variant="md">
                    <span className={clsx(classes.link, { [classes.active]: isActive })} onClick={callback}>
                      {title}
                    </span>
                  </ENHeading>
                ) : url ? (
                  <ENHeading variant="md">
                    <Link className={clsx(classes.link, { [classes.active]: isActive })} to={url}>
                      {title}
                    </Link>
                  </ENHeading>
                ) : (
                  <ENHeading variant="md">{title}</ENHeading>
                )}

                {!isActive && (
                  <div data-testid="arrow-icon" className={classes.separator}>
                    /
                  </div>
                )}
              </div>
            )
          })}

          {secondaryText && <span className={classes.secondaryText}>{secondaryText}</span>}
        </div>
      )}
    </>
  )
}

export default AppBreadcrumbs
