import { RADSEC_PROXY_NOTIFICATIONS } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { NotificationsParsedType } from "./UpgradeBanner"

const mapStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

type RadsecProxyNotificationsResponseType = {
  status: keyof typeof mapStatus
  message: string
  aboutToExpire: boolean
  latestVersion?: string
}

type UseRadsecProxyNotifications = {
  shouldFetch?: boolean
}

const parser = (response: RadsecProxyNotificationsResponseType): NotificationsParsedType => ({
  ...response,
  status: mapStatus[response?.status],
})

export const useRadsecProxyNotifications = ({ shouldFetch }: UseRadsecProxyNotifications) => {
  const { data, mutate } = useFetch({
    apiUrl: shouldFetch ? RADSEC_PROXY_NOTIFICATIONS : null,
    options: { refreshInterval: 15000 },
    parser,
  })

  return { radsecProxyNotificationsData: data, getRadsecProxyNotifications: mutate }
}
