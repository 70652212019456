import Alert from "src/shared/components/Alert"

interface WarningContainerProps {
  message: string
  onClose?: () => void
  textContainerClass?: string
}

const WarningContainer: React.FC<WarningContainerProps> = ({ message, onClose, textContainerClass }) => {
  return <Alert variant="warning" content={message} handleClose={onClose} />
}

export default WarningContainer
