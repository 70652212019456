import clsx from "clsx"
import { ENStepper } from "en-react/dist/src/components/Stepper"
import { ENStepperItem } from "en-react/dist/src/components/StepperItem"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useStepperWizardStyles } from "./StepperWizard.styles"

interface StepperWizardProps {
  renderContent: React.ReactNode[]
  header: string[]
  stepperWrapperClass?: string
  stepperContentClass?: string
  activeStep: number
}

const StepperWizard: React.FC<StepperWizardProps> = ({
  renderContent,
  header,
  stepperWrapperClass,
  stepperContentClass,
  activeStep,
}) => {
  const classes = useStepperWizardStyles()
  const getStepContent = (stepIndex: number): React.ReactNode => renderContent[stepIndex]

  return (
    <div className={clsx(classes.modalInfo, stepperWrapperClass)}>
      <div className={clsx(classes.stepperContainer, stepperContentClass)}>
        <ENStepper style={{ width: "100px !important" }}>
          {header.map((label, idx) => (
            <ENStepperItem
              key={`StepperWizardStep-${label}-${idx}`}
              isActive={activeStep === idx}
              isComplete={activeStep > idx}
              isLast={idx === header.length - 1}
            >
              <ENTextPassage>
                <span className={classes.label}>{label}</span>
              </ENTextPassage>
            </ENStepperItem>
          ))}
        </ENStepper>
      </div>

      <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
    </div>
  )
}

export default StepperWizard
