import { createUseStyles } from "react-jss"

export const useErrorSlateStyles = createUseStyles((theme) => ({
  errorSlate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 200,
    border: "none",
    color: theme.color.grey[700],
  },
  errorSlateIcon: {
    marginBottom: 21,
    color: theme.color.content.dangerDefault,
  },
  errorSlateText: {
    color: theme.color.content.default,
    textAlign: "center",
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    "& a": {
      color: theme.color.text.primary,
      cursor: "pointer",
    },
  },
}))
