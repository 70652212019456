export const GET_MANIFEST = "/manifest.json"
export const CUSTOMER_ADMIN_ACTIVITY_LOGS = "/analytics/api/v1/activity/logs/"
export const FILTER_ACTIVITY_LOG = "/analytics/api/v1/activity/logs/filter-by"
export const PROFILE_IMAGE = "/auth/api/v1/accounts/profile-image/"
export const PROFILE = "/auth/api/v1/accounts/profile/"
export const FEATURE_FLAGS = "/auth/api/v1/feature-flags/"
export const LOGIN_URL = "/auth/api/v1/accounts/login/"
export const LOGOUT_URL = "/auth/api/v1/accounts/logout/"
export const ACCOUNT_SETTINGS = "/auth/api/v1/accounts/settings/"
export const ENVIRONMENT_DETAILS = "/resource/api/v1/environment-details/"

// IDENTITY PROVIDERS
export const VALIDATE_IDP = "/auth/api/v1/accounts/validate-idp/"

export const IDP_SETTINGS = "/auth/api/v1/accounts/idp/settings/"
export const IDP_PROVISIONING_SETTINGS = "/auth/api/v1/accounts/idp/provisioning-settings/"
export const IDENTITY_PROVIDER_LIST = "/auth/api/v1/accounts/idp/"
export const DISCONNECT_IDENTITY_PROVIDER = "/auth/api/v1/accounts/idp/initiate-disconnect-cleanup/"
export const IDP_SYNCED = "/auth/api/v1/accounts/idp/synced/"

export const VALIDATE_GOOGLE_IDP = "/auth/api/v1/accounts/google/idp/validate/"
export const UPDATE_GOOGLE_IDP = "/auth/api/v1/accounts/google/idp/settings/"

// DEVICE POSTURE
export const CUSTOMER_ADMIN_DEVICE_POSTURE = "/resource/api/v1/resources/device-posture/"
export const GET_OS_AND_BROWSER_LISTS = "/resource/api/v1/resources/device-posture/browser-and-os-names/"

export const USER_LOCATION = "/resource/api/v1/location/user/"
export const FORGOT_PASSWORD_URL = "/auth/api/v1/accounts/forgot-password/"
export const GET_LOCATION = "/resource/api/v1/location/extreme-ip-lookup/"

export const END_USER_FORCE_LOGOUT = "/auth/api/v1/accounts/logout-workspace-users/"
export const AUTHENTICATE_SERVICE = (serviceId: string): string =>
  `/resource/api/v1/resources/services/${serviceId}/domain/`

export const CHECK_FOR_FORCE_LOGOUT = (sessionId: string): string =>
  `/auth/api/v1/accounts/user-info/?session_id=${sessionId}`

export const TERMINATE_USER_SESSION = "/auth/api/v1/accounts/logout-device/"

export const SYNC_GOOGLE_IDP_USER_AND_USER_GROUP = "/auth/api/v1/google-directory-sync/settings/"
export const SYNC_GOOGLE_IDP_USER_AND_USER_GROUP_NOW = "/auth/api/v1/google-directory-sync/force-sync/"

export const GET_MAC_OUIS = (limit: string, offset: string, searchQuery: string) =>
  `/admin/resource/api/v1/resources/devices/mac-ouis/?limit=${limit}&offset=${offset}&search=${searchQuery}`

export const SYNC_AZURE_IDP_USER_AND_USER_GROUP_SETTINGS = "/auth/api/v1/entra-id-sync/settings/"
export const SYNC_AZURE_IDP_USER_AND_USER_GROUP_NOW = "/auth/api/v1/entra-id-sync/force-sync/"
