import { useState } from "react"
import { ALERTS } from "src/craas/apiURLs"
import { REQUEST_HEADERS } from "src/services"
import { getExternalApiUrl, keysToCamelCase } from "src/utils"
import useSWR from "swr"

export type AlertType = {
  id: string
  timestamp: string
  app: string
  appName: string
  messageMetadataId: number
  messageMetadataType: string
  messageMetadataName: string
  summary: string
  severityName: string
  categoryName: string
  severityId: number
  categoryId: number
  source: SourceType
  tags: Array<TagsType>
  acknowledged: boolean
  siteId: string
  siteName: string
  alertPolicyId: number
  alertPolicyName: string
  alertRuleId: number
  acknowledgedUsername: string
}

type SourceType = {
  sourceTypeId: string
  sourceName: string
  sourceType: string
  sourceId: string
}

type TagsType = {
  id: number
  name: string
  value: string
  isHidden: boolean
}

export type AlertsPayloadType = {
  alerts: AlertType[] | null
  total: number
  from?: number
  to?: number
}

type UseAlertsResponseType = {
  data?: AlertsPayloadType
  isLoading: boolean
  error: any
  isValidating: boolean
  getAlerts: () => void
}

export type UseAlertsType = {
  shouldFetch?: boolean
  startTime: number
  endTime: number
  page?: number
  limit?: number
  refreshInterval?: number
  filterQuery?: string
  query?: string
}

export const getExternalApiGdc = (domain: string) => {
  const gdcNames: { [domain: string]: string } = {
    "zta-tme": "tme",
    "g3r1-uz": "g3",
    "g2r1-uz": "g2",
    "feature-endsystem": "g2",
    default: "ztna1",
  }

  return gdcNames[domain] ?? gdcNames.default
}

export const getExternalApiRdc = (server: string) => {
  const envUrl: string = getExternalApiUrl() ?? ""
  let API_URL = envUrl

  if (envUrl && envUrl !== "") {
    let envDomain = envUrl.split(".")
    envDomain[0] += `-${server}`
    API_URL = envDomain.join(".")
  }

  return API_URL
}

const useAlerts = ({
  filterQuery,
  shouldFetch = true,
  startTime,
  endTime,
  page = 1,
  limit = 100,
  refreshInterval,
}: UseAlertsType): UseAlertsResponseType => {
  const [alertsData, setAlertsData] = useState<AlertsPayloadType | undefined>()
  const [error, setError] = useState<any>(null)

  const fetchData = async (swrData: any) => {
    try {
      setAlertsData({
        total: swrData?.pagination?.totalCount,
        alerts: swrData?.data ? keysToCamelCase(swrData.data) : undefined,
      })
    } catch (error) {
      setError(error)
    }
  }

  const fetcher = async (url: string, headers: HeadersInit) => {
    const response = await fetch(url, { headers, credentials: "include" })
    if (!response.ok) {
      error.response = response
    }
    return response.json()
  }

  const isoStartTime = new Date(startTime).toISOString()
  const isoEndTime = new Date(endTime).toISOString()

  const API_URL = getExternalApiRdc("cas") ?? ""

  const url = `${API_URL}${ALERTS}?startTime=${isoStartTime}&endTime=${isoEndTime}&page=${page}&limit=${limit}&order=DESC`

  const {
    error: swrError,
    data: swrData,
    mutate,
    isValidating,
  } = useSWR(shouldFetch ? [url, REQUEST_HEADERS] : null, {
    refreshInterval,
    fetcher,
    onSuccess: (data) => {
      if (!swrError) {
        fetchData(data)
      }
    },
  })

  return {
    data: alertsData,
    isLoading: !swrError && (!alertsData || !swrData),
    error: error || swrError,
    isValidating,
    getAlerts: mutate,
  }
}

export default useAlerts
