import theme from "src/theme"

export const FeedbackIcon: React.FC<{ color?: string }> = ({ color = theme.color.grey[800] }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.322" height="19.322">
    <path
      data-name="Icon material-message"
      d="M20.39 3H4.932A1.93 1.93 0 0 0 3.01 4.932L3 22.322l3.864-3.864H20.39a1.938 1.938 0 0 0 1.932-1.932V4.932A1.938 1.938 0 0 0 20.39 3zm-1.932 11.593H6.864v-1.932h11.594zm0-2.9H6.864v-1.93h11.594zm0-2.9H6.864V6.864h11.594z"
      transform="translate(-3 -3)"
      fill={color}
    />
  </svg>
)

export const FileIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.652" height="22.203" viewBox="0 0 16.652 22.203">
    <path
      data-name="Icon awesome-file-alt"
      d="M9.714 5.9V0H1.041A1.038 1.038 0 0 0 0 1.041v20.121A1.038 1.038 0 0 0 1.041 22.2h14.571a1.038 1.038 0 0 0 1.041-1.041V6.938h-5.9A1.044 1.044 0 0 1 9.714 5.9zm2.775 10.234a.522.522 0 0 1-.52.52H4.683a.522.522 0 0 1-.52-.52v-.347a.522.522 0 0 1 .52-.52h7.285a.522.522 0 0 1 .52.52zm0-2.775a.522.522 0 0 1-.52.52H4.683a.522.522 0 0 1-.52-.52v-.349a.522.522 0 0 1 .52-.52h7.285a.522.522 0 0 1 .52.52zm0-3.122v.347a.522.522 0 0 1-.52.52H4.683a.522.522 0 0 1-.52-.52v-.347a.522.522 0 0 1 .52-.52h7.285a.522.522 0 0 1 .521.517zm4.163-4.948v.265H11.1V0h.265a1.04 1.04 0 0 1 .735.3l4.245 4.25a1.037 1.037 0 0 1 .307.736z"
      fill={theme.color.grey[800]}
    />
  </svg>
)

export const FileIcon2 = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.66634 12.3337H10.333V14.0003H3.66634V12.3337ZM3.66634 9.0003H10.333V10.667H3.66634V9.0003ZM8.6663 0.666992H1.99967C1.08301 0.666992 0.333008 1.41699 0.333008 2.33366V15.667C0.333008 16.5837 1.07467 17.3337 1.99134 17.3337H11.9997C12.9163 17.3337 13.6663 16.5837 13.6663 15.667V5.66699L8.6663 0.666992ZM11.9997 15.667H1.99967V2.33366H7.833V6.50033H11.9997V15.667Z"
      fill={theme.color.grey.A200}
    />
  </svg>
)

export const FileIcon3 = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V4.66667L11.3333 0.5ZM13.8333 13.8333H2.16667V2.16667H10.5V5.5H13.8333V13.8333ZM3.83333 12.1667H12.1667V10.5H3.83333V12.1667ZM8 3.83333H3.83333V5.5H8V3.83333ZM3.83333 8.8333H12.1667V7.16667H3.83333V8.8333Z"
      fill={theme.color.grey.A200}
    />
  </svg>
)

export const FilterIcon: React.FC<{ color?: string; height?: string; width?: string }> = ({
  color = theme.color.grey[800],
  height = "14.551",
  width = "14.551",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 14.551 14.551" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.176336 1.34166C2.10967 3.825 4.96801 7.49999 4.96801 7.49999V11.6667C4.96801 12.5833 5.71801 13.3333 6.63467 13.3333C7.55137 13.3333 8.30137 12.5833 8.30137 11.6667V7.49999C8.30137 7.49999 11.1597 3.825 13.093 1.34166C13.518 0.79166 13.1264 0 12.4264 0H0.834666C0.143006 0 -0.248664 0.79166 0.176336 1.34166Z"
      fill={color}
    />
  </svg>
)

export const FileUploadIcon: React.FC<{ color?: string; height?: string; width?: string }> = ({
  color = theme.color.content.default,
  height = "25",
  width = "24",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6213 6.12132L17.3787 3.87868C16.8161 3.31607 16.053 3 15.2574 3H7.5C5.84315 3 4.5 4.34315 4.5 6V18C4.5 19.6569 5.84315 21 7.5 21H17.5C19.1569 21 20.5 19.6569 20.5 18V8.24264C20.5 7.44699 20.1839 6.68393 19.6213 6.12132Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5 8.5H17C15.8954 8.5 15 7.60457 15 6.5V3"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.25 14.25L12.5 12L14.75 14.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.4951 17V12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const FileInvalidUploadIcon: React.FC<{ color?: string; height?: string; width?: string }> = ({
  color = theme.color.content.default,
  height = "25",
  width = "24",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0156 8.5H16.5156C15.4111 8.5 14.5156 7.60457 14.5156 6.5V3"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0156 21H17.0156C18.6725 21 20.0156 19.6569 20.0156 18V8.24264C20.0156 7.44699 19.6996 6.68393 19.1369 6.12132L16.8943 3.87868C16.3317 3.31607 15.5686 3 14.773 3H7.01562C5.35877 3 4.01562 4.34315 4.01562 6V13"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.51562 19.5L5.51562 16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.51562 19.5L8.51562 16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
