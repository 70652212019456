import { END_APPLICATION_TROUBLESHOOTING, START_APPLICATION_TROUBLESHOOTING } from "src/constants"
import { patchRequest, postRequest } from "src/services"

export const startApplicationTroubleshooting = async (evaluationId: string) => {
  await postRequest(START_APPLICATION_TROUBLESHOOTING(evaluationId), {
    status: "IN_PROGRESS",
  })
}

export const endApplicationTroubleshooting = async (evaluationId: string) => {
  await patchRequest(END_APPLICATION_TROUBLESHOOTING(evaluationId), {
    status: "TERMINATED",
  })
}
