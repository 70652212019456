import { ENVIRONMENT_DETAILS, PROFILE, TENANT_ADMIN_BASE_URL } from "src/constants"
import { PublicRoutesMap, Roles } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWRImmutable from "swr/immutable"

type EnvironmentDetailsType = {
  environment: string
  environmentUrl: string
  xcdUrl: string
  xiqUrl: string
  xiqLogoutUrl: string
}

type UseEnvironmentDetailsTypeType = {
  data?: EnvironmentDetailsType
  isLoading: boolean
  error: any
}

const useEnvironmentDetails = (): UseEnvironmentDetailsTypeType => {
  const { role } = JSON.parse(localStorage.getItem("userData") || "{}")

  const url = isTenantRole(role)
    ? `${API_URL}${TENANT_ADMIN_BASE_URL}${ENVIRONMENT_DETAILS}`
    : role === Roles.END_USER
    ? `${API_URL}${PROFILE}`
    : `${API_URL}${ENVIRONMENT_DETAILS}`

  const { data, error } = useSWRImmutable(
    window.location.pathname.includes(PublicRoutesMap.SERVICE_AGENTLESS_TUNNEL.absolutePath)
      ? [url, REQUEST_HEADERS]
      : role
      ? [url, REQUEST_HEADERS]
      : null,
  )

  const payload = data?.payload

  return {
    data: payload || undefined,
    isLoading: !error && !data,
    error,
  }
}

export default useEnvironmentDetails
