import { VERSIONS_ROLLOUT } from "src/constants"
import { postRequest } from "src/services"
import { VersionsCheckResponseType } from "./useRolloutUpgrade"

export const rolloutUpgrade = async ({
  connectorVersion,
  radsecProxyVersion,
  relayVersion,
}: VersionsCheckResponseType) => {
  const body = {
    connector_version: connectorVersion,
    relay_version: relayVersion,
    radsec_proxy_version: radsecProxyVersion,
  }
  await postRequest(VERSIONS_ROLLOUT, body)
}
