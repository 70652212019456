import { createUseStyles } from "react-jss"

export const useBadgeWithIconCellStyles = createUseStyles((theme) => ({
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  badgeWithoutTooltipWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 5,
  },
  badgeWrapperWithIcon: {
    marginLeft: 28,
  },
  clickableBadgeIcon: {
    marginBottom: -9,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  clickableBadgeIconNoPadding: {
    "--en-button-padding": 0,
    marginLeft: 8,
  },
  tooltip: {
    backgroundColor: theme.color.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.color.primary.main,
  },
  defaultCursor: {
    cursor: "default",
  },
  badgeIcon: {
    display: "flex",
    marginLeft: 12,
    alignItems: "center",
    width: 15,
    height: 15,
  },
  badge: {
    height: "28px !important",
    borderRadius: "13px !important",
    minWidth: 124,
    "& span": {
      fontSize: 14,
      fontWeight: 600,
    },
    color: `${theme.color.grey[300]} !important`,
    backgroundColor: `${theme.color.grey[300]}1a !important`,
  },
  badgeSuccess: {
    color: `${theme.color.success[500]} !important`,
    backgroundColor: `${theme.color.success[400]} !important`,
  },
  badgeWarning: {
    color: `${theme.color.warning[500]} !important`,
    backgroundColor: "#f8f1e5 !important",
  },
  badgeDanger: {
    color: `${theme.color.error.main} !important`,
    backgroundColor: "#fde5e5 !important",
  },
  noBadge: {
    backgroundColor: "transparent !important",
  },
  badgeIndeterminate: {
    color: `${theme.color.grey[300]} !important`,
    backgroundColor: `${theme.color.grey[300]}1a !important`,
  },
}))
