import { ENDialog } from "en-react/dist/src/components/Dialog"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { hideToaster } from "src/components/TopNav"
import { SETUP_WORKSPACE_URL } from "src/constants"
import useFetch from "src/hooks/useFetch"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import ErrorSlate from "src/shared/components/ErrorSlate"
import ZtnaIcon, { IconNameType } from "src/shared/components/Icons"
import LoadingSlate from "src/shared/components/LoadingSlate"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { closeModal } from "src/store/ui"
import { setToasterState } from "src/store/ui/uiSlice"
import theme from "src/theme"
import { BRAND_NAME } from "src/utils/constants"
import { updateSecureTunnel } from "./EncryptionProtocols.service"
import { useEncryptionProtocolsStyles } from "./EncryptionProtocols.styles"
import ProtocolCard from "./ProtocolCard"

export type TunnelNamesType = "IPSEC" | "WireGuard"

type EncryptionProtocolsType = {
  name: TunnelNamesType
  icon: IconNameType
}

const EncryptionProtocols = () => {
  const classes = useEncryptionProtocolsStyles()

  const dispatch = useDispatch()

  const closeModalCallback = () => dispatch(closeModal("encryptionProtocols"))

  const [tunnelType, setTunnelType] = useState("IPSEC")
  const [isUpdating, setIsUpdating] = useState(false)

  const onSubmit = async () => {
    hideToaster()
    setIsUpdating(true)
    try {
      await updateSecureTunnel({ type: tunnelType.toLowerCase() })
      dispatch(
        setToasterState({
          message: "Tunnel type updated successfully!",
          autoHide: true,
          type: "success",
        }),
      )
      dispatch(closeModal("encryptionProtocols"))
    } catch (err: any) {
      dispatch(
        setToasterState({
          message: err?.message,
          autoHide: false,
          type: "danger",
        }),
      )
      setIsUpdating(false)
    }
  }

  const { data, error, isLoading } = useFetch({
    apiUrl: SETUP_WORKSPACE_URL,
    parser: (resp) => (resp?.tunnelType === "ipsec" ? "IPSEC" : "WireGuard"),
  })

  useEffect(() => {
    data && setTunnelType(data)
  }, [data])

  const encryptionProtocols: EncryptionProtocolsType[] = [
    { name: "IPSEC", icon: "lock" },
    { name: "WireGuard", icon: "wireguard" },
  ]

  return (
    <ENDialog disableClickOutside isActive className={classes.root} onclose={closeModalCallback} heading=" ">
      {isLoading ? (
        <div className={classes.loader}>
          <LoadingSlate text="Please wait while we gather the available Encryption Protocols" />
        </div>
      ) : (
        <>
          <div role="button" className={classes.closeIconContainer} onClick={closeModalCallback}>
            <ZtnaIcon name="close" width="11" height="11" color={theme.color.grey.A200} />
          </div>

          {error ? (
            <ErrorSlate iconHeight="70px" iconWidth="70px" />
          ) : (
            <>
              <ENTextPassage>
                <span className={classes.heading}>
                  Choose one of the available encryption protocols in {BRAND_NAME} to secure application access.
                </span>
              </ENTextPassage>

              <ENTextPassage>
                <span className={classes.text}>
                  Later, you can choose to migrate to a different available encryption protocol.
                </span>
              </ENTextPassage>

              <div className={classes.cardContainer}>
                {encryptionProtocols.map((item) => (
                  <ProtocolCard
                    key={`ProtocolCard-${item.name}`}
                    name={item.name}
                    icon={item.icon}
                    isSelected={item.name === tunnelType}
                    setTunnelType={setTunnelType}
                  />
                ))}
              </div>

              <ZtnaButton
                buttonType="primary"
                title="Confirm"
                className={classes.button}
                onClick={onSubmit}
                startIcon={isUpdating && <CircularLoader size="md" />}
                disabled={isUpdating}
              />
            </>
          )}
        </>
      )}
    </ENDialog>
  )
}

export default EncryptionProtocols
