import { createUseStyles } from "react-jss"

export const useToasterStyle = createUseStyles((theme) => ({
  root: {
    top: "2em",
    padding: 0,
    margin: 0,
    width: 490,
    zIndex: theme.zIndex.toastNotification,
    [theme.breakpoints.down("md")]: {
      maxWidth: "95vw",
      left: "50%",
      transform: "translateX(-50%)",
    },
    "& .Toastify__toast": {
      width: 490,
      border: `1px solid ${theme.color.border.divider}`,
      borderRadius: 6,
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      height: "auto",
      minHeight: "auto",
      backgroundColor: theme.color.background.surfaceElevation1,
      [theme.breakpoints.down("md")]: {
        maxWidth: "95vw",
      },
    },
    "& .Toastify__toast-container": {
      width: 525,
      height: "auto",
    },
    "& .Toastify__progress-bar": {
      background: theme.color.background.accentDefault,
    },
    "& .Toastify__close-button": {
      color: theme.color.content.secondary,
      margin: theme.spacing(1.5),
    },
  },
  text: {
    [theme.breakpoints.down("md")]: {
      maxHeight: 75,
      overflow: "auto",
    },
  },
  title: {
    fontSize: 16,
  },
  message: {
    fontSize: 14,
  },
  closeWrapper: {
    color: theme.color.content.default,
    display: "flex",
    marginRight: 10,
    marginTop: 10,
  },
}))
