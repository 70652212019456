import { TENANT_ADMIN_BASE_URL, USER_LOCATION } from "src/constants"
import { RoleValueType } from "src/routes/config"
import { isTenantRole } from "src/routes/config/Roles"
import { keysToSnakeCase } from "src/utils"
import { postRequest } from ".."
import { LocationType } from "./swrHooks/useLocation"

export const sendLocationData = async (
  { country, countryCode, city }: LocationType,
  role: RoleValueType,
): Promise<{
  status: boolean
}> => {
  const data = keysToSnakeCase({ countryCode, countryName: country, city })

  const url = isTenantRole(role) ? `${TENANT_ADMIN_BASE_URL}${USER_LOCATION}` : USER_LOCATION
  const resp = await postRequest(url, data)

  return {
    status: resp.data.success,
  }
}
