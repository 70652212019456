import theme from "src/theme"

export const TenantAdminPortalIcon: React.FC<{ color?: string }> = ({ color = theme.color.grey[800] }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25.886" height="20.711" viewBox="0 0 25.886 20.711">
    <path
      data-name="Icon awesome-user-cog"
      d="M24.7 15.1a4.745 4.745 0 0 0 0-1.723l1.044-.6a.293.293 0 0 0 .133-.344 6.07 6.07 0 0 0-1.343-2.322.3.3 0 0 0-.364-.057l-1.044.6a4.749 4.749 0 0 0-1.493-.862V8.588A.3.3 0 0 0 21.4 8.3a6.1 6.1 0 0 0-2.678 0 .3.3 0 0 0-.231.287v1.206a4.749 4.749 0 0 0-1.491.862l-1.044-.6a.3.3 0 0 0-.364.057 6.07 6.07 0 0 0-1.343 2.322.3.3 0 0 0 .133.344l1.044.6a4.745 4.745 0 0 0 0 1.723l-1.044.6a.293.293 0 0 0-.133.344 6.1 6.1 0 0 0 1.343 2.322.3.3 0 0 0 .364.057l1.044-.6a4.749 4.749 0 0 0 1.493.862v1.204a.3.3 0 0 0 .231.287 6.1 6.1 0 0 0 2.678 0 .3.3 0 0 0 .231-.287v-1.206a4.749 4.749 0 0 0 1.493-.862l1.044.6a.3.3 0 0 0 .364-.057 6.07 6.07 0 0 0 1.343-2.322.3.3 0 0 0-.133-.344L24.7 15.1zm-4.632 1.1a1.962 1.962 0 1 1 1.962-1.962 1.964 1.964 0 0 1-1.966 1.962zm-11-5.845a5.178 5.178 0 1 0-5.185-5.177 5.177 5.177 0 0 0 5.178 5.177zm8.132 9.163c-.093-.049-.186-.105-.275-.158l-.32.186a1.587 1.587 0 0 1-1.962-.3 7.376 7.376 0 0 1-1.626-2.815 1.582 1.582 0 0 1 .724-1.849l.32-.186q-.006-.158 0-.316l-.32-.186a1.58 1.58 0 0 1-.724-1.849c.036-.117.089-.235.129-.352-.154-.012-.3-.049-.461-.049h-.675a7.042 7.042 0 0 1-5.9 0h-.673A5.438 5.438 0 0 0 0 17.087v1.683a1.942 1.942 0 0 0 1.942 1.942H16.18a1.939 1.939 0 0 0 1.1-.344 1.575 1.575 0 0 1-.081-.477z"
      style={{ fill: color }}
    />
  </svg>
)

export const TickIcon: React.FC<{ color?: string; height?: string; width?: string }> = ({
  color = theme.color.primary[600],
  height = "19.045",
  width = "19.045",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 27" fill="none">
    <path
      d="M10.1248 18.1906L6.22107 14.2868C5.78232 13.8481 5.07357 13.8481 4.63482 14.2868C4.19607 14.7256 4.19607 15.4343 4.63482 15.8731L9.33733 20.5757C9.77608 21.0144 10.4848 21.0144 10.9236 20.5757L22.8261 8.67313C23.2648 8.23438 23.2648 7.52562 22.8261 7.08687C22.3873 6.64813 21.6786 6.64813 21.2398 7.08687L10.1248 18.1906Z"
      fill={color}
    />
  </svg>
)

export const TourWizardCloseIcon: React.FC<{ width?: string; height?: string }> = ({
  width = "19.1",
  height = "19.1",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 19.1 19.1">
    <g data-name="Group 634">
      <path
        data-name="Path 140"
        d="m823.537 7758.158-15.564 15.564"
        transform="translate(-806.205 -7756.39)"
        style={{ fill: "none", stroke: "#8f9db1", strokeLinecap: "round", strokeWidth: 2.5 }}
      />
      <path
        data-name="Path 141"
        d="m807.973 7758.158 15.564 15.564"
        transform="translate(-806.205 -7756.39)"
        style={{ fill: "none", stroke: "#8f9db1", strokeLinecap: "round", strokeWidth: 2.5 }}
      />
    </g>
  </svg>
)

export const TriangleWarningIcon: React.FC<{ height?: string; width?: string }> = ({
  height = "22.55",
  width = "24.432",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24.432 22.55">
    <path
      fill={theme.color.warning.main}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5.434L3.6 24.413a1.785 1.785 0 0 0 1.59 2.637H26a1.789 1.789 0 0 0 1.591-2.637L17.181 5.434a1.823 1.823 0 0 0-3.181 0zm2.625 7.875l-.211 7.164h-1.647l-.211-7.164zm-1.036 11.063a1.081 1.081 0 1 1 1.122-1.081 1.091 1.091 0 0 1-1.122 1.081z"
      transform="translate(-3.375 -4.5)"
    />
  </svg>
)

export const TriangleWarningEmptyIcon = ({ height = "18", width = "19" }) => (
  <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 11.3798V6.87979C8.25 6.46979 8.59 6.12979 9 6.12979C9.41 6.12979 9.75 6.46979 9.75 6.87979V11.3798C9.75 11.7898 9.41 12.1298 9 12.1298C8.59 12.1298 8.25 11.7898 8.25 11.3798ZM17.9 16.5098C17.71 17.0898 17.32 17.5798 16.77 17.8698C16.43 18.0398 16.06 18.1298 15.69 18.1298H2.32001C1.69001 18.1098 1.11999 17.8998 0.679993 17.4598C0.249993 17.0298 0 16.4498 0 15.8298C0 15.4498 0.089989 15.0698 0.269989 14.7398L6.94 2.10977C7.54 0.979766 8.94001 0.539795 10.07 1.1398C10.48 1.3598 10.82 1.69977 11.04 2.10977L17.72 14.7398C18.01 15.2898 18.06 15.9098 17.88 16.4998L17.9 16.5098ZM16.4 15.4398L9.72 2.80978C9.68 2.72978 9.63001 2.66977 9.57001 2.60977C9.52001 2.55977 9.45002 2.50978 9.39001 2.46978C8.98001 2.24978 8.49 2.40978 8.28 2.80978L1.60001 15.4498C1.54001 15.5598 1.51001 15.6898 1.51001 15.8298C1.51001 16.0498 1.6 16.2498 1.75 16.3998C1.9 16.5498 2.13001 16.6198 2.32001 16.6298H15.69C15.83 16.6298 15.96 16.5998 16.07 16.5398C16.27 16.4398 16.4 16.2798 16.47 16.0698C16.53 15.8598 16.51 15.6398 16.41 15.4498L16.4 15.4398ZM9 12.8698C8.38 12.8698 7.88 13.3698 7.88 13.9898C7.88 14.6098 8.38 15.1098 9 15.1098C9.62 15.1098 10.12 14.6098 10.12 13.9898C10.12 13.3698 9.62 12.8698 9 12.8698Z"
      fill={theme.color.neutralDark[16]}
    />
  </svg>
)

export const TerminalAccessIcon: React.FC<{ height?: string; width?: string; color?: string }> = ({
  height = "24",
  width = "24",
  color = theme.color.neutralDark[16],
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      d="M9.75 6.76974C9.75 7.18974 9.41 7.52975 9 7.52975H6.75C6.34 7.52975 6 7.18974 6 6.76974C6 6.34974 6.34 6.01974 6.75 6.01974H9C9.41 6.01974 9.75 6.35974 9.75 6.76974ZM3.75 4.50977H9C9.41 4.50977 9.75 4.16977 9.75 3.75977C9.75 3.34977 9.41 3.00977 9 3.00977H3.75C3.34 3.00977 3 3.34977 3 3.75977C3 4.16977 3.34 4.50977 3.75 4.50977ZM21 3.75977V20.2397C21 20.6597 20.66 20.9897 20.25 20.9897H3.75C3.34 20.9897 3 20.6497 3 20.2397V9.77975C3 9.36975 3.34 9.02975 3.75 9.02975C4.16 9.02975 4.5 9.36975 4.5 9.77975V13.4498H19.5V4.50977H12C11.59 4.50977 11.25 4.16977 11.25 3.75977C11.25 3.34977 11.59 3.00977 12 3.00977H20.25C20.66 3.00977 21 3.34977 21 3.75977ZM19.5 14.9698H4.5V19.4998H19.5V14.9698ZM17.25 17.9798C17.66 17.9798 18 17.6398 18 17.2298C18 16.8198 17.66 16.4798 17.25 16.4798C16.84 16.4798 16.5 16.8198 16.5 17.2298C16.5 17.6398 16.84 17.9798 17.25 17.9798ZM6.75 17.9798H14.25C14.66 17.9798 15 17.6398 15 17.2298C15 16.8198 14.66 16.4698 14.25 16.4698H6.75C6.34 16.4698 6 16.8098 6 17.2298C6 17.6498 6.34 17.9798 6.75 17.9798Z"
      fill={color}
    />
  </svg>
)
