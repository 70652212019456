import { createUseStyles } from "react-jss"

export const useCircularLoaderStyles = createUseStyles((theme) => ({
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  animation: {
    display: "inline-flex",
    animationName: "spin",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.44, 0.9)",
  },
}))
