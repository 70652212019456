export const DATE_FORMATS = {
  US: {
    H12: {
      dateTime: "MM-DD-YYYY hh:mm A",
      dateTimeWithSeconds: "MM-DD-YYYY hh:mm:ss A",
      dateTimeWithMilliSeconds: "MM-DD-YYYY hh:mm:ss:SSS A",
      date: "MM-DD-YYYY",
      time: "hh:mm A",
      timeWithSeconds: "hh:mm:ss A",
    },
    H24: {
      dateTime: "MM-DD-YYYY HH:mm",
      dateTimeWithSeconds: "MM-DD-YYYY HH:mm:ss",
      dateTimeWithMilliSeconds: "MM-DD-YYYY HH:mm:ss:SSS",
      date: "MM-DD-YYYY",
      time: "HH:mm",
      timeWithSeconds: "HH:mm:ss",
    },
  },
}
