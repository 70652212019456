import React, { ReactNode, useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import { useDispatch } from "react-redux"
import { hidePageHeader, showPageHeader } from "src/store/ui/uiSlice"
import { useAppHeaderLayoutStyles } from "./AppHeaderLayout.styles"

interface AppHeaderLayoutProps {
  portalElement?: string | null
  slots: {
    breadcrumbs?: ReactNode
    pageHeader?: ReactNode
    ctas?: ReactNode
  }
}

const AppHeaderLayout = (props: AppHeaderLayoutProps) => {
  const { slots, portalElement = "app-container-page-header-container" } = props
  const classes = useAppHeaderLayoutStyles()
  const dispatch = useDispatch()
  const containerRef = useRef(portalElement ? (document.getElementById(portalElement) as HTMLElement) : null)

  useEffect(() => {
    if (!portalElement) {
      return
    }
    dispatch(showPageHeader())
    containerRef.current = document.getElementById(portalElement) as HTMLElement

    return () => {
      dispatch(hidePageHeader())
    }
  }, [portalElement])

  const component = (
    <div className={classes.layout}>
      <div className={classes.left}>
        {slots.breadcrumbs && slots.breadcrumbs}
        {slots.pageHeader && slots.pageHeader}
      </div>
      {slots.ctas && <div className={classes.right}>{slots.ctas}</div>}
    </div>
  )

  if (!containerRef.current) {
    return component
  }

  return createPortal(
    <div className={classes.layout}>
      <div className={classes.left}>
        {slots.breadcrumbs && slots.breadcrumbs}
        {slots.pageHeader && slots.pageHeader}
      </div>
      {slots.ctas && <div className={classes.right}>{slots.ctas}</div>}
    </div>,
    containerRef.current,
  )
}

export default AppHeaderLayout
