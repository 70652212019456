import { createUseStyles } from "react-jss"

export const useConnectorsUpgradeDetailsModalStyles = createUseStyles(() => ({
  content: {
    height: 410,
    "&.MuiDialogContent-root:first-child": {
      padding: "19px 24px 25px",
      marginRight: 0,
      display: "flex",
      flexDirection: "column",
    },
  },
  table: {
    "& tr:nth-child(odd)": {
      height: 50,
    },
    "& h6, td": {
      fontSize: 16,
    },
  },
  upgradeFailedWrapper: {
    justifyContent: "flex-end",
  },
  paginationWrapper: {
    gap: 10,
    marginTop: 20,
    "& .MuiButtonBase-root": {
      padding: "10px 0",
    },
  },
}))
