import { ColDef } from "ag-grid-community"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DataGrid from "src/components/DataGrid/DataGrid"
import { DataGridLoader } from "src/components/DataGrid/DataGridLoader"
import BadgeWithIcons from "src/components/DataGrid/renderers/BadgeWithIconsCell/BadgeWithIconCell"
import StatusCell from "src/components/DataGrid/renderers/StatusCell"
import {
  InstanceType,
  useConnectorExpandedView,
} from "src/pages/ServiceConnectors/ConnectorExpandedView/useConnectorExpandedView"
import RetryConnectorInstanceUpgrade from "src/pages/ServiceConnectors/RetryConnectorInstanceUpgrade"
import { HostsParsedType } from "src/services/api/swrHooks/useHosts"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { RootState } from "src/store"
import { closeModal, openModal } from "src/store/ui"
import theme from "src/theme"
import { useConnectorExpandedInstancesViewStyles } from "./ConnectorExpandedInstancesView.styles"

interface ConnectorExpandedInstancesViewProps {
  hostData: HostsParsedType
  getServiceConnectors: () => void
}

const ConnectorExpandedInstancesView: React.FC<ConnectorExpandedInstancesViewProps> = ({
  hostData,
  getServiceConnectors,
}) => {
  const classes = useConnectorExpandedInstancesViewStyles()
  const dispatch = useDispatch()
  const openedModals = useSelector((state: RootState) => state.ui.openedModals)
  const [instanceData, setInstanceData] = useState<InstanceType | undefined>(undefined)

  const { expandedViewData, expandedViewError, expandedViewLoading, getExpandedViewData } = useConnectorExpandedView({
    connectorId: hostData.id,
  })

  const onModalClose = () => {
    setInstanceData(undefined)
    getExpandedViewData()
    getServiceConnectors()
  }

  const colDefs: ColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Instance Name",
        tooltipField: "name",
        cellClass: classes.fitContent,
        cellRenderer: StatusCell,
        cellRendererParams: {
          config: {
            key: "connectionStatusInfoText",
            successValue: "active",
            dangerValue: "inActive",
            warningValue: "",
            showStatusDot: false,
          },
          activeSinceConfig: {
            activeSinceKey: "connectionSince",
            activeSinceStyle: "inLine",
          },
        },
        width: 356,
      },
      {
        field: "state",
        headerName: "",
        cellClass: classes.fitContent,
        cellRenderer: BadgeWithIcons,
        cellRendererParams: {
          badgeConfig: {
            successValues: ["UP", "Upgrade Queued"],
            warningValues: [
              "Deploying...",
              "Activating...",
              "Deactivating...",
              "Removing...",
              "Updating MTU...",
              "Upgrading...",
            ],
            dangerValues: ["DOWN"],
            badgeTooltipKey: "instanceStatusTooltip",
            iconConfiguration: {
              iconName: "encircledInfo",
              showIconValues: () => ["upgrade failed"],
              onIconClick: (rowData: InstanceType) => {
                setInstanceData(rowData)
                dispatch(openModal("modalRetryConnectorInstanceUpgrade"))
              },
            },
          },
        },
      },
    ],
    [],
  )

  return (
    <div className={classes.root}>
      {expandedViewLoading ? (
        <DataGridLoader />
      ) : (
        <>
          {!expandedViewError && !!expandedViewData?.instances.length ? (
            <DataGrid
              columnDefs={colDefs}
              rowData={expandedViewData?.instances || []}
              onGridReady={(params) => params.api.sizeColumnsToFit()}
              hasFilters={false}
              containerHeight={300}
            />
          ) : (
            <span className={classes.blankSlate}>
              <ZtnaIcon name="encircledInfo" color={theme.color.grey.A200} />
              <ENTextPassage>No instance added yet. Add instance to view instance details</ENTextPassage>
            </span>
          )}
        </>
      )}

      {openedModals["modalRetryConnectorInstanceUpgrade"] && instanceData && (
        <RetryConnectorInstanceUpgrade
          onModalClose={() => {
            dispatch(closeModal("modalRetryConnectorInstanceUpgrade"))
            onModalClose()
          }}
          instanceData={instanceData}
          connectorName={hostData.name}
        />
      )}
    </div>
  )
}

export default ConnectorExpandedInstancesView
