import clsx from "clsx"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { useStepCircleStyles } from "./StepCircle.styles"

export interface Props {
  circleNumber: number
  circleType: "initial" | "current" | "complete"
}

const StepCircle = ({ circleType: type, circleNumber }: Props) => {
  const classes = useStepCircleStyles()

  return (
    <div className={clsx(classes.root, classes[type])}>
      {type === "complete" ? <ZtnaIcon name="enDone" /> : circleNumber}
    </div>
  )
}

export default StepCircle
