import store from "src/store"
import { LogLevels } from "./types"

interface TrackingDataType {
  level: LogLevels
  data?: { [s: string]: any } | Error
  tags?: { [s: string]: string }
  app?: string | { [s: string]: string }
  email?: string
  workspace?: string
  includeUserData?: boolean
}

export const getTrackingData = ({
  data,
  tags,
  level,
  app = "ztmesh-web-portal",
  includeUserData = true,
}: TrackingDataType): TrackingDataType => {
  const { email, workspace } = store.getState()?.auth?.user
  const userData = includeUserData
    ? {
        ...(email ? { email } : {}),
        ...(workspace ? { workspace } : {}),
      }
    : {}

  return { ...data, ...tags, ...userData, level, app }
}
