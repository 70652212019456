import { createUseStyles } from "react-jss"

export const useApplicationTroubleshootingStyles = createUseStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.color.border.divider}`,
    backgroundColor: theme.color.background.surfaceElevation1,
    padding: "16px 16px 24px 16px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  cardHeading: {
    fontSize: 16,
    marginBottom: 5,
  },
  cardDescription: {
    fontSize: 14,
    color: theme.color.content.secondary,
    lineHeight: "20px",
  },
  cta: {
    whiteSpace: "nowrap",
    marginLeft: 8,
  },
  blankslate: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 19,
    padding: "15px 0px 10px",
    "& p": {
      textAlign: "center",
    },
  },
  blankslateIcon: {
    padding: 14,
    borderRadius: "50%",
    backgroundColor: theme.color.background.surfaceElevation2,
  },
  stepsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  divider: {
    borderTop: `1px solid ${theme.color.border.divider}`,
    marginTop: 8,
  },
  supportTicket: {
    display: "flex",
    alignItems: "center",
    gap: 28,
    marginTop: 24,
  },
  ticketText: {
    color: theme.color.neutralLight[16],
  },
  ticketLink: {
    color: theme.color.content.accentDefault,
    fontWeight: 600,
    cursor: "pointer",
  },
}))
