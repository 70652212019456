import { ENButton } from "en-react/dist/src/components/Button"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENIconDotsVertical } from "en-react/dist/src/components/Icons/DotsVertical"
import { ENMenu } from "en-react/dist/src/components/Menu"
import { ENMenuItem } from "en-react/dist/src/components/MenuItem"
import { Fragment, useEffect, useRef, useState } from "react"
import ZtnaIcon from "../Icons/ZtnaIcon"
import { ButtonType } from "../ZtnaButton"
import ZtnaTooltip from "../ZtnaTooltip"

interface PopoverButtonType {
  name: string
  title?: string | ((rowData: any) => string)
  callback?: (name: string, rowData: any) => void
  disabled?: (rowData: any) => boolean
  variant?: "normal" | "separator" | "remove" | ((rowData: any) => "normal")
  show?: (rowData: any) => boolean
  hide?: (rowData: any) => boolean
  tooltipText?: string
  disabledTooltipText?: string | ((rowData: any) => string)
  emptySlateElement?: JSX.Element
}

type Props = {
  isOpen?: boolean
  title?: string
  hideText?: boolean | undefined
  items: PopoverButtonType[]
  onItemClick?: () => void
  rowData?: any
  position?: "bottom-left" | "bottom-right" | "top-right" | "top-left" | "left" | "right" | undefined
  onClose?: () => void
  onActionClick?: (e: PointerEvent) => void
  buttonProps?: {
    variant?: "error" | "tertiary" | "secondary" | "primary"
    dataTestid: string | undefined
    id: string
    hideText: boolean | undefined
  }
  styles?: React.CSSProperties
}

const getButtonVariant = (buttonType: string): ButtonType => {
  switch (buttonType) {
    case "tertiary":
      return "tertiary"
    case "secondary":
      return "secondary"
    case "error":
      return "danger"
  }
}

const EnMenu: React.FC<Props> = (props) => {
  const { title, items, onItemClick, rowData, styles, position = "bottom-left" } = props

  const { variant = "tertiary", ...buttonProps } = props?.buttonProps || {}

  const hasNoItems = items.every((item) => (item.show && !item.show(rowData)) || item.variant === "separator")

  const buttonsDisplay = items.map((item) => (item.show ? item.show(rowData) : item.hide ? !item.hide(rowData) : true))

  const [active, setActive] = useState(false)

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActive(false)
      }
    }
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  if (hasNoItems) {
    return null
  }

  return (
    <div
      style={{
        zIndex: styles?.zIndex ? styles.zIndex : 1000,
        position: "relative",
        // top: "8px",
      }}
      ref={menuRef}
    >
      <ENMenu
        isActive={active}
        toggleActive={() => setActive((prev) => !prev)}
        // width={280}
        position={position}
      >
        <Fragment key=".0">
          <ENButton variant={getButtonVariant(variant)} {...buttonProps} slot="trigger">
            {title || <ENIconDotsVertical slot="before" />}

            {title && (
              <span style={{ marginLeft: 10 }} slot="after">
                <ZtnaIcon name={active ? "dropdownUp" : "dropdownDown"} />
              </span>
            )}
          </ENButton>

          {items.map(
            ({ name, title, callback, show, hide, disabled, variant, disabledTooltipText, tooltipText }, idx) => {
              const tooltipTitle =
                (disabled?.(rowData)
                  ? typeof disabledTooltipText === "function"
                    ? disabledTooltipText(rowData)
                    : disabledTooltipText
                  : tooltipText) || ""

              const menuItem = (
                <ENMenuItem
                  isSelected={false}
                  data-testid={`${title}-button`}
                  key={`PopoverButtons-${name}`}
                  id={`en-ztna-PopoverButtons-${name}`}
                  onClick={() => {
                    setActive(false)
                    callback?.(name, rowData)
                    onItemClick?.()
                  }}
                  style={(hide && !!hide(rowData)) || (show && !show(rowData)) ? { display: "none" } : {}}
                  isDisabled={(disabled && disabled(rowData)) || false}
                >
                  {typeof title === "function" ? title(rowData) : title}
                </ENMenuItem>
              )

              return (
                <Fragment key={idx}>
                  {variant === "separator" ? (
                    buttonsDisplay.slice(idx + 1).includes(true) &&
                    buttonsDisplay
                      .reverse()
                      .slice(idx + 1)
                      .includes(true) && <ENDivider data-testid="separator" />
                  ) : tooltipTitle ? (
                    <ZtnaTooltip title={tooltipTitle} arrow key={`PopoverTooltip-${name}`}>
                      {menuItem}
                    </ZtnaTooltip>
                  ) : (
                    menuItem
                  )}
                </Fragment>
              )
            },
          )}
        </Fragment>
      </ENMenu>
    </div>
  )
}

export default EnMenu
