import { createUseStyles } from "react-jss"

export const useApplicationPolicyEvaluationFormStyles = createUseStyles((theme) => ({
  root: {
    borderRadius: 8,
    padding: "24px 16px",
    border: `1px solid ${theme.color.border.divider}`,
    backgroundColor: theme.color.background.surfaceElevation1,
  },
  form: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  fieldWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 21,
    rowGap: 16,
  },
  select: {
    display: "flex",
    flexDirection: "column",
    width: 456,
    marginBottom: 16,
    "& .react-select__placeholder": {
      fontSize: 14,
    },
    "& .react-select__option--is-selected": {
      "& p": {
        color: theme.color.neutralLight[16],
      },
    },
    "& .react-select__control": {
      borderRadius: 2,
      maxHeight: 72,
      overflowX: "hidden",
    },
    "& .react-select__indicators": {
      top: 8,
      right: 0,
    },
  },
  accessModeRadioGroup: {
    gap: 0,
    marginTop: 5,
  },
  agentMessage: {
    color: theme.color.content.secondary,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
  },
  timeBasedConditionWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  timeFieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: 16,
    width: 456,
  },
  formFooter: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
    alignItems: "center",
  },
  lastEvaluation: {
    color: theme.color.content.secondary,
    fontSize: 14,
  },
}))
