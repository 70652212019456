import { intersection } from "lodash"
import { RouteConfig, RouteType, RouteWithoutComponentType } from "src/routes/config"
import { FeatureFlagsType } from "src/store/ui/types"

const filterRoutesWithPermission = (routes: RouteType[], roles: string[]): RouteType[] => {
  return routes.filter(({ permission }) => {
    if (!permission) return true
    if (!permission?.length) return true
    return intersection(permission, roles).length
  })
}

const removeComponentsKeyfromRoutesArray = (routes: RouteType[]): RouteWithoutComponentType[] => {
  return routes.map(({ component, ...route }) => {
    const childRoutes = route.childs?.map(({ component, ...child }) => child)
    return { ...route, childs: childRoutes }
  })
}

export const getAllowedRoutes = (roles: string[], flags: FeatureFlagsType): RouteType[] => {
  const routes = RouteConfig(flags, roles[0]) || []
  const filteredRoutesConfig = filterRoutesWithPermission(removeComponentsKeyfromRoutesArray(routes), roles)
  return filteredRoutesConfig.map((route) => {
    const { childs } = route
    if (childs && childs?.length) {
      const filteredChilds = filterRoutesWithPermission(removeComponentsKeyfromRoutesArray(childs), roles)
      return { ...route, childs: filteredChilds }
    }
    return route
  })
}

export const getSelectedRoute = (url: string, allowedRoutes: RouteType[]): RouteType | undefined => {
  let route

  allowedRoutes.forEach((element) => {
    const { childs, path } = element
    const matchURL = path
    if (childs?.length) {
      childs.forEach((childElement) => {
        const matchChildURL = childElement.path
        if (url === matchChildURL?.absolutePath) {
          route = childElement
        }
      })
    } else if (url === matchURL?.absolutePath) {
      route = element
    }
  })

  return route
}
