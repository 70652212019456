import clsx from "clsx"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { ENToast } from "en-react/dist/src/components/Toast"
import { useEffect, useState } from "react"
import { EVALUATION_RESULTS_DOWNLOAD, POLICY_EVALUATION_RESULTS } from "src/constants"
import useFetch from "src/hooks/useFetch"
import CircularLoader from "src/shared/components/CicularLoader"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { usePolicyEvaluationResultStyles } from "./PolicyEvaluationResult.styles"

type EvaluationResultPolicyType = {
  name: string
  access: boolean
  reasons?: string[]
}

const Loader = () => {
  const classes = usePolicyEvaluationResultStyles()
  return (
    <div className={classes.loader}>
      <CircularLoader />

      <ENTextPassage>
        <p className={classes.loaderText}>Loading evaluation result...</p>
      </ENTextPassage>
    </div>
  )
}

const ErrorSlate = () => {
  const classes = usePolicyEvaluationResultStyles()
  return (
    <div className={classes.errorSlate}>
      <ENToast
        isActive
        variant="danger"
        description="Application Troubleshooting cannot be initiated as the policy conditions restrict user access to application"
      >
        Policy Evaluation Failed
      </ENToast>
    </div>
  )
}

const BlankSlate = () => {
  const classes = usePolicyEvaluationResultStyles()
  return (
    <div className={classes.blankslate}>
      <div className={classes.blankslateIcon}>
        <ZtnaIcon name="enProtect" size="xl" />
      </div>

      <ENTextPassage>
        <p>There are no policies created.</p>
      </ENTextPassage>
    </div>
  )
}

const PolicyItem = ({ name, access, reasons }: EvaluationResultPolicyType) => {
  const classes = usePolicyEvaluationResultStyles()
  const legendClass = access ? classes.greenLegend : classes.redLegend
  const reasonText = reasons && reasons.length > 1 ? "Reasons" : "Reason"

  return (
    <div>
      <div className={classes.policyItem}>
        <div className={clsx(classes.legend, legendClass)} />
        <ENTextPassage>
          <p className={classes.policyText}>{name}</p>
        </ENTextPassage>
      </div>

      {reasons && (
        <div className={classes.reason}>
          <ENTextPassage>
            <p className={classes.policyText}>
              <strong>{reasonText}:</strong>
            </p>
          </ENTextPassage>
          <ul>
            {reasons.map((item, idx) => (
              <li key={`reason-${idx}`}>
                <ENTextPassage>
                  <p className={classes.policyText}>{item}</p>
                </ENTextPassage>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

interface Props {
  fetchEvaluationResult: boolean
  setFetchEvaluationResult: (val: boolean) => void
  setFetchingEvaluationResult: (val: boolean) => void
  setZeroActivePolicies: (val: boolean) => void
}

const PolicyEvaluationResult = ({
  fetchEvaluationResult,
  setFetchEvaluationResult,
  setFetchingEvaluationResult,
  setZeroActivePolicies,
}: Props) => {
  const classes = usePolicyEvaluationResultStyles()

  const [showAll, setShowAll] = useState(false)

  const [policiesToDisplay, setPoliciesToDisplay] = useState<EvaluationResultPolicyType[]>([])

  const { data, error, isLoading, isValidating, mutate } = useFetch<EvaluationResultPolicyType[]>({
    apiUrl: POLICY_EVALUATION_RESULTS,
    options: { shouldRetryOnError: false },
  })

  useEffect(() => {
    if (fetchEvaluationResult) {
      mutate()
      setFetchEvaluationResult(false)
    }
  }, [fetchEvaluationResult])

  useEffect(() => {
    setFetchingEvaluationResult(isLoading || isValidating)
  }, [isLoading, isValidating])

  const handleDownload = () => window.open(EVALUATION_RESULTS_DOWNLOAD, "_blank")

  const handleShowToggle = () => setShowAll((prev) => !prev)

  useEffect(() => {
    setPoliciesToDisplay(Array.isArray(data) ? (showAll ? data : data?.slice(0, 5)) : [])
  }, [data, showAll])

  useEffect(() => {
    if (data) setZeroActivePolicies(data?.length === 0 || !data?.some((item) => item.access))
  }, [data])

  return (
    <div>
      <div className={classes.header}>
        <ENTextPassage>
          <p className={classes.headerHeading}>Evaluation Result</p>
        </ENTextPassage>

        {!(isLoading || isValidating) && !!data?.length && (
          <div role="button" onClick={handleDownload} className={classes.cursorPointer}>
            <ZtnaIcon name="enDownload" size="lg" />
          </div>
        )}
      </div>

      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <ENTextPassage>
            <p className={classes.cardHeading}>Policy Evaluation</p>
          </ENTextPassage>

          {!isLoading && (
            <div className={classes.legendsContainer}>
              <div className={classes.legendContainer}>
                <div className={clsx(classes.legend, classes.greenLegend)} />

                <ENTextPassage>
                  <p className={classes.legendText}>Policy provides user access to application</p>
                </ENTextPassage>
              </div>

              <div className={classes.legendContainer}>
                <div className={clsx(classes.legend, classes.redLegend)} />

                <ENTextPassage>
                  <p className={classes.legendText}>Policy conditions restrict user access to application</p>
                </ENTextPassage>
              </div>
            </div>
          )}
        </div>

        {isLoading || isValidating ? (
          <Loader />
        ) : error ? (
          <ErrorSlate />
        ) : (
          Array.isArray(data) &&
          (data.length > 0 ? (
            <>
              <div className={classes.policiesContainer}>
                {policiesToDisplay.map((item, idx) => (
                  <div key={`application-policy-${idx}`} className={classes.policyItem}>
                    <PolicyItem key={`application-policy-${idx}`} {...item} />
                  </div>
                ))}
              </div>

              {data.length > 5 && (
                <ENTextPassage onClick={handleShowToggle}>
                  <p className={clsx(classes.cursorPointer, classes.showButton)}>Show {showAll ? "Less" : "More"}</p>
                </ENTextPassage>
              )}
            </>
          ) : (
            <BlankSlate />
          ))
        )}
      </div>
    </div>
  )
}

export default PolicyEvaluationResult
