export const COUNTRIES_LIST = [
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Brunei Darussalam", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Christmas Island", value: "CX" },
  { label: "Cocos (Keeling) Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Congo", value: "CG" },
  { label: "Congo, The Democratic Republic of the", value: "CD" },
  { label: "Cook Islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Cote D'Ivoire", value: "CI" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Cyprus", value: "CY" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Denmark", value: "DK" },
  { label: "Djibouti", value: "DJ" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Falkland Islands (Malvinas)", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Fiji", value: "FJ" },
  { label: "Finland", value: "FI" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Polynesia", value: "PF" },
  { label: "French Southern Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Grenada", value: "GD" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard Island and Mcdonald Islands", value: "HM" },
  { label: "Holy See (Vatican City State)", value: "VA" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "Iceland", value: "IS" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iran, Islamic Republic Of", value: "IR" },
  { label: "Iraq", value: "IQ" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kiribati", value: "KI" },
  { label: "Korea, Democratic People'S Republic of", value: "KP" },
  { label: "Korea, Republic of", value: "KR" },
  { label: "Kuwait", value: "KW" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Lao People'S Democratic Republic", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lebanon", value: "LB" },
  { label: "Lesotho", value: "LS" },
  { label: "Liberia", value: "LR" },
  { label: "Libyan Arab Jamahiriya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Maldives", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauritania", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia, Federated States of", value: "FM" },
  { label: "Moldova, Republic of", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montserrat", value: "MS" },
  { label: "Morocco", value: "MA" },
  { label: "Mozambique", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Netherlands", value: "NL" },
  { label: "Netherlands Antilles", value: "AN" },
  { label: "New Caledonia", value: "NC" },
  { label: "New Zealand", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk Island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Norway", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestinian Territory, Occupied", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn", value: "PN" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reunion", value: "RE" },
  { label: "Romania", value: "RO" },
  { label: "Russian Federation", value: "RU" },
  { label: "RWANDA", value: "RW" },
  { label: "Saint Helena", value: "SH" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "Saint Lucia", value: "LC" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "Saint Vincent and the Grenadines", value: "VC" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia and Montenegro", value: "CS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Solomon Islands", value: "SB" },
  { label: "Somalia", value: "SO" },
  { label: "South Africa", value: "ZA" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "Spain", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Suriname", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Sweden", value: "SE" },
  { label: "Switzerland", value: "CH" },
  { label: "Syrian Arab Republic", value: "SY" },
  { label: "Taiwan, Province of China", value: "TW" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Tanzania, United Republic of", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Timor-Leste", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkey", value: "TR" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States", value: "US" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Viet Nam", value: "VN" },
  { label: "Virgin Islands, British", value: "VG" },
  { label: "Virgin Islands, U.S.", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Western Sahara", value: "EH" },
  { label: "Yemen", value: "YE" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
]

export const getCountryValue = (countryLabel?: string): string | undefined => {
  if (!countryLabel) {
    return undefined
  }
  return COUNTRIES_LIST.find((country) => country.label === countryLabel)?.value || countryLabel
}

export const PROTOCOL_TYPES = [
  {
    label: "UDP",
    value: "UDP",
  },
  {
    label: "TCP",
    value: "TCP",
  },
  {
    label: "UDP or TCP",
    value: "UDP or TCP",
  },
  {
    label: "ICMP",
    value: "ICMP",
  },
  {
    label: "ANY",
    value: "ANY",
  },
]
export const authenticationTypeOptions = [
  { label: "MAC-AUTH", value: "MAC-AUTH" },
  { label: "EAP-TTLS", value: "EAP-TTLS" },
  { label: "EAP-TLS", value: "EAP-TLS" },
]

export const timeIntervalOptions = [
  { label: "Today", value: "today", id: "today" },
  { label: "Last 1 hour", value: "last1Hour", id: "last1Hour" },
  { label: "Last 2 hour", value: "last2Hour", id: "last2Hour" },
  { label: "Last 7 days", value: "last7Days", id: "last7Days" },
  { label: "Last 30 days", value: "last30Days", id: "last30Days" },
]

export const timeDropdownOptions = [
  { label: "Last 24 hours", value: "last24Hours" },
  { label: "Last 1 hour", value: "last1Hour" },
  { label: "Last 2 hours", value: "last2Hour" },
  { label: "Last 7 days", value: "last7Days" },
  { label: "Last 30 days", value: "last30Days" },
]

export const timeframeOptions = [
  { label: "Last 1 hour", value: "hour" },
  { label: "Last 24 hours", value: "day" },
  { label: "Last 7 days", value: "week" },
]

export const TIME_ZONES_LIST = [
  {
    label: "Pacific/Midway (UTC-11:00)",
    tzCode: "Pacific/Midway",
    name: "(UTC-11:00) Midway",
    utc: "-11:00",
  },
  {
    label: "Pacific/Niue (UTC-11:00)",
    tzCode: "Pacific/Niue",
    name: "(UTC-11:00) Alofi",
    utc: "-11:00",
  },
  {
    label: "Pacific/Pago_Pago (UTC-11:00)",
    tzCode: "Pacific/Pago_Pago",
    name: "(UTC-11:00) Pago Pago, Tāfuna, Ta`ū, Taulaga",
    utc: "-11:00",
  },
  {
    label: "America/Adak (UTC-10:00)",
    tzCode: "America/Adak",
    name: "(UTC-10:00) Adak",
    utc: "-10:00",
  },
  {
    label: "Pacific/Honolulu (UTC-10:00)",
    tzCode: "Pacific/Honolulu",
    name: "(UTC-10:00) Honolulu, East Honolulu, Pearl City, Hilo, Kailua",
    utc: "-10:00",
  },
  {
    label: "Pacific/Rarotonga (UTC-10:00)",
    tzCode: "Pacific/Rarotonga",
    name: "(UTC-10:00) Avarua",
    utc: "-10:00",
  },
  {
    label: "Pacific/Tahiti (UTC-10:00)",
    tzCode: "Pacific/Tahiti",
    name: "(UTC-10:00) Faaa, Papeete, Punaauia, Pirae, Mahina",
    utc: "-10:00",
  },
  {
    label: "Pacific/Marquesas (UTC-09:30)",
    tzCode: "Pacific/Marquesas",
    name: "(UTC-09:30) Taiohae",
    utc: "-09:30",
  },
  {
    label: "America/Anchorage (UTC-09:00)",
    tzCode: "America/Anchorage",
    name: "(UTC-09:00) Anchorage, Fairbanks, Eagle River, Badger, Knik-Fairview",
    utc: "-09:00",
  },
  {
    label: "America/Juneau (UTC-09:00)",
    tzCode: "America/Juneau",
    name: "(UTC-09:00) Juneau",
    utc: "-09:00",
  },
  {
    label: "America/Metlakatla (UTC-09:00)",
    tzCode: "America/Metlakatla",
    name: "(UTC-09:00) Metlakatla",
    utc: "-09:00",
  },
  {
    label: "America/Nome (UTC-09:00)",
    tzCode: "America/Nome",
    name: "(UTC-09:00) Nome",
    utc: "-09:00",
  },
  {
    label: "America/Sitka (UTC-09:00)",
    tzCode: "America/Sitka",
    name: "(UTC-09:00) Sitka, Ketchikan",
    utc: "-09:00",
  },
  {
    label: "America/Yakutat (UTC-09:00)",
    tzCode: "America/Yakutat",
    name: "(UTC-09:00) Yakutat",
    utc: "-09:00",
  },
  {
    label: "Pacific/Gambier (UTC-09:00)",
    tzCode: "Pacific/Gambier",
    name: "(UTC-09:00) Gambier",
    utc: "-09:00",
  },
  {
    label: "America/Los_Angeles (UTC-08:00)",
    tzCode: "America/Los_Angeles",
    name: "(UTC-08:00) Los Angeles, San Diego, San Jose, San Francisco, Seattle",
    utc: "-08:00",
  },
  {
    label: "America/Tijuana (UTC-08:00)",
    tzCode: "America/Tijuana",
    name: "(UTC-08:00) Tijuana, Mexicali, Ensenada, Rosarito, Tecate",
    utc: "-08:00",
  },
  {
    label: "America/Vancouver (UTC-08:00)",
    tzCode: "America/Vancouver",
    name: "(UTC-08:00) Vancouver, Surrey, Okanagan, Victoria, Burnaby",
    utc: "-08:00",
  },
  {
    label: "Pacific/Pitcairn (UTC-08:00)",
    tzCode: "Pacific/Pitcairn",
    name: "(UTC-08:00) Adamstown",
    utc: "-08:00",
  },
  {
    label: "America/Boise (UTC-07:00)",
    tzCode: "America/Boise",
    name: "(UTC-07:00) Boise, Meridian, Nampa, Idaho Falls, Pocatello",
    utc: "-07:00",
  },
  {
    label: "America/Cambridge_Bay (UTC-07:00)",
    tzCode: "America/Cambridge_Bay",
    name: "(UTC-07:00) Cambridge Bay",
    utc: "-07:00",
  },
  {
    label: "America/Chihuahua (UTC-07:00)",
    tzCode: "America/Chihuahua",
    name: "(UTC-07:00) Chihuahua, Ciudad Delicias, Cuauhtémoc, Parral, Nuevo Casas Grandes",
    utc: "-07:00",
  },
  {
    label: "America/Creston (UTC-07:00)",
    tzCode: "America/Creston",
    name: "(UTC-07:00) Creston",
    utc: "-07:00",
  },
  {
    label: "America/Dawson (UTC-07:00)",
    tzCode: "America/Dawson",
    name: "(UTC-07:00) Dawson",
    utc: "-07:00",
  },
  {
    label: "America/Dawson_Creek (UTC-07:00)",
    tzCode: "America/Dawson_Creek",
    name: "(UTC-07:00) Fort St. John, Dawson Creek",
    utc: "-07:00",
  },
  {
    label: "America/Denver (UTC-07:00)",
    tzCode: "America/Denver",
    name: "(UTC-07:00) Denver, El Paso, Albuquerque, Colorado Springs, Aurora",
    utc: "-07:00",
  },
  {
    label: "America/Edmonton (UTC-07:00)",
    tzCode: "America/Edmonton",
    name: "(UTC-07:00) Calgary, Edmonton, Fort McMurray, Red Deer, Lethbridge",
    utc: "-07:00",
  },
  {
    label: "America/Fort_Nelson (UTC-07:00)",
    tzCode: "America/Fort_Nelson",
    name: "(UTC-07:00) Fort Nelson",
    utc: "-07:00",
  },
  {
    label: "America/Hermosillo (UTC-07:00)",
    tzCode: "America/Hermosillo",
    name: "(UTC-07:00) Hermosillo, Ciudad Obregón, Nogales, San Luis Río Colorado, Navojoa",
    utc: "-07:00",
  },
  {
    label: "America/Inuvik (UTC-07:00)",
    tzCode: "America/Inuvik",
    name: "(UTC-07:00) Inuvik",
    utc: "-07:00",
  },
  {
    label: "America/Mazatlan (UTC-07:00)",
    tzCode: "America/Mazatlan",
    name: "(UTC-07:00) Culiacán, Mazatlán, Tepic, Los Mochis, La Paz",
    utc: "-07:00",
  },
  {
    label: "America/Ojinaga (UTC-07:00)",
    tzCode: "America/Ojinaga",
    name: "(UTC-07:00) Ciudad Juárez, Manuel Ojinaga, Ojinaga",
    utc: "-07:00",
  },
  {
    label: "America/Phoenix (UTC-07:00)",
    tzCode: "America/Phoenix",
    name: "(UTC-07:00) Phoenix, Tucson, Mesa, Chandler, Gilbert",
    utc: "-07:00",
  },
  {
    label: "America/Whitehorse (UTC-07:00)",
    tzCode: "America/Whitehorse",
    name: "(UTC-07:00) Whitehorse",
    utc: "-07:00",
  },
  {
    label: "America/Yellowknife (UTC-07:00)",
    tzCode: "America/Yellowknife",
    name: "(UTC-07:00) Yellowknife",
    utc: "-07:00",
  },
  {
    label: "America/Bahia_Banderas (UTC-06:00)",
    tzCode: "America/Bahia_Banderas",
    name: "(UTC-06:00) Mezcales, San Vicente, Bucerías, Valle de Banderas",
    utc: "-06:00",
  },
  {
    label: "America/Belize (UTC-06:00)",
    tzCode: "America/Belize",
    name: "(UTC-06:00) Belize City, San Ignacio, Orange Walk, Belmopan, Dangriga",
    utc: "-06:00",
  },
  {
    label: "America/Chicago (UTC-06:00)",
    tzCode: "America/Chicago",
    name: "(UTC-06:00) Chicago, Houston, San Antonio, Dallas, Austin",
    utc: "-06:00",
  },
  {
    label: "America/Costa_Rica (UTC-06:00)",
    tzCode: "America/Costa_Rica",
    name: "(UTC-06:00) San José, Limón, San Francisco, Alajuela, Liberia",
    utc: "-06:00",
  },
  {
    label: "America/El_Salvador (UTC-06:00)",
    tzCode: "America/El_Salvador",
    name: "(UTC-06:00) San Salvador, Soyapango, Santa Ana, San Miguel, Mejicanos",
    utc: "-06:00",
  },
  {
    label: "America/Guatemala (UTC-06:00)",
    tzCode: "America/Guatemala",
    name: "(UTC-06:00) Guatemala City, Mixco, Villa Nueva, Petapa, San Juan Sacatepéquez",
    utc: "-06:00",
  },
  {
    label: "America/Indiana/Knox (UTC-06:00)",
    tzCode: "America/Indiana/Knox",
    name: "(UTC-06:00) Knox",
    utc: "-06:00",
  },
  {
    label: "America/Indiana/Tell_City (UTC-06:00)",
    tzCode: "America/Indiana/Tell_City",
    name: "(UTC-06:00) Tell City",
    utc: "-06:00",
  },
  {
    label: "America/Managua (UTC-06:00)",
    tzCode: "America/Managua",
    name: "(UTC-06:00) Managua, León, Masaya, Chinandega, Matagalpa",
    utc: "-06:00",
  },
  {
    label: "America/Matamoros (UTC-06:00)",
    tzCode: "America/Matamoros",
    name: "(UTC-06:00) Reynosa, Heroica Matamoros, Nuevo Laredo, Piedras Negras, Ciudad Acuña",
    utc: "-06:00",
  },
  {
    label: "America/Menominee (UTC-06:00)",
    tzCode: "America/Menominee",
    name: "(UTC-06:00) Menominee, Iron Mountain, Kingsford, Ironwood, Iron River",
    utc: "-06:00",
  },
  {
    label: "America/Merida (UTC-06:00)",
    tzCode: "America/Merida",
    name: "(UTC-06:00) Mérida, Campeche, Ciudad del Carmen, Kanasín, Valladolid",
    utc: "-06:00",
  },
  {
    label: "America/Mexico_City (UTC-06:00)",
    tzCode: "America/Mexico_City",
    name: "(UTC-06:00) Mexico City, Iztapalapa, Ecatepec de Morelos, Guadalajara, Puebla",
    utc: "-06:00",
  },
  {
    label: "America/Monterrey (UTC-06:00)",
    tzCode: "America/Monterrey",
    name: "(UTC-06:00) Monterrey, Saltillo, Guadalupe, Torreón, Victoria de Durango",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/Beulah (UTC-06:00)",
    tzCode: "America/North_Dakota/Beulah",
    name: "(UTC-06:00) Beulah",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/Center (UTC-06:00)",
    tzCode: "America/North_Dakota/Center",
    name: "(UTC-06:00) Center",
    utc: "-06:00",
  },
  {
    label: "America/North_Dakota/New_Salem (UTC-06:00)",
    tzCode: "America/North_Dakota/New_Salem",
    name: "(UTC-06:00) Mandan",
    utc: "-06:00",
  },
  {
    label: "America/Rainy_River (UTC-06:00)",
    tzCode: "America/Rainy_River",
    name: "(UTC-06:00) Rainy River",
    utc: "-06:00",
  },
  {
    label: "America/Rankin_Inlet (UTC-06:00)",
    tzCode: "America/Rankin_Inlet",
    name: "(UTC-06:00) Rankin Inlet",
    utc: "-06:00",
  },
  {
    label: "America/Regina (UTC-06:00)",
    tzCode: "America/Regina",
    name: "(UTC-06:00) Saskatoon, Regina, Prince Albert, Moose Jaw, North Battleford",
    utc: "-06:00",
  },
  {
    label: "America/Resolute (UTC-06:00)",
    tzCode: "America/Resolute",
    name: "(UTC-06:00) Resolute",
    utc: "-06:00",
  },
  {
    label: "America/Swift_Current (UTC-06:00)",
    tzCode: "America/Swift_Current",
    name: "(UTC-06:00) Swift Current",
    utc: "-06:00",
  },
  {
    label: "America/Tegucigalpa (UTC-06:00)",
    tzCode: "America/Tegucigalpa",
    name: "(UTC-06:00) Tegucigalpa, San Pedro Sula, Choloma, La Ceiba, El Progreso",
    utc: "-06:00",
  },
  {
    label: "America/Winnipeg (UTC-06:00)",
    tzCode: "America/Winnipeg",
    name: "(UTC-06:00) Winnipeg, Brandon, Kenora, Portage la Prairie, Thompson",
    utc: "-06:00",
  },
  {
    label: "Pacific/Easter (UTC-06:00)",
    tzCode: "Pacific/Easter",
    name: "(UTC-06:00) Easter",
    utc: "-06:00",
  },
  {
    label: "Pacific/Galapagos (UTC-06:00)",
    tzCode: "Pacific/Galapagos",
    name: "(UTC-06:00) Puerto Ayora, Puerto Baquerizo Moreno",
    utc: "-06:00",
  },
  {
    label: "America/Atikokan (UTC-05:00)",
    tzCode: "America/Atikokan",
    name: "(UTC-05:00) Atikokan",
    utc: "-05:00",
  },
  {
    label: "America/Bogota (UTC-05:00)",
    tzCode: "America/Bogota",
    name: "(UTC-05:00) Bogotá, Cali, Medellín, Barranquilla, Cartagena",
    utc: "-05:00",
  },
  {
    label: "America/Cancun (UTC-05:00)",
    tzCode: "America/Cancun",
    name: "(UTC-05:00) Cancún, Chetumal, Playa del Carmen, Cozumel, Felipe Carrillo Puerto",
    utc: "-05:00",
  },
  {
    label: "America/Cayman (UTC-05:00)",
    tzCode: "America/Cayman",
    name: "(UTC-05:00) George Town, West Bay, Bodden Town, East End, North Side",
    utc: "-05:00",
  },
  {
    label: "America/Detroit (UTC-05:00)",
    tzCode: "America/Detroit",
    name: "(UTC-05:00) Detroit, Grand Rapids, Warren, Sterling Heights, Ann Arbor",
    utc: "-05:00",
  },
  {
    label: "America/Eirunepe (UTC-05:00)",
    tzCode: "America/Eirunepe",
    name: "(UTC-05:00) Eirunepé, Benjamin Constant, Envira",
    utc: "-05:00",
  },
  {
    label: "America/Grand_Turk (UTC-05:00)",
    tzCode: "America/Grand_Turk",
    name: "(UTC-05:00) Cockburn Town",
    utc: "-05:00",
  },
  {
    label: "America/Guayaquil (UTC-05:00)",
    tzCode: "America/Guayaquil",
    name: "(UTC-05:00) Guayaquil, Quito, Cuenca, Santo Domingo de los Colorados, Machala",
    utc: "-05:00",
  },
  {
    label: "America/Havana (UTC-05:00)",
    tzCode: "America/Havana",
    name: "(UTC-05:00) Havana, Santiago de Cuba, Camagüey, Holguín, Guantánamo",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Indianapolis (UTC-05:00)",
    tzCode: "America/Indiana/Indianapolis",
    name: "(UTC-05:00) Indianapolis, Fort Wayne, South Bend, Carmel, Bloomington",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Marengo (UTC-05:00)",
    tzCode: "America/Indiana/Marengo",
    name: "(UTC-05:00) Marengo",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Petersburg (UTC-05:00)",
    tzCode: "America/Indiana/Petersburg",
    name: "(UTC-05:00) Petersburg",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Vevay (UTC-05:00)",
    tzCode: "America/Indiana/Vevay",
    name: "(UTC-05:00) Vevay",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Vincennes (UTC-05:00)",
    tzCode: "America/Indiana/Vincennes",
    name: "(UTC-05:00) Vincennes, Jasper, Washington, Huntingburg",
    utc: "-05:00",
  },
  {
    label: "America/Indiana/Winamac (UTC-05:00)",
    tzCode: "America/Indiana/Winamac",
    name: "(UTC-05:00) Winamac",
    utc: "-05:00",
  },
  {
    label: "America/Iqaluit (UTC-05:00)",
    tzCode: "America/Iqaluit",
    name: "(UTC-05:00) Iqaluit",
    utc: "-05:00",
  },
  {
    label: "America/Jamaica (UTC-05:00)",
    tzCode: "America/Jamaica",
    name: "(UTC-05:00) Kingston, New Kingston, Spanish Town, Portmore, Montego Bay",
    utc: "-05:00",
  },
  {
    label: "America/Kentucky/Louisville (UTC-05:00)",
    tzCode: "America/Kentucky/Louisville",
    name: "(UTC-05:00) Louisville, Jeffersonville, New Albany, Jeffersontown, Pleasure Ridge Park",
    utc: "-05:00",
  },
  {
    label: "America/Kentucky/Monticello (UTC-05:00)",
    tzCode: "America/Kentucky/Monticello",
    name: "(UTC-05:00) Monticello",
    utc: "-05:00",
  },
  {
    label: "America/Lima (UTC-05:00)",
    tzCode: "America/Lima",
    name: "(UTC-05:00) Lima, Arequipa, Callao, Trujillo, Chiclayo",
    utc: "-05:00",
  },
  {
    label: "America/Nassau (UTC-05:00)",
    tzCode: "America/Nassau",
    name: "(UTC-05:00) Nassau, Lucaya, Freeport, West End, Cooper’s Town",
    utc: "-05:00",
  },
  {
    label: "America/New_York (UTC-05:00)",
    tzCode: "America/New_York",
    name: "(UTC-05:00) New York City, Brooklyn, Queens, Philadelphia, Manhattan",
    utc: "-05:00",
  },
  {
    label: "America/Nipigon (UTC-05:00)",
    tzCode: "America/Nipigon",
    name: "(UTC-05:00) Nipigon",
    utc: "-05:00",
  },
  {
    label: "America/Panama (UTC-05:00)",
    tzCode: "America/Panama",
    name: "(UTC-05:00) Panamá, San Miguelito, Juan Díaz, David, Arraiján",
    utc: "-05:00",
  },
  {
    label: "America/Pangnirtung (UTC-05:00)",
    tzCode: "America/Pangnirtung",
    name: "(UTC-05:00) Pangnirtung",
    utc: "-05:00",
  },
  {
    label: "America/Port-au-Prince (UTC-05:00)",
    tzCode: "America/Port-au-Prince",
    name: "(UTC-05:00) Port-au-Prince, Carrefour, Delmas 73, Pétionville, Port-de-Paix",
    utc: "-05:00",
  },
  {
    label: "America/Rio_Branco (UTC-05:00)",
    tzCode: "America/Rio_Branco",
    name: "(UTC-05:00) Rio Branco, Cruzeiro do Sul, Sena Madureira, Tarauacá, Feijó",
    utc: "-05:00",
  },
  {
    label: "America/Thunder_Bay (UTC-05:00)",
    tzCode: "America/Thunder_Bay",
    name: "(UTC-05:00) Thunder Bay",
    utc: "-05:00",
  },
  {
    label: "America/Toronto (UTC-05:00)",
    tzCode: "America/Toronto",
    name: "(UTC-05:00) Toronto, Montréal, Ottawa, Mississauga, Québec",
    utc: "-05:00",
  },
  {
    label: "America/AnguillaSandy Hill (UTC-04:00)",
    tzCode: "America/AnguillaSandy Hill",
    name: "(UTC-04:00) The Valley, Blowing Point Village, Sandy Ground Village, The Quarter, Sandy Hill",
    utc: "-04:00",
  },
  {
    label: "America/Antigua (UTC-04:00)",
    tzCode: "America/Antigua",
    name: "(UTC-04:00) Saint John’s, Piggotts, Bolands, Codrington, Parham",
    utc: "-04:00",
  },
  {
    label: "America/Aruba (UTC-04:00)",
    tzCode: "America/Aruba",
    name: "(UTC-04:00) Oranjestad, Tanki Leendert, San Nicolas, Santa Cruz, Paradera",
    utc: "-04:00",
  },
  {
    label: "America/Asuncion (UTC-04:00)",
    tzCode: "America/Asuncion",
    name: "(UTC-04:00) Asunción, Ciudad del Este, San Lorenzo, Capiatá, Lambaré",
    utc: "-04:00",
  },
  {
    label: "America/Barbados (UTC-04:00)",
    tzCode: "America/Barbados",
    name: "(UTC-04:00) Bridgetown, Speightstown, Oistins, Bathsheba, Holetown",
    utc: "-04:00",
  },
  {
    label: "America/Blanc-Sablon (UTC-04:00)",
    tzCode: "America/Blanc-Sablon",
    name: "(UTC-04:00) Lévis",
    utc: "-04:00",
  },
  {
    label: "America/Boa_Vista (UTC-04:00)",
    tzCode: "America/Boa_Vista",
    name: "(UTC-04:00) Boa Vista",
    utc: "-04:00",
  },
  {
    label: "America/Campo_Grande (UTC-04:00)",
    tzCode: "America/Campo_Grande",
    name: "(UTC-04:00) Campo Grande, Dourados, Corumbá, Três Lagoas, Ponta Porã",
    utc: "-04:00",
  },
  {
    label: "America/Caracas (UTC-04:00)",
    tzCode: "America/Caracas",
    name: "(UTC-04:00) Caracas, Maracaibo, Maracay, Valencia, Barquisimeto",
    utc: "-04:00",
  },
  {
    label: "America/Cuiaba (UTC-04:00)",
    tzCode: "America/Cuiaba",
    name: "(UTC-04:00) Cuiabá, Várzea Grande, Rondonópolis, Sinop, Barra do Garças",
    utc: "-04:00",
  },
  {
    label: "America/Curacao (UTC-04:00)",
    tzCode: "America/Curacao",
    name: "(UTC-04:00) Willemstad, Sint Michiel Liber",
    utc: "-04:00",
  },
  {
    label: "America/Dominica (UTC-04:00)",
    tzCode: "America/Dominica",
    name: "(UTC-04:00) Roseau, Portsmouth, Berekua, Saint Joseph, Wesley",
    utc: "-04:00",
  },
  {
    label: "America/Glace_Bay (UTC-04:00)",
    tzCode: "America/Glace_Bay",
    name: "(UTC-04:00) Sydney, Glace Bay, Sydney Mines",
    utc: "-04:00",
  },
  {
    label: "America/Goose_Bay (UTC-04:00)",
    tzCode: "America/Goose_Bay",
    name: "(UTC-04:00) Labrador City, Happy Valley-Goose Bay",
    utc: "-04:00",
  },
  {
    label: "America/Grenada (UTC-04:00)",
    tzCode: "America/Grenada",
    name: "(UTC-04:00) Saint George's, Gouyave, Grenville, Victoria, Saint David’s",
    utc: "-04:00",
  },
  {
    label: "America/Guadeloupe (UTC-04:00)",
    tzCode: "America/Guadeloupe",
    name: "(UTC-04:00) Les Abymes, Baie-Mahault, Le Gosier, Petit-Bourg, Sainte-Anne",
    utc: "-04:00",
  },
  {
    label: "America/Guyana (UTC-04:00)",
    tzCode: "America/Guyana",
    name: "(UTC-04:00) Georgetown, Linden, New Amsterdam, Anna Regina, Bartica",
    utc: "-04:00",
  },
  {
    label: "America/Halifax (UTC-04:00)",
    tzCode: "America/Halifax",
    name: "(UTC-04:00) Halifax, Dartmouth, Charlottetown, Lower Sackville, Truro",
    utc: "-04:00",
  },
  {
    label: "America/Kralendijk (UTC-04:00)",
    tzCode: "America/Kralendijk",
    name: "(UTC-04:00) Kralendijk, Oranjestad, The Bottom",
    utc: "-04:00",
  },
  {
    label: "America/La_Paz (UTC-04:00)",
    tzCode: "America/La_Paz",
    name: "(UTC-04:00) Santa Cruz de la Sierra, Cochabamba, La Paz, Sucre, Oruro",
    utc: "-04:00",
  },
  {
    label: "America/Lower_Princes (UTC-04:00)",
    tzCode: "America/Lower_Princes",
    name: "(UTC-04:00) Cul de Sac, Lower Prince’s Quarter, Koolbaai, Philipsburg",
    utc: "-04:00",
  },
  {
    label: "America/Manaus (UTC-04:00)",
    tzCode: "America/Manaus",
    name: "(UTC-04:00) Manaus, Itacoatiara, Parintins, Manacapuru, Coari",
    utc: "-04:00",
  },
  {
    label: "America/Marigot (UTC-04:00)",
    tzCode: "America/Marigot",
    name: "(UTC-04:00) Marigot",
    utc: "-04:00",
  },
  {
    label: "America/Martinique (UTC-04:00)",
    tzCode: "America/Martinique",
    name: "(UTC-04:00) Fort-de-France, Le Lamentin, Le Robert, Sainte-Marie, Le François",
    utc: "-04:00",
  },
  {
    label: "America/Moncton (UTC-04:00)",
    tzCode: "America/Moncton",
    name: "(UTC-04:00) Moncton, Saint John, Fredericton, Dieppe, Miramichi",
    utc: "-04:00",
  },
  {
    label: "America/Montserrat (UTC-04:00)",
    tzCode: "America/Montserrat",
    name: "(UTC-04:00) Brades, Saint Peters, Plymouth",
    utc: "-04:00",
  },
  {
    label: "America/Porto_Velho (UTC-04:00)",
    tzCode: "America/Porto_Velho",
    name: "(UTC-04:00) Porto Velho, Ji Paraná, Vilhena, Ariquemes, Cacoal",
    utc: "-04:00",
  },
  {
    label: "America/Port_of_Spain (UTC-04:00)",
    tzCode: "America/Port_of_Spain",
    name: "(UTC-04:00) Chaguanas, Mon Repos, San Fernando, Port of Spain, Rio Claro",
    utc: "-04:00",
  },
  {
    label: "America/Puerto_Rico (UTC-04:00)",
    tzCode: "America/Puerto_Rico",
    name: "(UTC-04:00) San Juan, Bayamón, Carolina, Ponce, Caguas",
    utc: "-04:00",
  },
  {
    label: "America/Santiago (UTC-04:00)",
    tzCode: "America/Santiago",
    name: "(UTC-04:00) Santiago, Puente Alto, Antofagasta, Viña del Mar, Valparaíso",
    utc: "-04:00",
  },
  {
    label: "America/Santo_Domingo (UTC-04:00)",
    tzCode: "America/Santo_Domingo",
    name: "(UTC-04:00) Santo Domingo, Santiago de los Caballeros, Santo Domingo Oeste, Santo Domingo Este, San Pedro de Macorís",
    utc: "-04:00",
  },
  {
    label: "America/St_Barthelemy (UTC-04:00)",
    tzCode: "America/St_Barthelemy",
    name: "(UTC-04:00) Gustavia",
    utc: "-04:00",
  },
  {
    label: "America/St_Kitts (UTC-04:00)",
    tzCode: "America/St_Kitts",
    name: "(UTC-04:00) Basseterre, Fig Tree, Market Shop, Saint Paul’s, Middle Island",
    utc: "-04:00",
  },
  {
    label: "America/St_Lucia (UTC-04:00)",
    tzCode: "America/St_Lucia",
    name: "(UTC-04:00) Castries, Bisee, Vieux Fort, Micoud, Soufrière",
    utc: "-04:00",
  },
  {
    label: "America/St_Thomas (UTC-04:00)",
    tzCode: "America/St_Thomas",
    name: "(UTC-04:00) Saint Croix, Charlotte Amalie, Cruz Bay",
    utc: "-04:00",
  },
  {
    label: "America/St_Vincent (UTC-04:00)",
    tzCode: "America/St_Vincent",
    name: "(UTC-04:00) Kingstown, Kingstown Park, Georgetown, Barrouallie, Port Elizabeth",
    utc: "-04:00",
  },
  {
    label: "America/Thule (UTC-04:00)",
    tzCode: "America/Thule",
    name: "(UTC-04:00) Thule",
    utc: "-04:00",
  },
  {
    label: "America/Tortola (UTC-04:00)",
    tzCode: "America/Tortola",
    name: "(UTC-04:00) Road Town",
    utc: "-04:00",
  },
  {
    label: "Atlantic/Bermuda (UTC-04:00)",
    tzCode: "Atlantic/Bermuda",
    name: "(UTC-04:00) Hamilton",
    utc: "-04:00",
  },
  {
    label: "America/St_Johns (UTC-03:30)",
    tzCode: "America/St_Johns",
    name: "(UTC-03:30) St. John's, Mount Pearl, Corner Brook, Conception Bay South, Bay Roberts",
    utc: "-03:30",
  },
  {
    label: "America/Araguaina (UTC-03:00)",
    tzCode: "America/Araguaina",
    name: "(UTC-03:00) Palmas, Araguaína, Gurupi, Miracema do Tocantins, Porto Franco",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Buenos_Aires (UTC-03:00)",
    tzCode: "America/Argentina/Buenos_Aires",
    name: "(UTC-03:00) Buenos Aires, La Plata, Mar del Plata, Morón, Bahía Blanca",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Catamarca (UTC-03:00)",
    tzCode: "America/Argentina/Catamarca",
    name: "(UTC-03:00) San Fernando del Valle de Catamarca, Trelew, Puerto Madryn, Esquel, Rawson",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Cordoba (UTC-03:00)",
    tzCode: "America/Argentina/Cordoba",
    name: "(UTC-03:00) Córdoba, Rosario, Santa Fe, Resistencia, Santiago del Estero",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Jujuy (UTC-03:00)",
    tzCode: "America/Argentina/Jujuy",
    name: "(UTC-03:00) San Salvador de Jujuy, San Pedro de Jujuy, Libertador General San Martín, Palpalá, La Quiaca",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/La_Rioja (UTC-03:00)",
    tzCode: "America/Argentina/La_Rioja",
    name: "(UTC-03:00) La Rioja, Chilecito, Arauco, Chamical",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Mendoza (UTC-03:00)",
    tzCode: "America/Argentina/Mendoza",
    name: "(UTC-03:00) Mendoza, San Rafael, San Martín",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Rio_Gallegos (UTC-03:00)",
    tzCode: "America/Argentina/Rio_Gallegos",
    name: "(UTC-03:00) Comodoro Rivadavia, Río Gallegos, Caleta Olivia, Pico Truncado, Puerto Deseado",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Salta (UTC-03:00)",
    tzCode: "America/Argentina/Salta",
    name: "(UTC-03:00) Salta, Neuquén, Santa Rosa, San Carlos de Bariloche, Cipolletti",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/San_Juan (UTC-03:00)",
    tzCode: "America/Argentina/San_Juan",
    name: "(UTC-03:00) San Juan, Chimbas, Santa Lucía, Pocito, Caucete",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/San_Luis (UTC-03:00)",
    tzCode: "America/Argentina/San_Luis",
    name: "(UTC-03:00) San Luis, Villa Mercedes, La Punta, Merlo, Justo Daract",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Tucuman (UTC-03:00)",
    tzCode: "America/Argentina/Tucuman",
    name: "(UTC-03:00) San Miguel de Tucumán, Yerba Buena, Tafí Viejo, Alderetes, Aguilares",
    utc: "-03:00",
  },
  {
    label: "America/Argentina/Ushuaia (UTC-03:00)",
    tzCode: "America/Argentina/Ushuaia",
    name: "(UTC-03:00) Ushuaia, Río Grande",
    utc: "-03:00",
  },
  {
    label: "America/Bahia (UTC-03:00)",
    tzCode: "America/Bahia",
    name: "(UTC-03:00) Salvador, Feira de Santana, Vitória da Conquista, Itabuna, Camaçari",
    utc: "-03:00",
  },
  {
    label: "America/Belem (UTC-03:00)",
    tzCode: "America/Belem",
    name: "(UTC-03:00) Belém, Ananindeua, Macapá, Parauapebas, Marabá",
    utc: "-03:00",
  },
  {
    label: "America/Cayenne (UTC-03:00)",
    tzCode: "America/Cayenne",
    name: "(UTC-03:00) Cayenne, Matoury, Saint-Laurent-du-Maroni, Kourou, Rémire-Montjoly",
    utc: "-03:00",
  },
  {
    label: "America/Fortaleza (UTC-03:00)",
    tzCode: "America/Fortaleza",
    name: "(UTC-03:00) Fortaleza, São Luís, Natal, Teresina, João Pessoa",
    utc: "-03:00",
  },
  {
    label: "America/Godthab (UTC-03:00)",
    tzCode: "America/Godthab",
    name: "(UTC-03:00) Nuuk, Sisimiut, Ilulissat, Qaqortoq, Aasiaat",
    utc: "-03:00",
  },
  {
    label: "America/Maceio (UTC-03:00)",
    tzCode: "America/Maceio",
    name: "(UTC-03:00) Maceió, Aracaju, Arapiraca, Nossa Senhora do Socorro, São Cristóvão",
    utc: "-03:00",
  },
  {
    label: "America/Miquelon (UTC-03:00)",
    tzCode: "America/Miquelon",
    name: "(UTC-03:00) Saint-Pierre, Miquelon",
    utc: "-03:00",
  },
  {
    label: "America/Montevideo (UTC-03:00)",
    tzCode: "America/Montevideo",
    name: "(UTC-03:00) Montevideo, Salto, Paysandú, Las Piedras, Rivera",
    utc: "-03:00",
  },
  {
    label: "America/Paramaribo (UTC-03:00)",
    tzCode: "America/Paramaribo",
    name: "(UTC-03:00) Paramaribo, Lelydorp, Brokopondo, Nieuw Nickerie, Moengo",
    utc: "-03:00",
  },
  {
    label: "America/Punta_Arenas (UTC-03:00)",
    tzCode: "America/Punta_Arenas",
    name: "(UTC-03:00) Punta Arenas, Puerto Natales",
    utc: "-03:00",
  },
  {
    label: "America/Recife (UTC-03:00)",
    tzCode: "America/Recife",
    name: "(UTC-03:00) Recife, Jaboatão, Jaboatão dos Guararapes, Olinda, Paulista",
    utc: "-03:00",
  },
  {
    label: "America/Santarem (UTC-03:00)",
    tzCode: "America/Santarem",
    name: "(UTC-03:00) Santarém, Altamira, Itaituba, Oriximiná, Alenquer",
    utc: "-03:00",
  },
  {
    label: "America/Sao_Paulo (UTC-03:00)",
    tzCode: "America/Sao_Paulo",
    name: "(UTC-03:00) São Paulo, Rio de Janeiro, Belo Horizonte, Brasília, Curitiba",
    utc: "-03:00",
  },
  {
    label: "Antarctica/Palmer (UTC-03:00)",
    tzCode: "Antarctica/Palmer",
    name: "(UTC-03:00) Palmer",
    utc: "-03:00",
  },
  {
    label: "Antarctica/Rothera (UTC-03:00)",
    tzCode: "Antarctica/Rothera",
    name: "(UTC-03:00) Rothera",
    utc: "-03:00",
  },
  {
    label: "Atlantic/Stanley (UTC-03:00)",
    tzCode: "Atlantic/Stanley",
    name: "(UTC-03:00) Stanley",
    utc: "-03:00",
  },
  {
    label: "America/Noronha (UTC-02:00)",
    tzCode: "America/Noronha",
    name: "(UTC-02:00) Itamaracá",
    utc: "-02:00",
  },
  {
    label: "Atlantic/South_Georgia (UTC-02:00)",
    tzCode: "Atlantic/South_Georgia",
    name: "(UTC-02:00) Grytviken",
    utc: "-02:00",
  },
  {
    label: "America/Scoresbysund (UTC-01:00)",
    tzCode: "America/Scoresbysund",
    name: "(UTC-01:00) Scoresbysund",
    utc: "-01:00",
  },
  {
    label: "Atlantic/Azores (UTC-01:00)",
    tzCode: "Atlantic/Azores",
    name: "(UTC-01:00) Ponta Delgada, Lagoa, Angra do Heroísmo, Rosto de Cão, Rabo de Peixe",
    utc: "-01:00",
  },
  {
    label: "Atlantic/Cape_Verde (UTC-01:00)",
    tzCode: "Atlantic/Cape_Verde",
    name: "(UTC-01:00) Praia, Mindelo, Santa Maria, Cova Figueira, Santa Cruz",
    utc: "-01:00",
  },
  {
    label: "Africa/Abidjan (UTC+00:00)",
    tzCode: "Africa/Abidjan",
    name: "(UTC+00:00) Abidjan, Abobo, Bouaké, Daloa, San-Pédro",
    utc: "+00:00",
  },
  {
    label: "Africa/Accra (UTC+00:00)",
    tzCode: "Africa/Accra",
    name: "(UTC+00:00) Accra, Kumasi, Tamale, Takoradi, Atsiaman",
    utc: "+00:00",
  },
  {
    label: "Africa/Bamako (UTC+00:00)",
    tzCode: "Africa/Bamako",
    name: "(UTC+00:00) Bamako, Sikasso, Mopti, Koutiala, Ségou",
    utc: "+00:00",
  },
  {
    label: "Africa/Banjul (UTC+00:00)",
    tzCode: "Africa/Banjul",
    name: "(UTC+00:00) Serekunda, Brikama, Bakau, Banjul, Farafenni",
    utc: "+00:00",
  },
  {
    label: "Africa/Bissau (UTC+00:00)",
    tzCode: "Africa/Bissau",
    name: "(UTC+00:00) Bissau, Bafatá, Gabú, Bissorã, Bolama",
    utc: "+00:00",
  },
  {
    label: "Africa/Casablanca (UTC+00:00)",
    tzCode: "Africa/Casablanca",
    name: "(UTC+00:00) Casablanca, Rabat, Fès, Sale, Marrakesh",
    utc: "+00:00",
  },
  {
    label: "Africa/Conakry (UTC+00:00)",
    tzCode: "Africa/Conakry",
    name: "(UTC+00:00) Camayenne, Conakry, Nzérékoré, Kindia, Kankan",
    utc: "+00:00",
  },
  {
    label: "Africa/Dakar (UTC+00:00)",
    tzCode: "Africa/Dakar",
    name: "(UTC+00:00) Dakar, Pikine, Touba, Thiès, Thiès Nones",
    utc: "+00:00",
  },
  {
    label: "Africa/El_Aaiun (UTC+00:00)",
    tzCode: "Africa/El_Aaiun",
    name: "(UTC+00:00) Laayoune, Dakhla, Laayoune Plage",
    utc: "+00:00",
  },
  {
    label: "Africa/Freetown (UTC+00:00)",
    tzCode: "Africa/Freetown",
    name: "(UTC+00:00) Freetown, Bo, Kenema, Koidu, Makeni",
    utc: "+00:00",
  },
  {
    label: "Africa/Lome (UTC+00:00)",
    tzCode: "Africa/Lome",
    name: "(UTC+00:00) Lomé, Sokodé, Kara, Atakpamé, Kpalimé",
    utc: "+00:00",
  },
  {
    label: "Africa/Monrovia (UTC+00:00)",
    tzCode: "Africa/Monrovia",
    name: "(UTC+00:00) Monrovia, Gbarnga, Kakata, Bensonville, Harper",
    utc: "+00:00",
  },
  {
    label: "Africa/Nouakchott (UTC+00:00)",
    tzCode: "Africa/Nouakchott",
    name: "(UTC+00:00) Nouakchott, Nouadhibou, Néma, Kaédi, Rosso",
    utc: "+00:00",
  },
  {
    label: "Africa/Ouagadougou (UTC+00:00)",
    tzCode: "Africa/Ouagadougou",
    name: "(UTC+00:00) Ouagadougou, Bobo-Dioulasso, Koudougou, Ouahigouya, Banfora",
    utc: "+00:00",
  },
  {
    label: "Africa/Sao_Tome (UTC+00:00)",
    tzCode: "Africa/Sao_Tome",
    name: "(UTC+00:00) São Tomé, Santo António",
    utc: "+00:00",
  },
  {
    label: "America/Danmarkshavn (UTC+00:00)",
    tzCode: "America/Danmarkshavn",
    name: "(UTC+00:00) Danmarkshavn",
    utc: "+00:00",
  },
  {
    label: "Antarctica/Troll (UTC+00:00)",
    tzCode: "Antarctica/Troll",
    name: "(UTC+00:00) Troll",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Canary (UTC+00:00)",
    tzCode: "Atlantic/Canary",
    name: "(UTC+00:00) Las Palmas de Gran Canaria, Santa Cruz de Tenerife, La Laguna, Telde, Arona",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Faroe (UTC+00:00)",
    tzCode: "Atlantic/Faroe",
    name: "(UTC+00:00) Tórshavn, Klaksvík, Fuglafjørður, Tvøroyri, Miðvágur",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Madeira (UTC+00:00)",
    tzCode: "Atlantic/Madeira",
    name: "(UTC+00:00) Funchal, Câmara de Lobos, São Martinho, Caniço, Machico",
    utc: "+00:00",
  },
  {
    label: "Atlantic/Reykjavik (UTC+00:00)",
    tzCode: "Atlantic/Reykjavik",
    name: "(UTC+00:00) Reykjavík, Kópavogur, Hafnarfjörður, Akureyri, Garðabær",
    utc: "+00:00",
  },
  {
    label: "Atlantic/St_Helena (UTC+00:00)",
    tzCode: "Atlantic/St_Helena",
    name: "(UTC+00:00) Jamestown, Georgetown, Edinburgh of the Seven Seas",
    utc: "+00:00",
  },
  {
    label: "Europe/Dublin (UTC+00:00)",
    tzCode: "Europe/Dublin",
    name: "(UTC+00:00) Dublin, Cork, Luimneach, Gaillimh, Tallaght",
    utc: "+00:00",
  },
  {
    label: "Europe/Guernsey (UTC+00:00)",
    tzCode: "Europe/Guernsey",
    name: "(UTC+00:00) Saint Peter Port, St Martin, Saint Sampson, St Anne, Saint Saviour",
    utc: "+00:00",
  },
  {
    label: "Europe/Isle_of_Man (UTC+00:00)",
    tzCode: "Europe/Isle_of_Man",
    name: "(UTC+00:00) Douglas, Ramsey, Peel, Port Erin, Castletown",
    utc: "+00:00",
  },
  {
    label: "Europe/Jersey (UTC+00:00)",
    tzCode: "Europe/Jersey",
    name: "(UTC+00:00) Saint Helier, Le Hocq",
    utc: "+00:00",
  },
  {
    label: "Europe/Lisbon (UTC+00:00)",
    tzCode: "Europe/Lisbon",
    name: "(UTC+00:00) Lisbon, Porto, Amadora, Braga, Setúbal",
    utc: "+00:00",
  },
  {
    label: "Europe/London (UTC+00:00)",
    tzCode: "Europe/London",
    name: "(UTC+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
    utc: "+00:00",
  },
  {
    label: "Africa/Algiers (UTC+01:00)",
    tzCode: "Africa/Algiers",
    name: "(UTC+01:00) Algiers, Boumerdas, Oran, Tébessa, Constantine",
    utc: "+01:00",
  },
  {
    label: "Africa/Bangui (UTC+01:00)",
    tzCode: "Africa/Bangui",
    name: "(UTC+01:00) Bangui, Bimbo, Mbaïki, Berbérati, Kaga Bandoro",
    utc: "+01:00",
  },
  {
    label: "Africa/Brazzaville (UTC+01:00)",
    tzCode: "Africa/Brazzaville",
    name: "(UTC+01:00) Brazzaville, Pointe-Noire, Dolisie, Kayes, Owando",
    utc: "+01:00",
  },
  {
    label: "Africa/Ceuta (UTC+01:00)",
    tzCode: "Africa/Ceuta",
    name: "(UTC+01:00) Ceuta, Melilla",
    utc: "+01:00",
  },
  {
    label: "Africa/Douala (UTC+01:00)",
    tzCode: "Africa/Douala",
    name: "(UTC+01:00) Douala, Yaoundé, Garoua, Kousséri, Bamenda",
    utc: "+01:00",
  },
  {
    label: "Africa/Kinshasa (UTC+01:00)",
    tzCode: "Africa/Kinshasa",
    name: "(UTC+01:00) Kinshasa, Masina, Kikwit, Mbandaka, Matadi",
    utc: "+01:00",
  },
  {
    label: "Africa/Lagos (UTC+01:00)",
    tzCode: "Africa/Lagos",
    name: "(UTC+01:00) Lagos, Kano, Ibadan, Kaduna, Port Harcourt",
    utc: "+01:00",
  },
  {
    label: "Africa/Libreville (UTC+01:00)",
    tzCode: "Africa/Libreville",
    name: "(UTC+01:00) Libreville, Port-Gentil, Franceville, Oyem, Moanda",
    utc: "+01:00",
  },
  {
    label: "Africa/Luanda (UTC+01:00)",
    tzCode: "Africa/Luanda",
    name: "(UTC+01:00) Luanda, N’dalatando, Huambo, Lobito, Benguela",
    utc: "+01:00",
  },
  {
    label: "Africa/Malabo (UTC+01:00)",
    tzCode: "Africa/Malabo",
    name: "(UTC+01:00) Bata, Malabo, Ebebiyin, Aconibe, Añisoc",
    utc: "+01:00",
  },
  {
    label: "Africa/Ndjamena (UTC+01:00)",
    tzCode: "Africa/Ndjamena",
    name: "(UTC+01:00) N'Djamena, Moundou, Sarh, Abéché, Kelo",
    utc: "+01:00",
  },
  {
    label: "Africa/Niamey (UTC+01:00)",
    tzCode: "Africa/Niamey",
    name: "(UTC+01:00) Niamey, Zinder, Maradi, Agadez, Alaghsas",
    utc: "+01:00",
  },
  {
    label: "Africa/Porto-Novo (UTC+01:00)",
    tzCode: "Africa/Porto-Novo",
    name: "(UTC+01:00) Cotonou, Abomey-Calavi, Djougou, Porto-Novo, Parakou",
    utc: "+01:00",
  },
  {
    label: "Africa/Tunis (UTC+01:00)",
    tzCode: "Africa/Tunis",
    name: "(UTC+01:00) Tunis, Sfax, Sousse, Kairouan, Bizerte",
    utc: "+01:00",
  },
  {
    label: "Africa/Windhoek (UTC+01:00)",
    tzCode: "Africa/Windhoek",
    name: "(UTC+01:00) Windhoek, Rundu, Walvis Bay, Oshakati, Swakopmund",
    utc: "+01:00",
  },
  {
    label: "Arctic/Longyearbyen (UTC+01:00)",
    tzCode: "Arctic/Longyearbyen",
    name: "(UTC+01:00) Longyearbyen, Olonkinbyen",
    utc: "+01:00",
  },
  {
    label: "Europe/Amsterdam (UTC+01:00)",
    tzCode: "Europe/Amsterdam",
    name: "(UTC+01:00) Amsterdam, Rotterdam, The Hague, Utrecht, Eindhoven",
    utc: "+01:00",
  },
  {
    label: "Europe/Andorra (UTC+01:00)",
    tzCode: "Europe/Andorra",
    name: "(UTC+01:00) Andorra la Vella, les Escaldes, Encamp, Sant Julià de Lòria, la Massana",
    utc: "+01:00",
  },
  {
    label: "Europe/Belgrade (UTC+01:00)",
    tzCode: "Europe/Belgrade",
    name: "(UTC+01:00) Belgrade, Pristina, Niš, Novi Sad, Prizren",
    utc: "+01:00",
  },
  {
    label: "Europe/Berlin (UTC+01:00)",
    tzCode: "Europe/Berlin",
    name: "(UTC+01:00) Berlin, Hamburg, Munich, Köln, Frankfurt am Main",
    utc: "+01:00",
  },
  {
    label: "Europe/Bratislava (UTC+01:00)",
    tzCode: "Europe/Bratislava",
    name: "(UTC+01:00) Bratislava, Košice, Prešov, Nitra, Žilina",
    utc: "+01:00",
  },
  {
    label: "Europe/Brussels (UTC+01:00)",
    tzCode: "Europe/Brussels",
    name: "(UTC+01:00) Brussels, Antwerpen, Gent, Charleroi, Liège",
    utc: "+01:00",
  },
  {
    label: "Europe/Budapest (UTC+01:00)",
    tzCode: "Europe/Budapest",
    name: "(UTC+01:00) Budapest, Debrecen, Miskolc, Szeged, Pécs",
    utc: "+01:00",
  },
  {
    label: "Europe/Copenhagen (UTC+01:00)",
    tzCode: "Europe/Copenhagen",
    name: "(UTC+01:00) Copenhagen, Århus, Odense, Aalborg, Frederiksberg",
    utc: "+01:00",
  },
  {
    label: "Europe/Gibraltar (UTC+01:00)",
    tzCode: "Europe/Gibraltar",
    name: "(UTC+01:00) Gibraltar",
    utc: "+01:00",
  },
  {
    label: "Europe/Ljubljana (UTC+01:00)",
    tzCode: "Europe/Ljubljana",
    name: "(UTC+01:00) Ljubljana, Maribor, Celje, Kranj, Velenje",
    utc: "+01:00",
  },
  {
    label: "Europe/Luxembourg (UTC+01:00)",
    tzCode: "Europe/Luxembourg",
    name: "(UTC+01:00) Luxembourg, Esch-sur-Alzette, Dudelange, Schifflange, Bettembourg",
    utc: "+01:00",
  },
  {
    label: "Europe/Madrid (UTC+01:00)",
    tzCode: "Europe/Madrid",
    name: "(UTC+01:00) Madrid, Barcelona, Valencia, Sevilla, Zaragoza",
    utc: "+01:00",
  },
  {
    label: "Europe/Malta (UTC+01:00)",
    tzCode: "Europe/Malta",
    name: "(UTC+01:00) Birkirkara, Qormi, Mosta, Żabbar, San Pawl il-Baħar",
    utc: "+01:00",
  },
  {
    label: "Europe/Monaco (UTC+01:00)",
    tzCode: "Europe/Monaco",
    name: "(UTC+01:00) Monaco, Monte-Carlo, La Condamine",
    utc: "+01:00",
  },
  {
    label: "Europe/Oslo (UTC+01:00)",
    tzCode: "Europe/Oslo",
    name: "(UTC+01:00) Oslo, Bergen, Trondheim, Stavanger, Drammen",
    utc: "+01:00",
  },
  {
    label: "Europe/Paris (UTC+01:00)",
    tzCode: "Europe/Paris",
    name: "(UTC+01:00) Paris, Marseille, Lyon, Toulouse, Nice",
    utc: "+01:00",
  },
  {
    label: "Europe/Podgorica (UTC+01:00)",
    tzCode: "Europe/Podgorica",
    name: "(UTC+01:00) Podgorica, Nikšić, Herceg Novi, Pljevlja, Budva",
    utc: "+01:00",
  },
  {
    label: "Europe/Prague (UTC+01:00)",
    tzCode: "Europe/Prague",
    name: "(UTC+01:00) Prague, Brno, Ostrava, Pilsen, Olomouc",
    utc: "+01:00",
  },
  {
    label: "Europe/Rome (UTC+01:00)",
    tzCode: "Europe/Rome",
    name: "(UTC+01:00) Rome, Milan, Naples, Turin, Palermo",
    utc: "+01:00",
  },
  {
    label: "Europe/San_Marino (UTC+01:00)",
    tzCode: "Europe/San_Marino",
    name: "(UTC+01:00) Serravalle, Borgo Maggiore, San Marino, Domagnano, Fiorentino",
    utc: "+01:00",
  },
  {
    label: "Europe/Sarajevo (UTC+01:00)",
    tzCode: "Europe/Sarajevo",
    name: "(UTC+01:00) Sarajevo, Banja Luka, Zenica, Tuzla, Mostar",
    utc: "+01:00",
  },
  {
    label: "Europe/Skopje (UTC+01:00)",
    tzCode: "Europe/Skopje",
    name: "(UTC+01:00) Skopje, Bitola, Kumanovo, Prilep, Tetovo",
    utc: "+01:00",
  },
  {
    label: "Europe/Stockholm (UTC+01:00)",
    tzCode: "Europe/Stockholm",
    name: "(UTC+01:00) Stockholm, Göteborg, Malmö, Uppsala, Sollentuna",
    utc: "+01:00",
  },
  {
    label: "Europe/Tirane (UTC+01:00)",
    tzCode: "Europe/Tirane",
    name: "(UTC+01:00) Tirana, Durrës, Elbasan, Vlorë, Shkodër",
    utc: "+01:00",
  },
  {
    label: "Europe/Vaduz (UTC+01:00)",
    tzCode: "Europe/Vaduz",
    name: "(UTC+01:00) Schaan, Vaduz, Triesen, Balzers, Eschen",
    utc: "+01:00",
  },
  {
    label: "Europe/Vatican (UTC+01:00)",
    tzCode: "Europe/Vatican",
    name: "(UTC+01:00) Vatican City",
    utc: "+01:00",
  },
  {
    label: "Europe/Vienna (UTC+01:00)",
    tzCode: "Europe/Vienna",
    name: "(UTC+01:00) Vienna, Graz, Linz, Favoriten, Donaustadt",
    utc: "+01:00",
  },
  {
    label: "Europe/Warsaw (UTC+01:00)",
    tzCode: "Europe/Warsaw",
    name: "(UTC+01:00) Warsaw, Łódź, Kraków, Wrocław, Poznań",
    utc: "+01:00",
  },
  {
    label: "Europe/Zagreb (UTC+01:00)",
    tzCode: "Europe/Zagreb",
    name: "(UTC+01:00) Zagreb, Split, Rijeka, Osijek, Zadar",
    utc: "+01:00",
  },
  {
    label: "Europe/Zurich (UTC+01:00)",
    tzCode: "Europe/Zurich",
    name: "(UTC+01:00) Zürich, Genève, Basel, Lausanne, Bern",
    utc: "+01:00",
  },
  {
    label: "Africa/Blantyre (UTC+02:00)",
    tzCode: "Africa/Blantyre",
    name: "(UTC+02:00) Lilongwe, Blantyre, Mzuzu, Zomba, Kasungu",
    utc: "+02:00",
  },
  {
    label: "Africa/Bujumbura (UTC+02:00)",
    tzCode: "Africa/Bujumbura",
    name: "(UTC+02:00) Bujumbura, Muyinga, Gitega, Ruyigi, Ngozi",
    utc: "+02:00",
  },
  {
    label: "Africa/Cairo (UTC+02:00)",
    tzCode: "Africa/Cairo",
    name: "(UTC+02:00) Cairo, Alexandria, Giza, Port Said, Suez",
    utc: "+02:00",
  },
  {
    label: "Africa/Gaborone (UTC+02:00)",
    tzCode: "Africa/Gaborone",
    name: "(UTC+02:00) Gaborone, Francistown, Molepolole, Selebi-Phikwe, Maun",
    utc: "+02:00",
  },
  {
    label: "Africa/Harare (UTC+02:00)",
    tzCode: "Africa/Harare",
    name: "(UTC+02:00) Harare, Bulawayo, Chitungwiza, Mutare, Gweru",
    utc: "+02:00",
  },
  {
    label: "Africa/Johannesburg (UTC+02:00)",
    tzCode: "Africa/Johannesburg",
    name: "(UTC+02:00) Cape Town, Durban, Johannesburg, Soweto, Pretoria",
    utc: "+02:00",
  },
  {
    label: "Africa/Juba (UTC+02:00)",
    tzCode: "Africa/Juba",
    name: "(UTC+02:00) Juba, Winejok, Malakal, Wau, Kuacjok",
    utc: "+02:00",
  },
  {
    label: "Africa/Khartoum (UTC+02:00)",
    tzCode: "Africa/Khartoum",
    name: "(UTC+02:00) Khartoum, Omdurman, Nyala, Port Sudan, Kassala",
    utc: "+02:00",
  },
  {
    label: "Africa/Kigali (UTC+02:00)",
    tzCode: "Africa/Kigali",
    name: "(UTC+02:00) Kigali, Butare, Gitarama, Musanze, Gisenyi",
    utc: "+02:00",
  },
  {
    label: "Africa/Lubumbashi (UTC+02:00)",
    tzCode: "Africa/Lubumbashi",
    name: "(UTC+02:00) Lubumbashi, Mbuji-Mayi, Kisangani, Kananga, Likasi",
    utc: "+02:00",
  },
  {
    label: "Africa/Lusaka (UTC+02:00)",
    tzCode: "Africa/Lusaka",
    name: "(UTC+02:00) Lusaka, Kitwe, Ndola, Kabwe, Chingola",
    utc: "+02:00",
  },
  {
    label: "Africa/Maputo (UTC+02:00)",
    tzCode: "Africa/Maputo",
    name: "(UTC+02:00) Maputo, Matola, Beira, Nampula, Chimoio",
    utc: "+02:00",
  },
  {
    label: "Africa/Maseru (UTC+02:00)",
    tzCode: "Africa/Maseru",
    name: "(UTC+02:00) Maseru, Mafeteng, Leribe, Maputsoe, Mohale’s Hoek",
    utc: "+02:00",
  },
  {
    label: "Africa/Mbabane (UTC+02:00)",
    tzCode: "Africa/Mbabane",
    name: "(UTC+02:00) Manzini, Mbabane, Big Bend, Malkerns, Nhlangano",
    utc: "+02:00",
  },
  {
    label: "Africa/Tripoli (UTC+02:00)",
    tzCode: "Africa/Tripoli",
    name: "(UTC+02:00) Tripoli, Benghazi, Mişrātah, Tarhuna, Al Khums",
    utc: "+02:00",
  },
  {
    label: "Asia/Amman (UTC+02:00)",
    tzCode: "Asia/Amman",
    name: "(UTC+02:00) Amman, Zarqa, Irbid, Russeifa, Wādī as Sīr",
    utc: "+02:00",
  },
  {
    label: "Asia/Beirut (UTC+02:00)",
    tzCode: "Asia/Beirut",
    name: "(UTC+02:00) Beirut, Ra’s Bayrūt, Tripoli, Sidon, Tyre",
    utc: "+02:00",
  },
  {
    label: "Asia/Damascus (UTC+02:00)",
    tzCode: "Asia/Damascus",
    name: "(UTC+02:00) Aleppo, Damascus, Homs, Ḩamāh, Latakia",
    utc: "+02:00",
  },
  {
    label: "Asia/Famagusta (UTC+02:00)",
    tzCode: "Asia/Famagusta",
    name: "(UTC+02:00) Famagusta, Kyrenia, Protaras, Paralímni, Lápithos",
    utc: "+02:00",
  },
  {
    label: "Asia/Gaza (UTC+02:00)",
    tzCode: "Asia/Gaza",
    name: "(UTC+02:00) Gaza, Khān Yūnis, Jabālyā, Rafaḩ, Dayr al Balaḩ",
    utc: "+02:00",
  },
  {
    label: "Asia/Hebron (UTC+02:00)",
    tzCode: "Asia/Hebron",
    name: "(UTC+02:00) East Jerusalem, Hebron, Nablus, Battir, Ţūlkarm",
    utc: "+02:00",
  },
  {
    label: "Asia/Jerusalem (UTC+02:00)",
    tzCode: "Asia/Jerusalem",
    name: "(UTC+02:00) Jerusalem, Tel Aviv, West Jerusalem, Haifa, Ashdod",
    utc: "+02:00",
  },
  {
    label: "Asia/Nicosia (UTC+02:00)",
    tzCode: "Asia/Nicosia",
    name: "(UTC+02:00) Nicosia, Limassol, Larnaca, Stróvolos, Paphos",
    utc: "+02:00",
  },
  {
    label: "Europe/Athens (UTC+02:00)",
    tzCode: "Europe/Athens",
    name: "(UTC+02:00) Athens, Thessaloníki, Pátra, Piraeus, Lárisa",
    utc: "+02:00",
  },
  {
    label: "Europe/Bucharest (UTC+02:00)",
    tzCode: "Europe/Bucharest",
    name: "(UTC+02:00) Bucharest, Sector 3, Sector 6, Sector 2, Iaşi",
    utc: "+02:00",
  },
  {
    label: "Europe/Chisinau (UTC+02:00)",
    tzCode: "Europe/Chisinau",
    name: "(UTC+02:00) Chisinau, Tiraspol, Bălţi, Bender, Rîbniţa",
    utc: "+02:00",
  },
  {
    label: "Europe/Helsinki (UTC+02:00)",
    tzCode: "Europe/Helsinki",
    name: "(UTC+02:00) Helsinki, Espoo, Tampere, Vantaa, Turku",
    utc: "+02:00",
  },
  {
    label: "Europe/Kaliningrad (UTC+02:00)",
    tzCode: "Europe/Kaliningrad",
    name: "(UTC+02:00) Kaliningrad, Chernyakhovsk, Sovetsk, Baltiysk, Gusev",
    utc: "+02:00",
  },
  {
    label: "Europe/Kiev (UTC+02:00)",
    tzCode: "Europe/Kiev",
    name: "(UTC+02:00) Kyiv, Kharkiv, Donetsk, Odessa, Dnipro",
    utc: "+02:00",
  },
  {
    label: "Europe/Mariehamn (UTC+02:00)",
    tzCode: "Europe/Mariehamn",
    name: "(UTC+02:00) Mariehamn",
    utc: "+02:00",
  },
  {
    label: "Europe/Riga (UTC+02:00)",
    tzCode: "Europe/Riga",
    name: "(UTC+02:00) Riga, Daugavpils, Liepāja, Jelgava, Jūrmala",
    utc: "+02:00",
  },
  {
    label: "Europe/Sofia (UTC+02:00)",
    tzCode: "Europe/Sofia",
    name: "(UTC+02:00) Sofia, Plovdiv, Varna, Burgas, Ruse",
    utc: "+02:00",
  },
  {
    label: "Europe/Tallinn (UTC+02:00)",
    tzCode: "Europe/Tallinn",
    name: "(UTC+02:00) Tallinn, Tartu, Narva, Kohtla-Järve, Pärnu",
    utc: "+02:00",
  },
  {
    label: "Europe/Uzhgorod (UTC+02:00)",
    tzCode: "Europe/Uzhgorod",
    name: "(UTC+02:00) Uzhgorod, Mukachevo, Khust, Berehove, Tyachiv",
    utc: "+02:00",
  },
  {
    label: "Europe/Vilnius (UTC+02:00)",
    tzCode: "Europe/Vilnius",
    name: "(UTC+02:00) Vilnius, Kaunas, Klaipėda, Šiauliai, Panevėžys",
    utc: "+02:00",
  },
  {
    label: "Europe/Zaporozhye (UTC+02:00)",
    tzCode: "Europe/Zaporozhye",
    name: "(UTC+02:00) Luhansk, Sevastopol, Sievierodonetsk, Alchevsk, Lysychansk",
    utc: "+02:00",
  },
  {
    label: "Africa/Addis_Ababa (UTC+03:00)",
    tzCode: "Africa/Addis_Ababa",
    name: "(UTC+03:00) Addis Ababa, Dire Dawa, Mek'ele, Nazrēt, Bahir Dar",
    utc: "+03:00",
  },
  {
    label: "Africa/Asmara (UTC+03:00)",
    tzCode: "Africa/Asmara",
    name: "(UTC+03:00) Asmara, Keren, Massawa, Assab, Mendefera",
    utc: "+03:00",
  },
  {
    label: "Africa/Dar_es_Salaam (UTC+03:00)",
    tzCode: "Africa/Dar_es_Salaam",
    name: "(UTC+03:00) Dar es Salaam, Mwanza, Zanzibar, Arusha, Mbeya",
    utc: "+03:00",
  },
  {
    label: "Africa/Djibouti (UTC+03:00)",
    tzCode: "Africa/Djibouti",
    name: "(UTC+03:00) Djibouti, 'Ali Sabieh, Tadjourah, Obock, Dikhil",
    utc: "+03:00",
  },
  {
    label: "Africa/Kampala (UTC+03:00)",
    tzCode: "Africa/Kampala",
    name: "(UTC+03:00) Kampala, Gulu, Lira, Mbarara, Jinja",
    utc: "+03:00",
  },
  {
    label: "Africa/Mogadishu (UTC+03:00)",
    tzCode: "Africa/Mogadishu",
    name: "(UTC+03:00) Mogadishu, Hargeysa, Berbera, Kismayo, Marka",
    utc: "+03:00",
  },
  {
    label: "Africa/Nairobi (UTC+03:00)",
    tzCode: "Africa/Nairobi",
    name: "(UTC+03:00) Nairobi, Mombasa, Nakuru, Eldoret, Kisumu",
    utc: "+03:00",
  },
  {
    label: "Antarctica/Syowa (UTC+03:00)",
    tzCode: "Antarctica/Syowa",
    name: "(UTC+03:00) Syowa",
    utc: "+03:00",
  },
  {
    label: "Asia/Aden (UTC+03:00)",
    tzCode: "Asia/Aden",
    name: "(UTC+03:00) Sanaa, Al Ḩudaydah, Taiz, Aden, Mukalla",
    utc: "+03:00",
  },
  {
    label: "Asia/Baghdad (UTC+03:00)",
    tzCode: "Asia/Baghdad",
    name: "(UTC+03:00) Baghdad, Basrah, Al Mawşil al Jadīdah, Al Başrah al Qadīmah, Mosul",
    utc: "+03:00",
  },
  {
    label: "Asia/Bahrain (UTC+03:00)",
    tzCode: "Asia/Bahrain",
    name: "(UTC+03:00) Manama, Al Muharraq, Ar Rifā‘, Dār Kulayb, Madīnat Ḩamad",
    utc: "+03:00",
  },
  {
    label: "Asia/Kuwait (UTC+03:00)",
    tzCode: "Asia/Kuwait",
    name: "(UTC+03:00) Al Aḩmadī, Ḩawallī, As Sālimīyah, Şabāḩ as Sālim, Al Farwānīyah",
    utc: "+03:00",
  },
  {
    label: "Asia/Qatar (UTC+03:00)",
    tzCode: "Asia/Qatar",
    name: "(UTC+03:00) Doha, Ar Rayyān, Umm Şalāl Muḩammad, Al Wakrah, Al Khawr",
    utc: "+03:00",
  },
  {
    label: "Asia/Riyadh (UTC+03:00)",
    tzCode: "Asia/Riyadh",
    name: "(UTC+03:00) Riyadh, Jeddah, Mecca, Medina, Sulţānah",
    utc: "+03:00",
  },
  {
    label: "Europe/Istanbul (UTC+03:00)",
    tzCode: "Europe/Istanbul",
    name: "(UTC+03:00) Istanbul, Ankara, İzmir, Bursa, Adana",
    utc: "+03:00",
  },
  {
    label: "Europe/Kirov (UTC+03:00)",
    tzCode: "Europe/Kirov",
    name: "(UTC+03:00) Kirov, Kirovo-Chepetsk, Vyatskiye Polyany, Slobodskoy, Kotel’nich",
    utc: "+03:00",
  },
  {
    label: "Europe/Minsk (UTC+03:00)",
    tzCode: "Europe/Minsk",
    name: "(UTC+03:00) Minsk, Homyel', Mahilyow, Vitebsk, Hrodna",
    utc: "+03:00",
  },
  {
    label: "Europe/Moscow (UTC+03:00)",
    tzCode: "Europe/Moscow",
    name: "(UTC+03:00) Moscow, Saint Petersburg, Nizhniy Novgorod, Kazan, Rostov-na-Donu",
    utc: "+03:00",
  },
  {
    label: "Europe/Simferopol (UTC+03:00)",
    tzCode: "Europe/Simferopol",
    name: "(UTC+03:00) Simferopol, Kerch, Yevpatoriya, Yalta, Feodosiya",
    utc: "+03:00",
  },
  {
    label: "Europe/Volgograd (UTC+03:00)",
    tzCode: "Europe/Volgograd",
    name: "(UTC+03:00) Volgograd, Volzhskiy, Kamyshin, Mikhaylovka, Uryupinsk",
    utc: "+03:00",
  },
  {
    label: "Indian/Antananarivo (UTC+03:00)",
    tzCode: "Indian/Antananarivo",
    name: "(UTC+03:00) Antananarivo, Toamasina, Antsirabe, Fianarantsoa, Mahajanga",
    utc: "+03:00",
  },
  {
    label: "Indian/Comoro (UTC+03:00)",
    tzCode: "Indian/Comoro",
    name: "(UTC+03:00) Moroni, Moutsamoudou, Fomboni, Domoni, Tsimbeo",
    utc: "+03:00",
  },
  {
    label: "Indian/Mayotte (UTC+03:00)",
    tzCode: "Indian/Mayotte",
    name: "(UTC+03:00) Mamoudzou, Koungou, Dzaoudzi, Dembeni, Sada",
    utc: "+03:00",
  },
  {
    label: "Asia/Tehran (UTC+03:30)",
    tzCode: "Asia/Tehran",
    name: "(UTC+03:30) Tehran, Mashhad, Isfahan, Karaj, Tabriz",
    utc: "+03:30",
  },
  {
    label: "Asia/Baku (UTC+04:00)",
    tzCode: "Asia/Baku",
    name: "(UTC+04:00) Baku, Ganja, Sumqayıt, Lankaran, Yevlakh",
    utc: "+04:00",
  },
  {
    label: "Asia/Dubai (UTC+04:00)",
    tzCode: "Asia/Dubai",
    name: "(UTC+04:00) Dubai, Sharjah, Abu Dhabi, Ajman City, Ras Al Khaimah City",
    utc: "+04:00",
  },
  {
    label: "Asia/Muscat (UTC+04:00)",
    tzCode: "Asia/Muscat",
    name: "(UTC+04:00) Muscat, Seeb, Şalālah, Bawshar, Sohar",
    utc: "+04:00",
  },
  {
    label: "Asia/Tbilisi (UTC+04:00)",
    tzCode: "Asia/Tbilisi",
    name: "(UTC+04:00) Tbilisi, Kutaisi, Batumi, Sokhumi, Zugdidi",
    utc: "+04:00",
  },
  {
    label: "Asia/Yerevan (UTC+04:00)",
    tzCode: "Asia/Yerevan",
    name: "(UTC+04:00) Yerevan, Gyumri, Vanadzor, Vagharshapat, Hrazdan",
    utc: "+04:00",
  },
  {
    label: "Europe/Astrakhan (UTC+04:00)",
    tzCode: "Europe/Astrakhan",
    name: "(UTC+04:00) Astrakhan, Akhtubinsk, Znamensk, Kharabali, Kamyzyak",
    utc: "+04:00",
  },
  {
    label: "Europe/Samara (UTC+04:00)",
    tzCode: "Europe/Samara",
    name: "(UTC+04:00) Samara, Togliatti-on-the-Volga, Izhevsk, Syzran’, Novokuybyshevsk",
    utc: "+04:00",
  },
  {
    label: "Europe/Saratov (UTC+04:00)",
    tzCode: "Europe/Saratov",
    name: "(UTC+04:00) Saratov, Balakovo, Engel’s, Balashov, Vol’sk",
    utc: "+04:00",
  },
  {
    label: "Europe/Ulyanovsk (UTC+04:00)",
    tzCode: "Europe/Ulyanovsk",
    name: "(UTC+04:00) Ulyanovsk, Dimitrovgrad, Inza, Barysh, Novoul’yanovsk",
    utc: "+04:00",
  },
  {
    label: "Indian/Mahe (UTC+04:00)",
    tzCode: "Indian/Mahe",
    name: "(UTC+04:00) Victoria, Anse Boileau, Bel Ombre, Beau Vallon, Cascade",
    utc: "+04:00",
  },
  {
    label: "Indian/Mauritius (UTC+04:00)",
    tzCode: "Indian/Mauritius",
    name: "(UTC+04:00) Port Louis, Beau Bassin-Rose Hill, Vacoas, Curepipe, Quatre Bornes",
    utc: "+04:00",
  },
  {
    label: "Indian/Reunion (UTC+04:00)",
    tzCode: "Indian/Reunion",
    name: "(UTC+04:00) Saint-Denis, Saint-Paul, Saint-Pierre, Le Tampon, Saint-André",
    utc: "+04:00",
  },
  {
    label: "Asia/Kabul (UTC+04:30)",
    tzCode: "Asia/Kabul",
    name: "(UTC+04:30) Kabul, Kandahār, Mazār-e Sharīf, Herāt, Jalālābād",
    utc: "+04:30",
  },
  {
    label: "Antarctica/Mawson (UTC+05:00)",
    tzCode: "Antarctica/Mawson",
    name: "(UTC+05:00) Mawson",
    utc: "+05:00",
  },
  {
    label: "Asia/Aqtau (UTC+05:00)",
    tzCode: "Asia/Aqtau",
    name: "(UTC+05:00) Shevchenko, Zhanaozen, Beyneu, Shetpe, Yeraliyev",
    utc: "+05:00",
  },
  {
    label: "Asia/Aqtobe (UTC+05:00)",
    tzCode: "Asia/Aqtobe",
    name: "(UTC+05:00) Aktobe, Kandyagash, Shalqar, Khromtau, Embi",
    utc: "+05:00",
  },
  {
    label: "Asia/Ashgabat (UTC+05:00)",
    tzCode: "Asia/Ashgabat",
    name: "(UTC+05:00) Ashgabat, Türkmenabat, Daşoguz, Mary, Balkanabat",
    utc: "+05:00",
  },
  {
    label: "Asia/Atyrau (UTC+05:00)",
    tzCode: "Asia/Atyrau",
    name: "(UTC+05:00) Atyrau, Qulsary, Shalkar, Balykshi, Maqat",
    utc: "+05:00",
  },
  {
    label: "Asia/Dushanbe (UTC+05:00)",
    tzCode: "Asia/Dushanbe",
    name: "(UTC+05:00) Dushanbe, Khujand, Kŭlob, Bokhtar, Istaravshan",
    utc: "+05:00",
  },
  {
    label: "Asia/Karachi (UTC+05:00)",
    tzCode: "Asia/Karachi",
    name: "(UTC+05:00) Karachi, Lahore, Faisalabad, Rawalpindi, Multan",
    utc: "+05:00",
  },
  {
    label: "Asia/Oral (UTC+05:00)",
    tzCode: "Asia/Oral",
    name: "(UTC+05:00) Oral, Aqsay, Zhänibek, Tasqala, Zhumysker",
    utc: "+05:00",
  },
  {
    label: "Asia/Qyzylorda (UTC+05:00)",
    tzCode: "Asia/Qyzylorda",
    name: "(UTC+05:00) Kyzylorda, Baikonur, Novokazalinsk, Aral, Chiili",
    utc: "+05:00",
  },
  {
    label: "Asia/Samarkand (UTC+05:00)",
    tzCode: "Asia/Samarkand",
    name: "(UTC+05:00) Samarkand, Bukhara, Nukus, Qarshi, Jizzax",
    utc: "+05:00",
  },
  {
    label: "Asia/Tashkent (UTC+05:00)",
    tzCode: "Asia/Tashkent",
    name: "(UTC+05:00) Tashkent, Namangan, Andijon, Qo‘qon, Chirchiq",
    utc: "+05:00",
  },
  {
    label: "Asia/Yekaterinburg (UTC+05:00)",
    tzCode: "Asia/Yekaterinburg",
    name: "(UTC+05:00) Yekaterinburg, Chelyabinsk, Ufa, Perm, Orenburg",
    utc: "+05:00",
  },
  {
    label: "Indian/Kerguelen (UTC+05:00)",
    tzCode: "Indian/Kerguelen",
    name: "(UTC+05:00) Port-aux-Français",
    utc: "+05:00",
  },
  {
    label: "Indian/Maldives (UTC+05:00)",
    tzCode: "Indian/Maldives",
    name: "(UTC+05:00) Male, Fuvahmulah, Hithadhoo, Kulhudhuffushi, Thinadhoo",
    utc: "+05:00",
  },
  {
    label: "Asia/Colombo (UTC+05:30)",
    tzCode: "Asia/Colombo",
    name: "(UTC+05:30) Colombo, Dehiwala-Mount Lavinia, Moratuwa, Jaffna, Negombo",
    utc: "+05:30",
  },
  {
    label: "Asia/Kolkata (UTC+05:30)",
    tzCode: "Asia/Kolkata",
    name: "(UTC+05:30) Mumbai, Delhi, Bengaluru, Kolkata, Chennai",
    utc: "+05:30",
  },
  {
    label: "Asia/Kathmandu (UTC+05:45)",
    tzCode: "Asia/Kathmandu",
    name: "(UTC+05:45) Kathmandu, Pokhara, Pātan, Biratnagar, Birgañj",
    utc: "+05:45",
  },
  {
    label: "Antarctica/Vostok (UTC+06:00)",
    tzCode: "Antarctica/Vostok",
    name: "(UTC+06:00) Vostok",
    utc: "+06:00",
  },
  {
    label: "Asia/Almaty (UTC+06:00)",
    tzCode: "Asia/Almaty",
    name: "(UTC+06:00) Almaty, Karagandy, Shymkent, Taraz, Nur-Sultan",
    utc: "+06:00",
  },
  {
    label: "Asia/Bishkek (UTC+06:00)",
    tzCode: "Asia/Bishkek",
    name: "(UTC+06:00) Bishkek, Osh, Jalal-Abad, Karakol, Tokmok",
    utc: "+06:00",
  },
  {
    label: "Asia/Dhaka (UTC+06:00)",
    tzCode: "Asia/Dhaka",
    name: "(UTC+06:00) Dhaka, Chattogram, Khulna, Rājshāhi, Comilla",
    utc: "+06:00",
  },
  {
    label: "Asia/Omsk (UTC+06:00)",
    tzCode: "Asia/Omsk",
    name: "(UTC+06:00) Omsk, Tara, Kalachinsk, Znamenskoye, Tavricheskoye",
    utc: "+06:00",
  },
  {
    label: "Asia/Qostanay (UTC+06:00)",
    tzCode: "Asia/Qostanay",
    name: "(UTC+06:00) Kostanay, Rudnyy, Dzhetygara, Arkalyk, Lisakovsk",
    utc: "+06:00",
  },
  {
    label: "Asia/Thimphu (UTC+06:00)",
    tzCode: "Asia/Thimphu",
    name: "(UTC+06:00) himphu, Punākha, Tsirang, Phuntsholing, Pemagatshel",
    utc: "+06:00",
  },
  {
    label: "Asia/Urumqi (UTC+06:00)",
    tzCode: "Asia/Urumqi",
    name: "(UTC+06:00) Zhongshan, Ürümqi, Zhanjiang, Shihezi, Huocheng",
    utc: "+06:00",
  },
  {
    label: "Indian/Chagos (UTC+06:00)",
    tzCode: "Indian/Chagos",
    name: "(UTC+06:00) British Indian Ocean Territory",
    utc: "+06:00",
  },
  {
    label: "Asia/Yangon (UTC+06:30)",
    tzCode: "Asia/Yangon",
    name: "(UTC+06:30) Yangon, Mandalay, Nay Pyi Taw, Mawlamyine, Kyain Seikgyi Township",
    utc: "+06:30",
  },
  {
    label: "Indian/Cocos (UTC+06:30)",
    tzCode: "Indian/Cocos",
    name: "(UTC+06:30) West Island",
    utc: "+06:30",
  },
  {
    label: "Antarctica/Davis (UTC+07:00)",
    tzCode: "Antarctica/Davis",
    name: "(UTC+07:00) Davis",
    utc: "+07:00",
  },
  {
    label: "Asia/Bangkok (UTC+07:00)",
    tzCode: "Asia/Bangkok",
    name: "(UTC+07:00) Bangkok, Hanoi, Haiphong, Samut Prakan, Mueang Nonthaburi",
    utc: "+07:00",
  },
  {
    label: "Asia/Barnaul (UTC+07:00)",
    tzCode: "Asia/Barnaul",
    name: "(UTC+07:00) Barnaul, Biysk, Rubtsovsk, Novoaltaysk, Gorno-Altaysk",
    utc: "+07:00",
  },
  {
    label: "Asia/Hovd (UTC+07:00)",
    tzCode: "Asia/Hovd",
    name: "(UTC+07:00) Khovd, Ölgii, Ulaangom, Uliastay, Altai",
    utc: "+07:00",
  },
  {
    label: "Asia/Ho_Chi_Minh (UTC+07:00)",
    tzCode: "Asia/Ho_Chi_Minh",
    name: "(UTC+07:00) Ho Chi Minh City, Da Nang, Biên Hòa, Nha Trang, Cần Thơ",
    utc: "+07:00",
  },
  {
    label: "Asia/Jakarta (UTC+07:00)",
    tzCode: "Asia/Jakarta",
    name: "(UTC+07:00) Jakarta, Surabaya, Medan, Bandung, Bekasi",
    utc: "+07:00",
  },
  {
    label: "Asia/Krasnoyarsk (UTC+07:00)",
    tzCode: "Asia/Krasnoyarsk",
    name: "(UTC+07:00) Krasnoyarsk, Abakan, Norilsk, Achinsk, Kyzyl",
    utc: "+07:00",
  },
  {
    label: "Asia/Novokuznetsk (UTC+07:00)",
    tzCode: "Asia/Novokuznetsk",
    name: "(UTC+07:00) Novokuznetsk, Kemerovo, Prokop’yevsk, Leninsk-Kuznetsky, Kiselëvsk",
    utc: "+07:00",
  },
  {
    label: "Asia/Novosibirsk (UTC+07:00)",
    tzCode: "Asia/Novosibirsk",
    name: "(UTC+07:00) Novosibirsk, Berdsk, Iskitim, Akademgorodok, Kuybyshev",
    utc: "+07:00",
  },
  {
    label: "Asia/Phnom_Penh (UTC+07:00)",
    tzCode: "Asia/Phnom_Penh",
    name: "(UTC+07:00) Phnom Penh, Takeo, Sihanoukville, Battambang, Siem Reap",
    utc: "+07:00",
  },
  {
    label: "Asia/Pontianak (UTC+07:00)",
    tzCode: "Asia/Pontianak",
    name: "(UTC+07:00) Pontianak, Tanjung Pinang, Palangkaraya, Singkawang, Sampit",
    utc: "+07:00",
  },
  {
    label: "Asia/Tomsk (UTC+07:00)",
    tzCode: "Asia/Tomsk",
    name: "(UTC+07:00) Tomsk, Seversk, Strezhevoy, Kolpashevo, Asino",
    utc: "+07:00",
  },
  {
    label: "Asia/Vientiane (UTC+07:00)",
    tzCode: "Asia/Vientiane",
    name: "(UTC+07:00) Vientiane, Pakse, Thakhèk, Savannakhet, Luang Prabang",
    utc: "+07:00",
  },
  {
    label: "Indian/Christmas (UTC+07:00)",
    tzCode: "Indian/Christmas",
    name: "(UTC+07:00) Flying Fish Cove",
    utc: "+07:00",
  },
  {
    label: "Asia/Brunei (UTC+08:00)",
    tzCode: "Asia/Brunei",
    name: "(UTC+08:00) Bandar Seri Begawan, Kuala Belait, Seria, Tutong, Bangar",
    utc: "+08:00",
  },
  {
    label: "Asia/Choibalsan (UTC+08:00)",
    tzCode: "Asia/Choibalsan",
    name: "(UTC+08:00) Baruun-Urt, Choibalsan",
    utc: "+08:00",
  },
  {
    label: "Asia/Hong_Kong (UTC+08:00)",
    tzCode: "Asia/Hong_Kong",
    name: "(UTC+08:00) Hong Kong, Kowloon, Tsuen Wan, Yuen Long Kau Hui, Tung Chung",
    utc: "+08:00",
  },
  {
    label: "Asia/Irkutsk (UTC+08:00)",
    tzCode: "Asia/Irkutsk",
    name: "(UTC+08:00) Irkutsk, Ulan-Ude, Bratsk, Angarsk, Ust’-Ilimsk",
    utc: "+08:00",
  },
  {
    label: "Asia/Kuala_Lumpur (UTC+08:00)",
    tzCode: "Asia/Kuala_Lumpur",
    name: "(UTC+08:00) Kota Bharu, Kuala Lumpur, Klang, Kampung Baru Subang, Johor Bahru",
    utc: "+08:00",
  },
  {
    label: "Asia/Kuching (UTC+08:00)",
    tzCode: "Asia/Kuching",
    name: "(UTC+08:00) Kuching, Kota Kinabalu, Sandakan, Tawau, Miri",
    utc: "+08:00",
  },
  {
    label: "Asia/Macau (UTC+08:00)",
    tzCode: "Asia/Macau",
    name: "(UTC+08:00) Macau",
    utc: "+08:00",
  },
  {
    label: "Asia/Makassar (UTC+08:00)",
    tzCode: "Asia/Makassar",
    name: "(UTC+08:00) Makassar, Denpasar, City of Balikpapan, Banjarmasin, Manado",
    utc: "+08:00",
  },
  {
    label: "Asia/Manila (UTC+08:00)",
    tzCode: "Asia/Manila",
    name: "(UTC+08:00) Quezon City, Manila, Caloocan City, Budta, Davao",
    utc: "+08:00",
  },
  {
    label: "Asia/Shanghai (UTC+08:00)",
    tzCode: "Asia/Shanghai",
    name: "(UTC+08:00) Shanghai, Beijing, Tianjin, Guangzhou, Shenzhen",
    utc: "+08:00",
  },
  {
    label: "Asia/Singapore (UTC+08:00)",
    tzCode: "Asia/Singapore",
    name: "(UTC+08:00) Singapore, Woodlands",
    utc: "+08:00",
  },
  {
    label: "Asia/Taipei (UTC+08:00)",
    tzCode: "Asia/Taipei",
    name: "(UTC+08:00) Taipei, Kaohsiung, Taichung, Tainan, Banqiao",
    utc: "+08:00",
  },
  {
    label: "Asia/Ulaanbaatar (UTC+08:00)",
    tzCode: "Asia/Ulaanbaatar",
    name: "(UTC+08:00) Ulan Bator, Erdenet, Darhan, Hovd, Mörön",
    utc: "+08:00",
  },
  {
    label: "Australia/Perth (UTC+08:00)",
    tzCode: "Australia/Perth",
    name: "(UTC+08:00) Perth, Rockingham, Mandurah, Bunbury, Albany",
    utc: "+08:00",
  },
  {
    label: "Australia/Eucla (UTC+08:45)",
    tzCode: "Australia/Eucla",
    name: "(UTC+08:45) Eucla",
    utc: "+08:45",
  },
  {
    label: "Asia/Chita (UTC+09:00)",
    tzCode: "Asia/Chita",
    name: "(UTC+09:00) Chita, Krasnokamensk, Borzya, Petrovsk-Zabaykal’skiy, Aginskoye",
    utc: "+09:00",
  },
  {
    label: "Asia/Dili (UTC+09:00)",
    tzCode: "Asia/Dili",
    name: "(UTC+09:00) Dili, Maliana, Suai, Likisá, Aileu",
    utc: "+09:00",
  },
  {
    label: "Asia/Jayapura (UTC+09:00)",
    tzCode: "Asia/Jayapura",
    name: "(UTC+09:00) Ambon, Jayapura, Sorong, Ternate, Abepura",
    utc: "+09:00",
  },
  {
    label: "Asia/Khandyga (UTC+09:00)",
    tzCode: "Asia/Khandyga",
    name: "(UTC+09:00) Khandyga",
    utc: "+09:00",
  },
  {
    label: "Asia/Pyongyang (UTC+09:00)",
    tzCode: "Asia/Pyongyang",
    name: "(UTC+09:00) Pyongyang, Hamhŭng, Namp’o, Sunch’ŏn, Hŭngnam",
    utc: "+09:00",
  },
  {
    label: "Asia/Seoul (UTC+09:00)",
    tzCode: "Asia/Seoul",
    name: "(UTC+09:00) Seoul, Busan, Incheon, Daegu, Daejeon",
    utc: "+09:00",
  },
  {
    label: "Asia/Tokyo (UTC+09:00)",
    tzCode: "Asia/Tokyo",
    name: "(UTC+09:00) Tokyo, Yokohama, Osaka, Nagoya, Sapporo",
    utc: "+09:00",
  },
  {
    label: "Asia/Yakutsk (UTC+09:00)",
    tzCode: "Asia/Yakutsk",
    name: "(UTC+09:00) Yakutsk, Blagoveshchensk, Belogorsk, Neryungri, Svobodnyy",
    utc: "+09:00",
  },
  {
    label: "Pacific/Palau (UTC+09:00)",
    tzCode: "Pacific/Palau",
    name: "(UTC+09:00) Koror, Koror Town, Kloulklubed, Ulimang, Mengellang",
    utc: "+09:00",
  },
  {
    label: "Australia/Adelaide (UTC+09:30)",
    tzCode: "Australia/Adelaide",
    name: "(UTC+09:30) Adelaide, Adelaide Hills, Mount Gambier, Morphett Vale, Gawler",
    utc: "+09:30",
  },
  {
    label: "Australia/Broken_Hill (UTC+09:30)",
    tzCode: "Australia/Broken_Hill",
    name: "(UTC+09:30) Broken Hill",
    utc: "+09:30",
  },
  {
    label: "Australia/Darwin (UTC+09:30)",
    tzCode: "Australia/Darwin",
    name: "(UTC+09:30) Darwin, Alice Springs, Palmerston, Howard Springs",
    utc: "+09:30",
  },
  {
    label: "Antarctica/DumontDUrville (UTC+10:00)",
    tzCode: "Antarctica/DumontDUrville",
    name: "(UTC+10:00) DumontDUrville",
    utc: "+10:00",
  },
  {
    label: "Antarctica/Macquarie (UTC+10:00)",
    tzCode: "Antarctica/Macquarie",
    name: "(UTC+10:00) Macquarie",
    utc: "+10:00",
  },
  {
    label: "Asia/Ust-Nera (UTC+10:00)",
    tzCode: "Asia/Ust-Nera",
    name: "(UTC+10:00) Ust-Nera",
    utc: "+10:00",
  },
  {
    label: "Asia/Vladivostok (UTC+10:00)",
    tzCode: "Asia/Vladivostok",
    name: "(UTC+10:00) Vladivostok, Khabarovsk, Khabarovsk Vtoroy, Komsomolsk-on-Amur, Ussuriysk",
    utc: "+10:00",
  },
  {
    label: "Australia/Brisbane (UTC+10:00)",
    tzCode: "Australia/Brisbane",
    name: "(UTC+10:00) Brisbane, Gold Coast, Logan City, Townsville, Cairns",
    utc: "+10:00",
  },
  {
    label: "Australia/Currie (UTC+10:00)",
    tzCode: "Australia/Currie",
    name: "(UTC+10:00) Currie",
    utc: "+10:00",
  },
  {
    label: "Australia/Hobart (UTC+10:00)",
    tzCode: "Australia/Hobart",
    name: "(UTC+10:00) Hobart, Launceston, Burnie, Devonport, Sandy Bay",
    utc: "+10:00",
  },
  {
    label: "Australia/Lindeman (UTC+10:00)",
    tzCode: "Australia/Lindeman",
    name: "(UTC+10:00) Lindeman",
    utc: "+10:00",
  },
  {
    label: "Australia/Melbourne (UTC+10:00)",
    tzCode: "Australia/Melbourne",
    name: "(UTC+10:00) Melbourne, Geelong, Bendigo, Ballarat, Melbourne City Centre",
    utc: "+10:00",
  },
  {
    label: "Australia/Sydney (UTC+10:00)",
    tzCode: "Australia/Sydney",
    name: "(UTC+10:00) Sydney, Canberra, Newcastle, Wollongong, Maitland",
    utc: "+10:00",
  },
  {
    label: "Pacific/Chuuk (UTC+10:00)",
    tzCode: "Pacific/Chuuk",
    name: "(UTC+10:00) Weno, Colonia",
    utc: "+10:00",
  },
  {
    label: "Pacific/GuamVillage (UTC+10:00)",
    tzCode: "Pacific/GuamVillage",
    name: "(UTC+10:00) Dededo Village, Yigo Village, Tamuning, Tamuning-Tumon-Harmon Village, Mangilao Village",
    utc: "+10:00",
  },
  {
    label: "Pacific/Port_Moresby (UTC+10:00)",
    tzCode: "Pacific/Port_Moresby",
    name: "(UTC+10:00) Port Moresby, Lae, Mount Hagen, Popondetta, Madang",
    utc: "+10:00",
  },
  {
    label: "Pacific/Saipan (UTC+10:00)",
    tzCode: "Pacific/Saipan",
    name: "(UTC+10:00) Saipan, San Jose Village",
    utc: "+10:00",
  },
  {
    label: "Australia/Lord_Howe (UTC+10:30)",
    tzCode: "Australia/Lord_Howe",
    name: "(UTC+10:30) Lord Howe",
    utc: "+10:30",
  },
  {
    label: "Antarctica/Casey (UTC+11:00)",
    tzCode: "Antarctica/Casey",
    name: "(UTC+11:00) Casey",
    utc: "+11:00",
  },
  {
    label: "Asia/Magadan (UTC+11:00)",
    tzCode: "Asia/Magadan",
    name: "(UTC+11:00) Magadan, Ust-Nera, Susuman, Ola",
    utc: "+11:00",
  },
  {
    label: "Asia/Sakhalin (UTC+11:00)",
    tzCode: "Asia/Sakhalin",
    name: "(UTC+11:00) Yuzhno-Sakhalinsk, Korsakov, Kholmsk, Okha, Nevel’sk",
    utc: "+11:00",
  },
  {
    label: "Asia/Srednekolymsk (UTC+11:00)",
    tzCode: "Asia/Srednekolymsk",
    name: "(UTC+11:00) Srednekolymsk",
    utc: "+11:00",
  },
  {
    label: "Pacific/Bougainville (UTC+11:00)",
    tzCode: "Pacific/Bougainville",
    name: "(UTC+11:00) Arawa, Buka",
    utc: "+11:00",
  },
  {
    label: "Pacific/Efate (UTC+11:00)",
    tzCode: "Pacific/Efate",
    name: "(UTC+11:00) Port-Vila, Luganville, Isangel, Sola, Lakatoro",
    utc: "+11:00",
  },
  {
    label: "Pacific/Guadalcanal (UTC+11:00)",
    tzCode: "Pacific/Guadalcanal",
    name: "(UTC+11:00) Honiara, Malango, Auki, Gizo, Buala",
    utc: "+11:00",
  },
  {
    label: "Pacific/Kosrae (UTC+11:00)",
    tzCode: "Pacific/Kosrae",
    name: "(UTC+11:00) Tofol",
    utc: "+11:00",
  },
  {
    label: "Pacific/Norfolk (UTC+11:00)",
    tzCode: "Pacific/Norfolk",
    name: "(UTC+11:00) Kingston",
    utc: "+11:00",
  },
  {
    label: "Pacific/Noumea (UTC+11:00)",
    tzCode: "Pacific/Noumea",
    name: "(UTC+11:00) Nouméa, Mont-Dore, Dumbéa, Païta, Wé",
    utc: "+11:00",
  },
  {
    label: "Pacific/Pohnpei (UTC+11:00)",
    tzCode: "Pacific/Pohnpei",
    name: "(UTC+11:00) Kolonia, Kolonia Town, Palikir - National Government Center",
    utc: "+11:00",
  },
  {
    label: "Antarctica/McMurdo (UTC+12:00)",
    tzCode: "Antarctica/McMurdo",
    name: "(UTC+12:00) McMurdo",
    utc: "+12:00",
  },
  {
    label: "Asia/Anadyr (UTC+12:00)",
    tzCode: "Asia/Anadyr",
    name: "(UTC+12:00) Anadyr, Bilibino",
    utc: "+12:00",
  },
  {
    label: "Asia/Kamchatka (UTC+12:00)",
    tzCode: "Asia/Kamchatka",
    name: "(UTC+12:00) Petropavlovsk-Kamchatsky, Yelizovo, Vilyuchinsk, Klyuchi, Mil’kovo",
    utc: "+12:00",
  },
  {
    label: "Pacific/Auckland (UTC+12:00)",
    tzCode: "Pacific/Auckland",
    name: "(UTC+12:00) Auckland, Wellington, Christchurch, Manukau City, North Shore",
    utc: "+12:00",
  },
  {
    label: "Pacific/Fiji (UTC+12:00)",
    tzCode: "Pacific/Fiji",
    name: "(UTC+12:00) Suva, Lautoka, Nadi, Labasa, Ba",
    utc: "+12:00",
  },
  {
    label: "Pacific/Funafuti (UTC+12:00)",
    tzCode: "Pacific/Funafuti",
    name: "(UTC+12:00) Funafuti, Savave Village, Tanrake Village, Toga Village, Asau Village",
    utc: "+12:00",
  },
  {
    label: "Pacific/Kwajalein (UTC+12:00)",
    tzCode: "Pacific/Kwajalein",
    name: "(UTC+12:00) Ebaye, Jabat",
    utc: "+12:00",
  },
  {
    label: "Pacific/Majuro (UTC+12:00)",
    tzCode: "Pacific/Majuro",
    name: "(UTC+12:00) Majuro, Arno, Jabor, Wotje, Mili",
    utc: "+12:00",
  },
  {
    label: "Pacific/Nauru (UTC+12:00)",
    tzCode: "Pacific/Nauru",
    name: "(UTC+12:00) Yaren, Baiti, Anabar, Uaboe, Ijuw",
    utc: "+12:00",
  },
  {
    label: "Pacific/Tarawa (UTC+12:00)",
    tzCode: "Pacific/Tarawa",
    name: "(UTC+12:00) Tarawa, Betio Village, Bikenibeu Village",
    utc: "+12:00",
  },
  {
    label: "Pacific/Wake (UTC+12:00)",
    tzCode: "Pacific/Wake",
    name: "(UTC+12:00) Wake",
    utc: "+12:00",
  },
  {
    label: "Pacific/Wallis (UTC+12:00)",
    tzCode: "Pacific/Wallis",
    name: "(UTC+12:00) Mata-Utu, Leava, Alo",
    utc: "+12:00",
  },
  {
    label: "Pacific/Chatham (UTC+12:45)",
    tzCode: "Pacific/Chatham",
    name: "(UTC+12:45) Waitangi",
    utc: "+12:45",
  },
  {
    label: "Pacific/Apia (UTC+13:00)",
    tzCode: "Pacific/Apia",
    name: "(UTC+13:00) Apia, Asau, Mulifanua, Afega, Leulumoega",
    utc: "+13:00",
  },
  {
    label: "Pacific/Enderbury (UTC+13:00)",
    tzCode: "Pacific/Enderbury",
    name: "(UTC+13:00) Enderbury",
    utc: "+13:00",
  },
  {
    label: "Pacific/Fakaofo (UTC+13:00)",
    tzCode: "Pacific/Fakaofo",
    name: "(UTC+13:00) Atafu Village, Nukunonu, Fale old settlement",
    utc: "+13:00",
  },
  {
    label: "Pacific/Tongatapu (UTC+13:00)",
    tzCode: "Pacific/Tongatapu",
    name: "(UTC+13:00) Nuku‘alofa, Lapaha, Neiafu, Pangai, ‘Ohonua",
    utc: "+13:00",
  },
  {
    label: "Pacific/Kiritimati (UTC+14:00)",
    tzCode: "Pacific/Kiritimati",
    name: "(UTC+14:00) Kiritimati",
    utc: "+14:00",
  },
]
