import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { hideToaster } from "src/components/TopNav"
import { APPLICATION_TROUBLESHOOTING_RESULT } from "src/constants"
import useFetch from "src/hooks/useFetch"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { RootState } from "src/store"
import { openModal, setToasterState } from "src/store/ui/uiSlice"
import { EXTREME_SUPPORT_URL } from "src/utils/constants"
import { startApplicationTroubleshooting } from "./ApplicationTroubleshooting.service"
import { useApplicationTroubleshootingStyles } from "./ApplicationTroubleshooting.styles"
import EndApplicationTroubleshooting from "./EndApplicationTroubleshooting"
import TroubleshootingStep, { Props as TroubleshootingStepProps } from "./TroubleshootingStep"

const BlankSlate = () => {
  const classes = useApplicationTroubleshootingStyles()
  return (
    <div className={classes.blankslate}>
      <div className={classes.blankslateIcon}>
        <ZtnaIcon name="enTroubleshoot" size="xl" />
      </div>

      <ENTextPassage>
        <p>
          Application Troubleshooting <br /> not done yet.
        </p>
      </ENTextPassage>
    </div>
  )
}

export const enum Status {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  TERMINATED = "TERMINATED",
  TIMEOUT = "TIMEOUT",
}

export type StatusType = keyof typeof Status

type ApplicationTroubleshootingResultType = {
  status: StatusType

  /*
      About `status` key:

        Determines the overall status:
        - SUCCESS: All other statuses are SUCCESS.
        - FAILED: Any other status is FAILED.

        CTA Button:
        - IN_PROGRESS: Show "End Application Troubleshooting" button
        - Otherwise: Show "Start Application Troubleshooting" button
  */

  logsCapture: boolean
  serviceConnectorConnectivity: { status: StatusType; description: string }
  applicationConnectionStatus: { status: StatusType; description: string }
  tunnelStatus: { status: StatusType; description: string }
  agentStatus: { status: StatusType; description: string }
}

type TroubleshootingDataType = Omit<
  TroubleshootingStepProps,
  "evaluationId" | "circleNumber" | "showLogDownloadIcon" | "hideVerticalLine"
>

interface Props {
  evaluationId: string
  isAgentless: boolean
  disableStartBtn: boolean
}

const ApplicationTroubleshooting = ({ evaluationId, isAgentless, disableStartBtn }: Props) => {
  const classes = useApplicationTroubleshootingStyles()

  const dispatch = useDispatch()

  const openedModals = useSelector((state: RootState) => state.ui.openedModals)

  const { data, error, isLoading, mutate } = useFetch<ApplicationTroubleshootingResultType>({
    apiUrl: APPLICATION_TROUBLESHOOTING_RESULT(evaluationId),
    options: { refreshInterval: 10_000 },
  })

  useEffect(() => {
    if (error) {
      dispatch(
        setToasterState({
          message: error.message,
          type: "danger",
          autoHide: false,
        }),
      )
    }

    return hideToaster
  }, [error])

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [troubleshootingData, setTroubleshootingData] = useState<TroubleshootingDataType[]>([])

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return

    const getCircleType = (previousStepStatus: StatusType, currentStepStatus: StatusType) =>
      previousStepStatus === Status.SUCCESS
        ? currentCircleTypeStatuses.includes(currentStepStatus as Status)
          ? "current"
          : "complete"
        : "initial"

    const getErrorText = (status: StatusType, description?: string) => {
      switch (status) {
        case Status.FAILED:
          return description || null
        case Status.TERMINATED:
          return "Troubleshooting process has terminated."
        case Status.TIMEOUT:
          return "Troubleshooting process has timed out."
        default:
          return null
      }
    }

    const { logsCapture, serviceConnectorConnectivity, applicationConnectionStatus, tunnelStatus, agentStatus } = data

    const currentCircleTypeStatuses = [Status.FAILED, Status.IN_PROGRESS, Status.TERMINATED, Status.TIMEOUT]

    setTroubleshootingData([
      {
        heading: "Service Connector Cloud Connectivity",
        subHeading: "Service Connector is connected to the UZTNA Gateway",
        circleType: currentCircleTypeStatuses.includes(serviceConnectorConnectivity.status as Status)
          ? "current"
          : "complete",
        status: serviceConnectorConnectivity.status,
        errorText: getErrorText(serviceConnectorConnectivity.status, serviceConnectorConnectivity.description),
      },
      {
        heading: "Application Connectivity Status",
        subHeading: "Service Connector can reach the application",
        circleType: getCircleType(serviceConnectorConnectivity.status, applicationConnectionStatus.status),
        status: serviceConnectorConnectivity.status === Status.SUCCESS ? applicationConnectionStatus.status : null,
        errorText:
          serviceConnectorConnectivity.status === Status.SUCCESS
            ? getErrorText(applicationConnectionStatus.status, applicationConnectionStatus.description)
            : null,
      },
      {
        heading: "Tunnel Status",
        subHeading: "End-to-end IPSec/WireGuard tunnel connectivity",
        circleType: getCircleType(applicationConnectionStatus.status, tunnelStatus.status),
        status: applicationConnectionStatus.status === Status.SUCCESS ? tunnelStatus.status : null,
        errorText:
          applicationConnectionStatus.status === Status.SUCCESS
            ? getErrorText(tunnelStatus.status, tunnelStatus.description)
            : null,
      },
      ...(isAgentless
        ? []
        : [
            {
              heading: "Agent Status",
              subHeading: "Agent can reach application",
              circleType: getCircleType(tunnelStatus.status, agentStatus.status),
              status: tunnelStatus.status === Status.SUCCESS ? agentStatus.status : null,
              errorText:
                tunnelStatus.status === Status.SUCCESS
                  ? getErrorText(agentStatus.status, agentStatus.description)
                  : null,
            } as TroubleshootingDataType,
          ]),
      {
        heading: "Log Collection",
        subHeading: "Logs available for Download",
        circleType: logsCapture ? "complete" : "initial",
        status: logsCapture ? Status.SUCCESS : null,
        errorText: null,
      },
    ])
  }, [data])

  const handleStartApplicationTroubleshooting = async () => {
    setIsSubmitting(true)
    startApplicationTroubleshooting(evaluationId)
      .then(() => {
        setIsSubmitting(false)
        mutate()
        dispatch(
          setToasterState({
            message: "Application troubleshooting started successfully.",
            type: "success",
            autoHide: true,
          }),
        )
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            message: err?.errors || err?.message,
            type: "danger",
            autoHide: false,
          }),
        )
        setIsSubmitting(false)
      })
  }

  const handleEndApplicationTroubleshooting = () => dispatch(openModal("endApplicationTroubleshooting"))

  return (
    <div>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <div>
            <ENTextPassage>
              <p className={classes.cardHeading}>Application Troubleshooting</p>
            </ENTextPassage>

            <ENTextPassage>
              <p className={classes.cardDescription}>
                Application Troubleshooting is enabled once the Policy Evaluation is completed and only if, one of the
                Policy condition allows user access to the applications. To complete Troubleshooting the Client/ Agent
                should be online.
              </p>
            </ENTextPassage>
          </div>

          {data?.status === Status.IN_PROGRESS ? (
            <ZtnaButton
              className={classes.cta}
              buttonType="secondary"
              title="End Application Troubleshooting"
              onClick={handleEndApplicationTroubleshooting}
            />
          ) : (
            <ZtnaButton
              className={classes.cta}
              buttonType="secondary"
              title="Start Application Troubleshooting"
              onClick={handleStartApplicationTroubleshooting}
              disabled={disableStartBtn || isLoading || isSubmitting}
              startIcon={isSubmitting && <CircularLoader />}
            />
          )}
        </div>

        {data && Object.keys(data).length > 0 ? (
          <>
            <div className={classes.stepsWrapper}>
              {troubleshootingData?.map((step, idx) => (
                <TroubleshootingStep
                  key={idx}
                  circleNumber={idx + 1}
                  evaluationId={evaluationId}
                  hideVerticalLine={idx === troubleshootingData?.length - 1}
                  {...step}
                />
              ))}
            </div>

            <div className={classes.divider} />

            <div className={classes.supportTicket}>
              <ENTextPassage>
                <p className={classes.ticketText}>To create a support ticket</p>
              </ENTextPassage>

              <ENTextPassage>
                <Link to={EXTREME_SUPPORT_URL} target="_blank">
                  <p className={classes.ticketLink}>Click Here</p>
                </Link>
              </ENTextPassage>
            </div>
          </>
        ) : (
          <BlankSlate />
        )}
      </div>

      {openedModals["endApplicationTroubleshooting"] && (
        <EndApplicationTroubleshooting evaluationId={evaluationId} onCloseCallback={mutate} />
      )}
    </div>
  )
}

export default ApplicationTroubleshooting
