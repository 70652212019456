import { ENButton } from "en-react/dist/src/components/Button"
import { ENIconChevronLeft } from "en-react/dist/src/components/Icons/ChevronLeft"
import { ENIconChevronRight } from "en-react/dist/src/components/Icons/ChevronRight"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { RADSEC_PROXY_SHOULD_INITIATE } from "src/craas/apiURLs"
import useFetch from "src/hooks/useFetch"
import { Roles } from "src/routes/config"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { openModal } from "src/store/ui"
import { ModalNamesType } from "src/store/ui/types"
import theme from "src/theme"
import { useUpgradeBannerStyles, useUpgradeButtonStyles, useUpgradeMessageStyles } from "./UpgradeBanner.styles"
import { useConnectorsNotifications } from "./useConnectorsNotifications"
import { useRelayNodeNotifications } from "./useRelayNodeNotifications"

const bannerStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

export type NotificationsParsedType = {
  status: (typeof bannerStatus)[keyof typeof bannerStatus]
  message: string
  latestVersion?: string
  version?: string
}

interface UpgradeMessageProps {
  showInfoIcon: boolean
  message: string
  subText?: string
}

const UpgradeMessage = ({ showInfoIcon, message, subText }: UpgradeMessageProps) => {
  const classes = useUpgradeMessageStyles()

  return (
    <div className={classes.root}>
      {showInfoIcon && <ZtnaIcon name="enInfo" size="lg" style={{ color: theme.color.neutralLight[16] }} />}
      <div>
        <p className={classes.text} dangerouslySetInnerHTML={{ __html: message }} />
        {subText && <p className={classes.subtext} dangerouslySetInnerHTML={{ __html: subText }} />}
      </div>
    </div>
  )
}

interface UpgradeButtonProps {
  title: string
  modalName: ModalNamesType
  callback?: () => void
  disabled?: boolean
  showTooltip?: boolean
}

const UpgradeButton = ({ title, modalName, callback, disabled, showTooltip }: UpgradeButtonProps) => {
  const classes = useUpgradeButtonStyles()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (!disabled) {
      callback?.()
      dispatch(openModal(modalName))
    }
  }

  return showTooltip ? (
    <ZtnaTooltip
      title="Please Look for the disclaimer icon next to proxy software version. Click to resolve the errors."
      arrow
      placement="bottom"
      cssPosition="absolute"
      enableTextWrap={false}
    >
      <ZtnaButton
        variant="text"
        title={title}
        buttonType="primary"
        className={classes.button}
        onClick={handleClick}
        disabled={disabled}
      />
    </ZtnaTooltip>
  ) : (
    <ZtnaButton
      variant="text"
      title={title}
      buttonType="primary"
      className={classes.button}
      onClick={handleClick}
      disabled={disabled}
    />
  )
}

interface UpgradeBannerProps extends NotificationsParsedType {
  type: "connector" | "relayNode" | "radsecProxy"
  currentBannerIndex?: number
  setCurrentBannerIndex?: React.Dispatch<React.SetStateAction<number>>
  role?: any
  bannerCount: number
}

const UpgradeBanner = ({
  type,
  status,
  message,
  currentBannerIndex = 0,
  setCurrentBannerIndex = () => {},
  bannerCount,
  role,
}: UpgradeBannerProps) => {
  const classes = useUpgradeBannerStyles()

  const {
    data: initiateProxy,
    isLoading,
    isValidating,
  } = useFetch({
    apiUrl: type === "radsecProxy" && role === Roles.CUSTOMER_SUPER_ADMIN ? RADSEC_PROXY_SHOULD_INITIATE : "",
  })

  const { getRelayNodeNotifications } = useRelayNodeNotifications({ shouldFetch: type === "relayNode" })
  const { getConnectorsNotifications } = useConnectorsNotifications({ shouldFetch: type === "connector" })

  const [isReadyToShow, setIsReadyToShow] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [subText, setSubText] = useState("")

  let buttonTitle = ""
  let callback = () => {}
  let modalName: ModalNamesType | null = null

  switch (status) {
    case "Upgrade Pending":
      buttonTitle = "Initiate Upgrade"
      modalName =
        type === "connector"
          ? "upgradeAllConnectors"
          : type === "radsecProxy"
          ? "upgradeRadsecProxyVersion"
          : "upgradeAllRelayNodes"
      break
    case "Upgrade Failed":
      buttonTitle = "Retry Upgrade"
      modalName = type === "connector" ? "retryConnectorUpgrade" : "retryRelayUpgrade"
      break
    default:
      buttonTitle = "View Progress"
      callback = type === "connector" ? getConnectorsNotifications : getRelayNodeNotifications
      modalName = type === "connector" ? "upgradeConnectorDetails" : "upgradeRelayNodeDetails"
      break
  }

  const shouldDisableButton = !initiateProxy?.shouldInitiate && type === "radsecProxy"
  const isUpgradePending = status === "Upgrade Pending"

  useEffect(() => {
    if (!isLoading && !isValidating) {
      const isProxyUpgradeInProgress = status === "Upgrade In-Progress" && type === "radsecProxy"

      setDisabled(isProxyUpgradeInProgress)
      setSubText(isProxyUpgradeInProgress ? "Please wait for the on-going upgrade to complete." : "")

      setIsReadyToShow(true)
    }
  }, [isLoading, isValidating, status, type, initiateProxy])

  if (!isReadyToShow) return null

  const handleBannerNavigation = (direction: "prev" | "next") => {
    setCurrentBannerIndex?.((prevIndex) =>
      direction === "prev"
        ? prevIndex > 0
          ? prevIndex - 1
          : bannerCount - 1
        : prevIndex < bannerCount - 1
        ? prevIndex + 1
        : 0,
    )
  }

  return (
    <div className={classes.rootBanner}>
      <div className={classes.bannerNav}>
        <ENButton
          variant="tertiary"
          data-testid="data-test-id"
          id="id"
          isDisabled={currentBannerIndex + 1 === 1}
          onClick={() => handleBannerNavigation("prev")}
        >
          <ENIconChevronLeft iconTitle="copy" size="lg" style={{ color: theme.color.neutralLight[16] }} />
        </ENButton>
        {currentBannerIndex + 1} / {bannerCount}
        <ENButton
          variant="tertiary"
          data-testid="data-test-id"
          id="id"
          isDisabled={currentBannerIndex + 1 === bannerCount}
          onClick={() => handleBannerNavigation("next")}
        >
          <ENIconChevronRight iconTitle="copy" size="lg" style={{ color: theme.color.neutralLight[16] }} />
        </ENButton>
      </div>
      <div className={classes.notification}>
        <UpgradeMessage message={message} showInfoIcon={isUpgradePending} subText={disabled ? subText : ""} />
        <UpgradeButton
          title={buttonTitle}
          modalName={modalName as ModalNamesType}
          callback={callback}
          disabled={shouldDisableButton}
          showTooltip={shouldDisableButton}
        />
      </div>
    </div>
  )
}

export default UpgradeBanner
