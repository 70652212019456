import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useDispatch } from "react-redux"
import ErrorContainer from "src/shared/components/ErrorContainer"
import Modal from "src/shared/components/Modal"
import { closeModal } from "src/store/ui"
import { endApplicationTroubleshooting } from "../ApplicationTroubleshooting.service"
import { useEndApplicationTroubleshootingStyles } from "./EndApplicationTroubleshooting.styles"

interface Props {
  evaluationId: string
  onCloseCallback: () => void
}

const EndApplicationTroubleshooting = ({ evaluationId, onCloseCallback }: Props) => {
  const classes = useEndApplicationTroubleshootingStyles()

  const dispatch = useDispatch()

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const handleYes = async () => {
    setError("")
    setIsLoading(true)
    try {
      await endApplicationTroubleshooting(evaluationId)
      dispatch(closeModal("endApplicationTroubleshooting"))
      onCloseCallback()
    } catch (err: any) {
      setIsLoading(false)
      setError(err?.message)
    }
  }

  return (
    <Modal
      title="End Application Troubleshooting"
      onOk={handleYes}
      onOkProps={{ title: "Yes", isLoading }}
      onCancel={() => dispatch(closeModal("endApplicationTroubleshooting"))}
      onCancelProps={{ title: "No", disabled: isLoading }}
      width={491}
    >
      <ENTextPassage>
        <p>Troubleshooting is in progress.</p>
      </ENTextPassage>

      <ENTextPassage>
        <p className={classes.confirmationText}>Would you like to cancel the current progress?</p>
      </ENTextPassage>

      <ErrorContainer message={error} open={!!error} handleClose={() => setError("")} />
    </Modal>
  )
}

export default EndApplicationTroubleshooting
