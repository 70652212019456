import { createUseStyles } from "react-jss"

export const useStepCircleStyles = createUseStyles((theme) => ({
  root: {
    borderRadius: "50%",
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1.5,
    borderStyle: "solid",
    fontWeight: 600,
    fontSize: 14,
    fontFamily: "Noto Sans",
  },
  initial: {
    borderColor: theme.color.content.secondary,
    color: theme.color.content.secondary,
  },
  current: {
    color: theme.color.content.inverseDefault,
    borderColor: theme.color.content.accentDefault,
    backgroundColor: theme.color.background.accentDefault,
  },
  complete: {
    borderColor: theme.color.content.accentDefault,
  },
}))
