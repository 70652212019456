import { CUSTOMER_ADMIN_DEVICE_POSTURE, TENANT_ADMIN_BASE_URL } from "src/constants"
import { API_URL, REQUEST_HEADERS } from "src/services"
import useSWR from "swr"

export type DevicePostureOsAndBrowserType = {
  id: string
  name: string
  version: string
  operator: string
  anyVersion: boolean
  isEnabled: boolean
}

export type DevicePostureType = {
  id: string
  matchingCriteria: string
  screenLock?: boolean
  status: string
  antivirus: boolean
  realtimeProtection: boolean
  patchLevel?: boolean
  operatingSystemCheck: DevicePostureOsAndBrowserType[]
  browserCheck: DevicePostureOsAndBrowserType[]
  frequency: number
  isBrowserEnabled: boolean
  isOsEnabled: boolean
}

type UseDevicePosturesResponseType = {
  data?: DevicePostureType
  isLoading: boolean
  error: any
  isValidating: boolean
  getDevicePosture: () => void
}

type UseDeivicePostureType = {
  shouldFetch?: boolean
  isEndUser?: boolean
}

const useDevicePosture = ({
  shouldFetch = false,
  isEndUser = false,
}: UseDeivicePostureType): UseDevicePosturesResponseType => {
  const url = shouldFetch
    ? isEndUser
      ? `${API_URL}${CUSTOMER_ADMIN_DEVICE_POSTURE}`
      : `${API_URL}${TENANT_ADMIN_BASE_URL}${CUSTOMER_ADMIN_DEVICE_POSTURE}`
    : null
  const { data, error, mutate, isValidating } = useSWR([url, REQUEST_HEADERS])

  const payload = data?.payload

  return {
    data: payload || undefined,
    isLoading: !error && !data,
    error,
    getDevicePosture: mutate,
    isValidating,
  }
}

export default useDevicePosture
