import { createUseStyles } from "react-jss"

export const useUpgradeBannerStyles = createUseStyles<string, any>((theme) => ({
  rootBanner: {
    width: 800,
    maxWidth: "95vw",
    zIndex: theme.zIndex.upgradeBanner,

    [theme.breakpoints.down("md")]: {
      maxHeight: 75,
      overflow: "hidden",
      overflowX: "auto",
      whiteSpace: "nowrap",
      maxWidth: "95vw",

      "scrollbar-width": "thin",
      "scrollbar-color": `${theme.color.content.tertiary} transparent`,
      "::-webkit-scrollbar": {
        height: 4,
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: theme.color.content.tertiary,
        borderRadius: 6,
      },
      "::-webkit-scrollbar-track": {
        background: "transparent",
      },
    },
    border: `1px solid ${theme.color.border.divider}`,
    borderRadius: 6,
    display: "flex",
    justifyContent: "space-between",
    height: "auto",
    minHeight: "auto",
    backgroundColor: theme.color.background.surfaceElevation1,
    alignItems: "center",
    animation: "$slideIn 0.6s, ease-in forwards",
  },
  bannerNav: {
    display: "flex",
    alignItems: "center",
  },
  notification: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "@keyframes slideIn": {
    "0%": {
      transform: "translateY(-100%)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0)",
      opacity: 1,
    },
  },

  arrowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 20,
  },
  arrowButton: {
    background: "none",
    border: "none",
    color: theme.color.primary,
    cursor: "pointer",
    fontSize: "1.5em",
    padding: "0 10px",
  },
}))

export const useUpgradeMessageStyles = createUseStyles((theme) => ({
  root: {
    gap: 20,
    margin: theme.spacing(1, 0),
    paddingLeft: 15,
    display: "flex",
    alignItems: "flex-start",
  },
  text: {
    color: theme.color.neutralDark[13],
    [theme.breakpoints.down("md")]: {
      maxHeight: 75,
      overflow: "auto",
    },
  },
  subtext: {
    color: theme.color.content.secondary,
    fontSize: 14,
  },
}))

export const useUpgradeButtonStyles = createUseStyles({
  button: {
    display: "flex",
    alignItems: "center",
    padding: 14,
    minWidth: "max-content",
  },
})
