import { useSelector } from "react-redux"
import { VERSIONS_CHECK } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { Roles } from "src/routes/config"
import ztnaAxios, { REQUEST_HEADERS } from "src/services"
import { RootState } from "src/store"
import { getCookie } from "src/utils"

export interface VersionsCheckResponseType {
  connectorVersion?: string | null
  relayVersion?: string | null
  radsecProxyVersion?: string | null
}

const fetcher = (url: string, headers: { [key: string]: string }): any => {
  const token = getCookie("csrftoken")
  const updatedHeaders: { [key: string]: any } = { ...REQUEST_HEADERS, ...headers }
  if (token) updatedHeaders["X-CSRFToken"] = token
  return ztnaAxios
    .post(url, null, {
      headers: updatedHeaders,
    })
    .then((res) => res.data)
}

const useRolloutUpgrade = () => {
  const role = useSelector((state: RootState) => state.auth.user.role)
  const { data: rolloutUpgradeData, mutate: getRolloutUpgrade } = useFetch<VersionsCheckResponseType>({
    apiUrl: role === Roles.SUPER_ADMIN ? VERSIONS_CHECK : null,
    options: {
      fetcher,
      refreshInterval: 15000,
    },
  })

  return { rolloutUpgradeData, getRolloutUpgrade }
}

export default useRolloutUpgrade
