import { ComponentType } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { RouterHOCTypes } from "./AppContainerErrorBoundary"

export const withRouter = (Component: ComponentType<any>) => {
  return function ComponentWithRouterProps(props: any) {
    const location = useLocation()
    const navigate = useNavigate() as RouterHOCTypes["navigate"]
    const params = useParams()
    const extendedPropsObject: RouterHOCTypes = { location, navigate, params }

    return <Component {...props} router={extendedPropsObject} />
  }
}
