import { API_URL, REQUEST_HEADERS } from "src/services"
import { ChartsTimeIntervalFilterType } from "src/utils"
import useSWR, { SWRConfiguration, SWRResponse } from "swr"

type UseResponseType<DataType> = {
  data?: DataType
  isLoading: boolean
  error: any
  isValidating: boolean
  mutate: SWRResponse["mutate"]
}

const useFetch = <PayloadType = any, DataType = PayloadType>({
  baseUrl = API_URL,
  apiUrl,
  options,
  parser,
  useBaseURL = true,
  timeFilterValue,
  headers = {},
  extraParserProps = {},
}: {
  baseUrl?: string
  apiUrl: string | null
  options?: SWRConfiguration
  extraParserProps?: any
  parser?: (data: PayloadType, extraParserProps?: any) => DataType
  useBaseURL?: boolean
  timeFilterValue?: ChartsTimeIntervalFilterType
  headers?: Record<string, string>
}): UseResponseType<DataType> => {
  const url = useBaseURL ? `${baseUrl}${apiUrl}` : apiUrl

  const { data, error, mutate, isValidating } = useSWR(
    apiUrl ? [url, { ...REQUEST_HEADERS, ...headers }, timeFilterValue] : null,
    options,
  )

  const payload = data?.payload || data

  const isLoading = !error && payload === undefined && !!apiUrl

  return {
    data:
      isLoading || !!error
        ? undefined
        : (parser && payload && parser(payload, extraParserProps)) || payload || undefined,
    isLoading,
    error,
    mutate,
    isValidating,
  }
}

export default useFetch
