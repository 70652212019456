import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthState, User } from "./types"

const initialState: AuthState = {
  user: {
    email: "",
    role: "tenant_end_user",
    name: "",
    sessionId: "",
    workspace: "",
    companyName: "",
    ssoUser: false,
    ownerId: null,
    workspaceData: null,
  },
  token: null,
  isLoading: false,
  error: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<User>) {
      state.user = payload
    },
    resetAuth: () => initialState,
  },
})

export const { resetAuth, setUser } = authSlice.actions

export default authSlice.reducer
