import { ColDef } from "ag-grid-community"
import clsx from "clsx"
import { ENChip } from "en-react/dist/src/components/Chip"
import { useSelector } from "react-redux"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { RootState } from "src/store"
import theme from "src/theme"
import { isInArray } from "src/utils"
import { CellRendererProps } from "../../DataGrid.types"
import { useBadgeWithIconCellStyles } from "./BadgeWithIconCell.styles"
interface RowDataType {
  [key: string]: any
}

type BadgeConfigType = {
  secondaryValues: string[]
  tertiaryValues: string[]
  successValues: string[]
  warningValues: string[]
  dangerValues: string[]
  infoValues: string[]
  primaryValues: string[]
  badgeTooltipKey?: string
  fallbackData?: string
}

const getChipVariant = (value: string, colDef: ColDef) => {
  const {
    secondaryValues = [],
    tertiaryValues = [],
    successValues = [],
    warningValues = [],
    dangerValues = [],
    infoValues = [],
    primaryValues = [],
  } = colDef?.cellRendererParams?.badgeConfig || ({} as BadgeConfigType)
  if (typeof value === "string") {
    if (isInArray(value, secondaryValues)) return "secondary"
    if (isInArray(value, tertiaryValues)) return "tertiary"
    if (isInArray(value, successValues)) return "green"
    if (isInArray(value, warningValues)) return "amber"
    if (isInArray(value, dangerValues)) return "red"
    if (isInArray(value, infoValues)) return "blue"
    if (isInArray(value, primaryValues)) return "purple"
  }
  return "secondary"
}

const BadgeWithIcons: React.FC<CellRendererProps> = ({ data, colDef }) => {
  const rowData = data as RowDataType
  const cellData = colDef.field && rowData[colDef.field]

  const openedModals = useSelector((state: RootState) => state.ui.openedModals)
  const isModalOpen = Object.keys(openedModals).length > 0
  const cssPositionStatus = isModalOpen ? "absolute" : "fixed"

  const classes = useBadgeWithIconCellStyles()

  const { cellRendererParams } = colDef
  const { badgeConfig, alignChild } = cellRendererParams
  const {
    badgeTooltipKey = "",
    iconTooltipKey = "",
    icon = "",
    iconColor = "",
    iconConfiguration,
    tooltipPlacement = "bottom",
    badgePaddingLeft = 0,
  } = badgeConfig

  return (
    <div className={classes.badgeWrapper} style={{ justifyContent: alignChild, paddingLeft: badgePaddingLeft }}>
      {badgeTooltipKey && rowData[badgeTooltipKey] ? (
        <>
          <ZtnaTooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{rowData[badgeTooltipKey as string]}</div>}
            placement={tooltipPlacement}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            cssPosition={cssPositionStatus}
          >
            <div>
              <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
            </div>
          </ZtnaTooltip>

          {(typeof iconConfiguration?.showIconValues(rowData) === "boolean"
            ? iconConfiguration.showIconValues(rowData)
            : isInArray(cellData, iconConfiguration?.showIconValues(rowData) || [])) &&
            iconConfiguration?.iconTooltipKey && (
              <ZtnaTooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
                <ZtnaButton
                  buttonType="tertiary"
                  className={clsx(classes.clickableBadgeIcon, {
                    [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                  })}
                  onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                  startIcon={
                    <ZtnaIcon
                      name={iconConfiguration?.iconName || "encircledInfo"}
                      color={theme.color.neutralDark[16]}
                    />
                  }
                />
              </ZtnaTooltip>
            )}
          {(typeof iconConfiguration?.showIconValues(rowData) === "boolean"
            ? iconConfiguration.showIconValues(rowData)
            : isInArray(cellData, iconConfiguration?.showIconValues(rowData) || [])) &&
            !iconConfiguration?.iconTooltipKey && (
              <ZtnaButton
                buttonType="tertiary"
                className={clsx(classes.clickableBadgeIcon, classes.clickableBadgeIconNoPadding, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                startIcon={
                  <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.color.neutralDark[16]} />
                }
              />
            )}
        </>
      ) : (
        <div className={classes.badgeWithoutTooltipWrapper}>
          <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
          {(typeof iconConfiguration?.showIconValues(rowData) === "boolean"
            ? iconConfiguration.showIconValues(rowData)
            : isInArray(cellData, iconConfiguration?.showIconValues(rowData) || [])) &&
            iconConfiguration?.iconTooltipKey && (
              <ZtnaTooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
                <ZtnaButton
                  buttonType="tertiary"
                  className={clsx(classes.clickableBadgeIcon, {
                    [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                  })}
                  onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                  startIcon={
                    <ZtnaIcon
                      name={iconConfiguration?.iconName || "encircledInfo"}
                      color={theme.color.neutralDark[16]}
                    />
                  }
                />
              </ZtnaTooltip>
            )}
          {(typeof iconConfiguration?.showIconValues(rowData) === "boolean"
            ? iconConfiguration.showIconValues(rowData)
            : isInArray(cellData, iconConfiguration?.showIconValues(rowData) || [])) &&
            !iconConfiguration?.iconTooltipKey && (
              <ZtnaButton
                buttonType="tertiary"
                className={clsx(classes.clickableBadgeIcon, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
                startIcon={
                  <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.color.neutralDark[16]} />
                }
              />
            )}
        </div>
      )}
      {icon && (
        <ZtnaTooltip
          title={rowData[iconTooltipKey] as string}
          placement="bottom"
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <div data-testid={`${icon}-icon`} className={classes.badgeIcon}>
            {iconTooltipKey && rowData[iconTooltipKey] && (
              <ZtnaIcon name={icon} width="15" height="15" color={iconColor} />
            )}
          </div>
        </ZtnaTooltip>
      )}
    </div>
  )
}

export default BadgeWithIcons
