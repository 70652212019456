export const MANAGE_DEVICES_ACCESS_NAC = "/cnac/network-policy-engine/nac_endsystem"

// ENDSYSTEMS
export const DASHBOARD_AUTHENTICATION_TYPE = "/cnac/endsystem-service/api/v1/endsystems/dashboard/authentication-types"
export const NETWORK_TOP_DEVICES_FAMILIES = "/cnac/endsystem-service/api/v1/endsystems/dashboard/top-device-families"
export const DASHBOARD_CONNECTION_AUTHENTICATION =
  "/cnac/endsystem-service/api/v1/endsystems/dashboard/authentication-trends"
export const DASHBOARD_CONNECTION_NETWORK = "/cnac/endsystem-service/api/v1/endsystems/dashboard/auth-state-trends"
export const NETWORK_EXPORT_ACCESS_CONNECT_CHART =
  "/cnac/endsystem-service/api/v1/endsystems/dashboard/network-access-summary"
export const CUSTOMER_ADMIN_POLICIES_TOP_CONDITIONS =
  "/cnac/endsystem-service/api/v1/endsystems/dashboard/top-policies-usage"

export const END_SYSTEMS = "/cnac/endsystem-service/api/v1/endsystems"

export const END_SYSTEMS_REAUTHENTICATE = "/cnac/network-policy-engine/api/v1/npe/external/deauth"
export const END_SYSTEMS_FILTER = "/cnac/endsystem-service/api/v1/endsystems/filter-by"

export const END_SYSTEMS_HISTORY = "/cnac/endsystem-service/api/v1/endsystems/history"

export const CUSTOMER_ADMIN_USERS_DEVICES = `/cnac/endsystem-service/api/v1/endsystems`
export const CUSTOMER_ADMIN_USERS_DEVICES_COUNT = `/cnac/endsystem-service/api/v1/endsystems/count-per-user`

// RADIUS SERVER
export const CUSTOMER_ADMIN_DEVICES = (device: "SWITCH" | "AP") =>
  `/cnac/network-policy-engine/api/v1/npe/devices/filters?deviceFunction=${device}`

//Network Devices
export const SYNC_DEVICE = `/cnac/network-policy-engine/api/v1/npe/resync`
export const ADD_NETWORK_DEVICE = `/cnac/network-policy-engine/api/v1/npe/devices/`
export const UPDATE_NETWORK_DEVICE = `/cnac/network-policy-engine/api/v1/npe/devices/update-non-xiq`
export const UPDATE_NETWORK_DEVICE_RADIUS_TEMPLATE = `/cnac/network-policy-engine/api/v1/npe/devices/update-devices-template`
export const REMOVE_NETWORK_DEVICE = `/cnac/network-policy-engine/api/v1/npe/devices/delete-by-serial-numbers/`
export const RESYNC_DEVICE = `/cnac/network-policy-engine/api/v1/npe/devices/resync-device/`
export const RESYNC_DEVICE_STATUS = `/cnac/network-policy-engine/api/v1/npe/devices/resync-device-status/`
export const FILTER_COLUMN_NETWORK_DEVICE = "/cnac/network-policy-engine/api/v1/npe/devices/filter-by-column"
export const FILTER_NETWORK_DEVICE = "/cnac/network-policy-engine/api/v1/npe/devices/filters"

// MONITOR
export const LICENSE_VALIDATE = "/cnac/license-service/api/v1/license/validate"
export const LICENSE_DETAIL = "/cnac/license-service/api/v1/license/detail"
