import { Logger, LogLevels } from "./types"
import { getTrackingData } from "./utils"

/**
 * Logger for use in local development
 * Prints everything to the browser console
 */
function consoleLog(
  level: LogLevels,
  eventName: string,
  data?: { [s: string]: any } | Error,
  tags?: { [s: string]: string },
) {
  const trackingData = getTrackingData({ level, data, tags })

  if (level === LogLevels.error) {
    console.error(`[${eventName}]: `, trackingData)
  } else if (level === LogLevels.warning) {
    console.warn(`[${eventName}]: `, trackingData)
  } else {
    console.log(`[${eventName}]: `, trackingData)
  }
}

const consoleLogger: Logger = {
  initialize: () => {},
  flush: () => {},
  fatal: consoleLog.bind(null, LogLevels.fatal),
  error: consoleLog.bind(null, LogLevels.error),
  warning: consoleLog.bind(null, LogLevels.warning),
  debug: consoleLog.bind(null, LogLevels.debug),
  info: consoleLog.bind(null, LogLevels.info),
}

export default consoleLogger
