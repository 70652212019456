type EllipsesParams = {
  text?: string
  count: number
}

export const addEllipsis = ({ text = "N/A", count }: EllipsesParams): string =>
  text.length <= count ? text : text.slice(0, count - 3) + "..."

export const getTransformedMacAddress = (macAddress: string) => {
  const cleanedMac = macAddress.replace(/[-.:]/g, "")

  return cleanedMac.replace(/(.{2})(?=.)/g, "$1:")
}
