import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { usePaperStyles } from "./Paper.styles"

export type PaperProps = {
  surface?: "transparent" | 0 | 1 | 2 | 3
  raised?: boolean
  padding?: number | [number, number]
  contentClass?: string
}

const Paper = (props: PropsWithChildren<PaperProps>) => {
  const { children, padding, contentClass, surface = 1, raised = true } = props
  const styles = usePaperStyles({ padding, surface, raised })

  return <div className={contentClass ? clsx(styles.root, contentClass) : styles.root}>{children}</div>
}

export default Paper
