import { createUseStyles } from "react-jss"

export const useENPaginatedSelectStyles = createUseStyles((theme) => ({
  noOptionText: {
    padding: "6px 10px",
    fontSize: 14,
  },
  creationBtn: {
    color: theme.color.content.accentDefault,
    fontWeight: 600,
  },
  editableOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  editableLabel: {
    width: 450,
  },

  creationButton: {
    width: "100%",
    minWidth: "100%",
    backgroundColor: theme.color.grey[400],
    marginTop: 0,
    boxShadow: "none",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    color: theme.color.primary[600],
    fontSize: 16,
    textAlign: "left",
    fontWeight: 600,
    padding: 0,
    cursor: "pointer",
  },
  popOverOpen: {
    transform: "rotate(180deg)",
    transition: "transform 0.15s ease-in-out",
  },

  // New styles
  // Loader
  loaderAnimation: {
    display: "inline-flex",
    animationName: "spin",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationTimingFunction: "cubic-bezier(0.42, 0, 0.44, 0.9)",
  },
  searchInputContainer: {
    padding: "calc(var(--size-base-unit)* 1)",
    border:
      "var(--en-theme-border-width) solid var(--en-dropdown-panel-border-color, var(--en-theme-color-border-default))",
    borderRadius: "var(--en-theme-border-radius) var(--en-theme-border-radius) 0 0",
    marginBlockEnd: -1,
    caretColor: "white",
  },
  selectContainer: {
    cursor: "not-allowed",
  },
  labelFocusedStyles: {
    backgroundColor: theme.color.background.surfaceElevation1,
    position: "absolute",
    zIndex: theme.zIndex.dropDownLabel,
    fontSize: 12,
    bottom: 35,
    marginLeft: 10,
    padding: "0px 5px",
  },
  selectContainerStyles: {
    position: "relative",
  },
}))
