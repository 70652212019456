import { Pagination, PaginationItem } from "@material-ui/lab"
import clsx from "clsx"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React, { useEffect, useState } from "react"
import { useDebounce } from "src/hooks"
import { ZtnaSelect } from "src/shared/components/FormComponents"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { useZtnaPaginationStyles } from "./ZtnaPagination.styles"

export const MIN_PAGE_LIMIT = 10

const ROWS_PER_PAGE_OPTIONS = [MIN_PAGE_LIMIT, 20, 30, 40, 50]

export type ZtnaPaginationType = {
  total: number
  limit: number
  offset: number
  callback: (newOffset: number, limit: number, page: number) => void
  isLoading?: boolean
  paginationWrapperClass?: string
  rowsPerPageOptions?: number[]
}

const ZtnaPagination: React.FC<ZtnaPaginationType> = (props) => {
  const classes = useZtnaPaginationStyles()
  const { total, limit, offset, callback, isLoading, paginationWrapperClass, rowsPerPageOptions } = props
  const count = Math.ceil(total / limit)
  const showingFrom = offset + 1
  const showingTo = Math.min(offset + limit, total)

  const [pageNo, setPageNo] = useState(offset / limit + 1)
  const [jumpPageNo, setJumpPageNo] = useState<number | string>(pageNo)
  const [localPageInputValue, setLocalPageInputValue] = useState<number | string>("")
  useEffect(() => {
    if (offset === 0 && pageNo !== 1) {
      setPageNo(1)
      setJumpPageNo(1)
    } else {
      setPageNo(Math.ceil((offset + 1) / limit))
      setJumpPageNo(Math.ceil((offset + 1) / limit))
    }
  }, [offset])

  useEffect(() => {
    setLocalPageInputValue(jumpPageNo)
  }, [jumpPageNo])

  const goToPage = (page: number, updatedLimit?: number) => {
    const newPage = page || 1
    const newLimit = updatedLimit || limit
    const newOffset = (newPage - 1) * newLimit
    callback && callback(newOffset, newLimit, newPage)
    setPageNo(newPage)
    setJumpPageNo(newPage)
  }
  const handleJumpToPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/
    const page = e.target.value
    if (page === "" || (re.test(page) && Number(page) <= count)) {
      setLocalPageInputValue(page)
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setJumpPageNo(localPageInputValue)
    }
  }

  const debouncedPageNo = useDebounce(jumpPageNo)

  useEffect(() => {
    debouncedPageNo && goToPage(Number(debouncedPageNo))
  }, [debouncedPageNo])

  const handleRowsPerPage = (value: any) => {
    goToPage(1, parseInt(value))
  }

  return (
    <div className={clsx(classes.root, paginationWrapperClass)}>
      <div className={classes.paginationContainer}>
        <ZtnaButton
          buttonType="tertiary"
          data-testid="leftFeatherChevron-btton"
          onClick={() => goToPage(1)}
          disabled={pageNo === 1 || isLoading}
          className={classes.icon}
          startIcon={<ZtnaIcon name="leftFeatherChevron" isDisabled={pageNo === 1 || isLoading} />}
        />

        <Pagination
          count={count}
          page={pageNo}
          siblingCount={0}
          boundaryCount={2}
          disabled={isLoading}
          color="primary"
          shape="rounded"
          size="small"
          onChange={(_, page) => {
            goToPage(page)
          }}
          classes={{ ul: classes.paginationIcon }}
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.paginationItem,
              }}
              {...item}
              selected={item.page === pageNo}
            />
          )}
        />

        <ZtnaButton
          buttonType="tertiary"
          data-testid="rightFeatherChevron-button"
          onClick={() => goToPage(count)}
          className={classes.icon}
          disabled={pageNo === count || isLoading}
          startIcon={<ZtnaIcon name="rightFeatherChevron" isDisabled={pageNo === count || isLoading} />}
        />
      </div>
      <ENDivider variant="vertical" className={classes.divider} />
      <div className={classes.actionContainer}>
        <ENTextPassage>
          <span className={classes.paginationText}>Jump to page</span>
        </ENTextPassage>
        <input
          data-testid="current-page-number"
          type="text"
          value={localPageInputValue}
          className={classes.input}
          onChange={handleJumpToPage}
          disabled={isLoading}
          onKeyDown={handleEnterKeyDown}
        />
      </div>
      <ENDivider variant="vertical" className={classes.divider} />
      <div className={classes.actionContainer}>
        <ENTextPassage>
          <span className={classes.paginationText}>Rows Per Page</span>
        </ENTextPassage>
        <ZtnaSelect
          className={classes.select}
          isSearchable={false}
          value={limit}
          options={(rowsPerPageOptions && rowsPerPageOptions.length ? rowsPerPageOptions : ROWS_PER_PAGE_OPTIONS).map(
            (item) => ({ label: item, value: item }),
          )}
          onChange={handleRowsPerPage}
          disabled={isLoading}
          minSelectHeight={22}
        />
      </div>
      <ENDivider variant="vertical" className={classes.divider} />
      <div>
        <ENTextPassage>
          <span className={classes.paginationText}>{`${showingFrom}-${showingTo} out of ${total}`}</span>
        </ENTextPassage>
      </div>
    </div>
  )
}

export default ZtnaPagination
