import { createUseStyles } from "react-jss"

export const useRetryRelayUpgradeStyles = createUseStyles((theme) => ({
  contentClass: {
    minHeight: 150,
  },
  disclaimer: {
    display: "flex",
    alignItems: "center",
    gap: 14,
    padding: 5,
    borderRadius: 10,
    backgroundColor: theme.color.neutralLight[16],
    boxShadow: "0px 8px 18px rgba(0, 0, 0, 0.12)",
    height: 66,
    marginBottom: 20,
  },
  disclaimerText: {
    marginTop: 20,
  },
  infoCircleIconContainer: {
    backgroundColor: "#0A78FF",
    borderRadius: "4px 0px 0px 4px",
    height: 63,
    width: 53,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))
