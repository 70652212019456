import { Roles } from "src/routes/config"
import { keysToCapitialize } from "src/utils"
import ztnaAxios from ".."
import { Logger, LoggerInitializePayload, LogLevels } from "./types"
import { getTrackingData } from "./utils"

/**
 * An array of strings or regexps that'll be used to ignore specific errors based on their type/message
 */
const IGNORE_ERRORS: (string | RegExp)[] = []

/**
 * This is used to filter out error BEFORE being sent to any logging service
 * @param error
 */
function shouldIgnoreError(error: string | { [s: string]: any } | Error): boolean {
  // Filter out Axios cancel errors
  if (ztnaAxios.isCancel(error)) {
    return true
  }

  if (typeof error === "string") {
    return IGNORE_ERRORS.some((matcher) => error.match(matcher))
  }

  if (typeof error?.name === "string") {
    if (IGNORE_ERRORS.some((matcher) => error.name.match(matcher))) {
      return true
    }
  }

  if (typeof error?.message === "string") {
    if (IGNORE_ERRORS.some((matcher) => error.message.match(matcher))) {
      return true
    }
  }

  return false
}

const initialize = (payload?: LoggerInitializePayload): void => {
  if (payload && payload.visitor?.role !== Roles.END_USER) {
    pendo?.initialize({
      visitor: payload.visitor,
      account: payload.account,
    })

    pendo?.identify(payload.visitor?.id, payload?.account.id)
  }
}

const flush = (): void => {
  pendo?.flushNow()
}

/**
 * Logging function wraps the message with relevant
 * context and posts it to Pendo
 */
function pendoLog(
  level: LogLevels,
  eventName?: string,
  data?: { [s: string]: any } | Error,
  tags?: { [s: string]: string },
) {
  if ([LogLevels.debug].includes(level)) {
    return
  }
  if (data && shouldIgnoreError(data)) {
    return
  }

  const trackingData = getTrackingData({ level, data, tags })
  if (pendo?.track) {
    pendo.track(eventName, keysToCapitialize(trackingData))
  } else {
    console.error("PENDO is Blocked")
  }
}

// Configurable logger module to push events to Pendo
const pendoLogger: Logger = {
  initialize,
  flush,
  fatal: pendoLog.bind(null, LogLevels.fatal),
  error: pendoLog.bind(null, LogLevels.error),
  warning: pendoLog.bind(null, LogLevels.warning),
  debug: pendoLog.bind(null, LogLevels.debug),
  info: pendoLog.bind(null, LogLevels.info),
}

export default pendoLogger
