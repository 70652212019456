import { createUseStyles } from "react-jss"
import { CLOSED_DRAWER_WIDTH } from "src/utils/constants"

export const useBreadcrumbsStyles = createUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gridArea: "Banner",
  },
  navMargin: {
    marginLeft: `calc(${theme.spacing(4)} + ${CLOSED_DRAWER_WIDTH}px)`,
    transition: "margin 0.5s",
  },
  nodeRoot: {
    display: "flex",
  },
  node: {
    fontSize: 26,
    color: theme.color.grey[800],
    textDecorationLine: "none",
  },
  link: {
    color: theme.color.content.secondary,
    textDecorationLine: "none",
    cursor: "pointer",
  },
  active: {
    fontWeight: 600,
    color: theme.color.grey[300],
  },
  separator: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontSize: 20,
  },
  badgeWrapper: {
    marginLeft: 25.8,
  },
  badge: {
    height: 29,
    borderRadius: 3,
    color: theme.color.secondary[600],
    backgroundColor: theme.color.tertiary[300],
  },
  success: {
    color: theme.color.success.main,
    backgroundColor: "#eff8ed",
  },
  danger: {
    color: theme.color.error.main,
    backgroundColor: "#fdecea",
  },
  secondaryText: {
    marginLeft: 16,
    marginTop: 7,
    fontSize: 16,
    fontWeight: 400,
    fontStyle: "italic",
    width: "max-content",
    color: theme.color.tertiary[600],
  },
}))
