import { ENDropdown } from "en-react/dist/src/components/Dropdown"
import { ENList } from "en-react/dist/src/components/List"
import { ComponentType, useMemo } from "react"
import { ActionMeta, GroupBase, Options } from "react-select"
import { GenericObject } from "src/utils/commonTypes"

interface ZtnaMultiSelectProps {
  id: string
  label: string
  value: GenericObject | readonly GenericObject[] | null | undefined
  CustomOption?: any
  onChange?: ((value: Options<GenericObject>, action: ActionMeta<GenericObject>) => void) | undefined
  loadOptions?: (
    inputValue: string,
    callback: (options: readonly (GenericObject | GroupBase<GenericObject>)[]) => void,
  ) => void | Promise<readonly (GenericObject | GroupBase<any>)[]>
  options?: any[]
  variant?: "User" | "Device" | "Network"
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  ValueContainer?: ComponentType<any>
  error?: any
  isSearchable?: boolean
  fieldNote?: string
}

const ZtnaMultiSelect = ({
  id,
  label,
  value,
  CustomOption,
  options = [],
  placeholder,
  disabled,
  isLoading,
  error,
  isSearchable = false,
  fieldNote = "",
}: ZtnaMultiSelectProps) => {
  const values = useMemo(
    () => (value?.length ? `(${value.length}) ${value?.length === 1 ? label : label} selected` : fieldNote),
    [value, fieldNote],
  )

  return (
    <ENDropdown
      id={id}
      label={label}
      value="Select"
      isDisabled={disabled || isLoading}
      fieldNote={values}
      placeholder={placeholder}
      isError={error}
      errorNote={error?.message}
      hasSearch={true}
      // isLoading={isLoading}
    >
      <ENList>
        {/* <Virtuoso
          data={options}
          totalCount={options.length}
          style={!!options.length ? { height: "calc(var(--size-base-unit) * 17)" } : undefined}
          itemContent={(index: number, option: any) => {
            console.log("🚀 ~ index:", index)
            console.log("🚀 ~ option:", option)
            return <CustomOption key={index?.toString()} data={option} />
          }}
        /> */}
        {options?.map((option) => (
          <CustomOption key={option?.id?.toString()} data={option} />
        ))}
      </ENList>
    </ENDropdown>
  )
}

export default ZtnaMultiSelect
