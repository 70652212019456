import { createUseStyles } from "react-jss"

export const useLoadingSlateStyles = createUseStyles((theme) => ({
  loadingContainer: {
    width: 370,
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 0),
    gap: theme.spacing(3),

    "& div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),

      "& p": {
        color: theme.color.content.secondary,
        textAlign: "center",
      },
    },
  },
}))
