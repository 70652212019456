import theme from "src/theme"

export const LeftFeatherChevronIcon: React.FC<{ isDisabled?: boolean }> = ({ isDisabled = false }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.129" height="14.258" viewBox="0 0 12.129 14.258">
    <g data-name="Group 5315">
      <path
        data-name="Icon feather-chevron-left"
        d="M19.215 21.43 13.5 15.715 19.215 10"
        transform="translate(-8.5 -8.586)"
        style={{
          stroke: isDisabled ? "#DDDEE2" : theme.color.primary[600],
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 2,
          fill: "none",
        }}
      />
      <g data-name="Path 3603" style={{ fill: "none" }}>
        <path
          d="M1 2a1 1 0 0 1 1 1v10a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1z"
          style={{ stroke: "none" }}
          transform="translate(0 -1.086)"
        />
        <path
          d="M1 3v10l.001-10H1m0-1a1 1 0 0 1 1 1v10a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1z"
          style={{ fill: isDisabled ? "#DDDEE2" : theme.color.primary[600], stroke: "none" }}
          transform="translate(0 -1.086)"
        />
      </g>
    </g>
  </svg>
)

export const LinkIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = theme.color.background.inverseEmphasis,
  width = "16",
  height = "16",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.667 2H14.0003V5.33333"
      stroke={theme.color.neutralDark[13]}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33301 6.66667L13.9997 2"
      stroke={theme.color.neutralDark[13]}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 9.33334V12.6667C12.6667 13.4033 12.07 14 11.3333 14H3.33333C2.59667 14 2 13.4033 2 12.6667V4.66667C2 3.93 2.59667 3.33334 3.33333 3.33334H6.66667"
      stroke={theme.color.neutralDark[13]}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const LocalAccountIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
    <g id="Group_4266" data-name="Group 4266" transform="translate(414.996 -329.005)">
      <path
        id="Path_2548"
        data-name="Path 2548"
        fill={theme.color.grey[900]}
        d="M-402.556 354A12.505 12.505 0 0 1-415 341.463a12.5 12.5 0 0 1 12.6-12.458A12.506 12.506 0 0 1-390 341.58 12.512 12.512 0 0 1-402.556 354zm7.737-4.938a10.712 10.712 0 0 0 0-15.194 10.806 10.806 0 0 0-15.9.59c-4.231 4.96-2.79 11.662.582 14.594 3.704-3.305 11.318-3.5 15.318.015z"
      />
      <path
        id="Path_2549"
        data-name="Path 2549"
        fill={theme.color.grey[900]}
        d="M-351 374.508a5.068 5.068 0 0 1-5.028 5.114 5.065 5.065 0 0 1-4.971-5.1 5.057 5.057 0 0 1 5.026-5.11 5.061 5.061 0 0 1 4.973 5.096z"
        transform="translate(-46.499 -34.795)"
      />
    </g>
  </svg>
)

export const LockIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.9993 15.999H33.9991V11.999C33.9991 6.47901 29.5193 1.99902 23.9992 1.99902C18.4792 1.99902 13.9992 6.47901 13.9992 11.999V15.999H11.9992C9.79922 15.999 7.99922 17.799 7.99922 19.999V39.9989C7.99922 42.199 9.79922 43.999 11.9992 43.999H35.9993C38.1991 43.999 39.9991 42.199 39.9991 39.9989V19.999C39.9991 17.799 38.1991 15.999 35.9993 15.999ZM17.7992 11.999C17.7992 8.57901 20.5792 5.79902 23.9992 5.79902C27.4193 5.79902 30.1992 8.57901 30.1992 11.999V15.999H17.7992V11.999ZM35.9993 39.9989H11.9992V19.999H35.9993V39.9989Z"
      fill={theme.color.grey.A200}
    />
    <circle cx="24" cy="27" r="4" fill={theme.color.grey.A200} />
    <rect x="22" y="28" width="4" height="6" fill={theme.color.grey.A200} />
  </svg>
)

export const LogoutIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = "currentColor",
  width = "10.417",
  height = "10.384",
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10.417 10.384">
    <g id="logout" transform="translate(.25 -.6)">
      <g id="Group_487" transform="translate(0 .85)">
        <g id="Group_486">
          <path
            id="Path_37"
            d="M4.942 9.911H1.236A.412.412 0 0 1 .824 9.5V2.086a.412.412 0 0 1 .412-.412h3.706a.412.412 0 0 0 0-.824H1.236A1.237 1.237 0 0 0 0 2.086V9.5a1.237 1.237 0 0 0 1.236 1.236h3.706a.412.412 0 1 0 0-.824z"
            transform="translate(0 -.85)"
            fill={color}
            stroke={color}
            strokeWidth=".5px"
          />
        </g>
      </g>
      <g id="Group_489" transform="translate(3.295 2.909)">
        <g id="Group_488">
          <path
            id="Path_38"
            d="M176.6 109.754l-2.5-2.471a.412.412 0 0 0-.578.586l1.789 1.766h-4.8a.412.412 0 0 0 0 .824h4.8l-1.789 1.766a.412.412 0 1 0 .578.586l2.5-2.471a.412.412 0 0 0 0-.586z"
            transform="translate(-170.1 -107.165)"
            fill={color}
            stroke={color}
            strokeWidth=".5px"
          />
        </g>
      </g>
    </g>
  </svg>
)
export const LetterEnvelopeIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = theme.color.primary[600],
  width = "21.417",
  height = "21.384",
}): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.664 0.334961H2.33073C1.41406 0.334961 0.672392 1.08496 0.672392 2.00163L0.664062 12.0016C0.664062 12.9183 1.41406 13.6683 2.33073 13.6683H15.664C16.5807 13.6683 17.3307 12.9183 17.3307 12.0016V2.00163C17.3307 1.08496 16.5807 0.334961 15.664 0.334961ZM14.8307 12.0016H3.16406C2.70573 12.0016 2.33073 11.6266 2.33073 11.1683V3.66829L8.11406 7.285C8.65573 7.6266 9.33902 7.6266 9.88072 7.285L15.664 3.66829V11.1683C15.664 11.6266 15.289 12.0016 14.8307 12.0016ZM8.99739 6.16829L2.33073 2.00163H15.664L8.99739 6.16829Z"
      fill={color}
    />
  </svg>
)

export const LocationIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = "currentColor",
  width = "26",
  height = "25",
}): JSX.Element => (
  <svg width="26" height="25" viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0001 2.08374C8.96892 2.08374 5.7085 5.34417 5.7085 9.37542C5.7085 14.8441 13.0001 22.9171 13.0001 22.9171C13.0001 22.9171 20.2919 14.8441 20.2919 9.37542C20.2919 5.34417 17.0314 2.08374 13.0001 2.08374ZM13.0001 11.9796C11.5627 11.9796 10.396 10.8129 10.396 9.37542C10.396 7.93792 11.5627 6.77124 13.0001 6.77124C14.4376 6.77124 15.6044 7.93792 15.6044 9.37542C15.6044 10.8129 14.4376 11.9796 13.0001 11.9796Z"
      fill={color}
    />
  </svg>
)

export const LocationOutlineIcon: React.FC<{ color?: string; width?: string; height?: string }> = ({
  color = "currentColor",
  width = "26",
  height = "25",
}): JSX.Element => (
  <svg width="22" height="23" viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.27148 9.18565C5.27148 6.02151 7.83651 3.45648 11.0007 3.45648C14.1648 3.45648 16.7298 6.02151 16.7298 9.18565C16.7298 11.3292 15.2749 13.6846 13.6815 15.5911C12.901 16.5249 12.1186 17.3144 11.5307 17.8709C11.3275 18.0632 11.1481 18.2271 11.0007 18.359C10.8532 18.2271 10.6738 18.0632 10.4706 17.8709C9.88267 17.3144 9.10028 16.5249 8.31982 15.5911C6.72636 13.6846 5.27148 11.3292 5.27148 9.18565ZM10.564 19.8C10.564 19.8 10.5643 19.8002 11.0007 19.269L11.437 19.8002C11.1834 20.0086 10.8176 20.0083 10.564 19.8ZM10.564 19.8L11.0007 19.269C11.437 19.8002 11.4378 19.7996 11.4378 19.7996L11.4391 19.7985L11.4436 19.7948L11.4592 19.7818C11.4727 19.7706 11.492 19.7544 11.5169 19.7334C11.5666 19.6913 11.6384 19.6299 11.7291 19.5505C11.9105 19.3918 12.1678 19.1611 12.4758 18.8695C13.091 18.2873 13.9128 17.4584 14.7365 16.4729C16.3514 14.5408 18.1048 11.8546 18.1048 9.18565C18.1048 5.26212 14.9242 2.08148 11.0007 2.08148C7.07712 2.08148 3.89648 5.26212 3.89648 9.18565C3.89648 11.8546 5.64994 14.5408 7.26481 16.4729C8.08852 17.4584 8.91029 18.2873 9.52547 18.8695C9.8335 19.1611 10.0908 19.3918 10.2722 19.5505C10.3629 19.6299 10.4347 19.6913 10.4844 19.7334C10.5093 19.7544 10.5286 19.7706 10.5421 19.7818L10.5577 19.7948L10.5622 19.7985L10.564 19.8ZM8.93815 9.18565C8.93815 8.04643 9.86143 7.12315 11.0007 7.12315C12.1399 7.12315 13.0632 8.04643 13.0632 9.18565C13.0632 10.3249 12.1399 11.2481 11.0007 11.2481C9.86143 11.2481 8.93815 10.3249 8.93815 9.18565ZM7.56315 9.18565C7.56315 7.28704 9.10204 5.74815 11.0007 5.74815C12.8993 5.74815 14.4382 7.28704 14.4382 9.18565C14.4382 11.0843 12.8993 12.6231 11.0007 12.6231C9.10204 12.6231 7.56315 11.0843 7.56315 9.18565Z"
      fill={color}
    />
  </svg>
)
