type RoleKeyType = keyof typeof Roles
export type RoleValueType = (typeof Roles)[RoleKeyType]

export const ProfileRoles = {
  TENANT_END_USER: "tenant_end_user",
  ZTNA_SUPER_ADMIN: "ztna_super_admin",
  TENANT_SUPER_ADMIN: "tenant_super_admin",
  TENANT_MONITOR_ADMIN: "tenant_monitor_admin",
  TENANT_OBSERVER_ADMIN: "tenant_observer_admin",
} as const

export const Roles = {
  SUPER_ADMIN: "ztna_super_admin",
  CUSTOMER_SUPER_ADMIN: "tenant_super_admin",
  CUSTOMER_MONITOR_ADMIN: "tenant_monitor_admin",
  CUSTOMER_OBSERVER_ADMIN: "tenant_observer_admin",
  END_USER: "tenant_end_user",
} as const

export const RolesNameMapping = {
  [Roles.SUPER_ADMIN]: "Ztna Super Admin",
  [Roles.CUSTOMER_SUPER_ADMIN]: "Super Admin",
  [Roles.CUSTOMER_MONITOR_ADMIN]: "Monitor Admin",
  [Roles.CUSTOMER_OBSERVER_ADMIN]: "Observer Admin",
  [Roles.END_USER]: "End User",
} as const

export const ALL_CUSTOMER_ROLES = [
  Roles.CUSTOMER_SUPER_ADMIN,
  Roles.CUSTOMER_MONITOR_ADMIN,
  Roles.CUSTOMER_OBSERVER_ADMIN,
]

export const isTenantRole = (role: RoleValueType) => {
  return (
    role === Roles.CUSTOMER_SUPER_ADMIN ||
    role === Roles.CUSTOMER_MONITOR_ADMIN ||
    role === Roles.CUSTOMER_OBSERVER_ADMIN
  )
}
