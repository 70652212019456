import theme from "src/theme"

export const HamburgerIcon: React.FC<{ color?: string }> = ({ color = theme.color.neutralLight[16] }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.768" height="15.845" viewBox="0 0 23.768 15.845">
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 24.845h23.768V22.2H4.5zm0-6.6h23.768V15.6H4.5zM4.5 9v2.641h23.768V9z"
      transform="translate(-4.5 -9)"
    />
  </svg>
)

export const HomeIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.632" height="13.506" viewBox="0 0 14.632 13.506">
    <path
      fill="currentColor"
      d="M9 18.006V13.5h3.377V18h3.433v-6.747h2.195L10.691 4.5l-7.316 6.753H5.57v6.753z"
      transform="translate(-3.375 -4.5)"
    />
  </svg>
)

export const HostsStatIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35.99" height="35.99" viewBox="0 0 35.99 35.99">
    <g id="Group_3879" transform="translate(-1569.83 -194.682)">
      <circle
        id="Ellipse_55"
        cx="17.995"
        cy="17.995"
        r="17.995"
        fill={theme.color.primary[600]}
        opacity="0.14"
        transform="translate(1569.83 194.682)"
      />
      <g id="Group_3877">
        <g id="Group_3876" transform="translate(2297.555 -365.705)">
          <path
            id="Path_2456"
            d="M-709.714 653.15h-9.958c-.82 0-1.045-.22-1.046-1.032v-4.241c0-.752.25-1 .99-1h20.054c.718 0 .966.252.967.97v4.287c0 .79-.226 1.02-1.006 1.02zm7.172-1.656a1.514 1.514 0 0 0 1.526-1.508 1.5 1.5 0 0 0-1.531-1.5 1.494 1.494 0 0 0-1.5 1.485 1.487 1.487 0 0 0 1.505 1.523zm-14.834.069v-3.189h-1.184v3.189zm1.552 0h1.173v-3.2h-1.173z"
            transform="translate(-.019 -68.224)"
            fill={theme.color.primary[600]}
          />
          <path
            id="Path_2457"
            d="M-709.694 571.717h9.958c.792 0 1.012.22 1.013 1.008v4.333c0 .665-.262.934-.913.934h-20.192c-.627 0-.9-.268-.9-.9q-.012-2.236 0-4.472c0-.655.267-.9.943-.9q5.043-.005 10.091-.003zm7.128 4.62a1.532 1.532 0 0 0 1.531-1.512 1.517 1.517 0 0 0-1.527-1.513 1.512 1.512 0 0 0-1.5 1.492 1.5 1.5 0 0 0 1.496 1.533zm-16.011-3.126v3.189h1.181v-3.194zm3.909.014h-1.164v3.17h1.164z"
            fill={theme.color.primary[600]}
          />
        </g>
      </g>
    </g>
  </svg>
)
