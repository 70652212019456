import { SETUP_WORKSPACE_URL } from "src/constants"
import { patchRequest } from "src/services"

type SecureTunnelConfigurationType = {
  type: string
}

export const updateSecureTunnel = async ({ type }: SecureTunnelConfigurationType): Promise<void> => {
  const data = {
    tunnel_type: type,
  }
  await patchRequest(SETUP_WORKSPACE_URL, data)
}
