import clsx from "clsx"
import { useDialogActionsStyles } from "./DialogActions.styles"

const DialogActions: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  const classes = useDialogActionsStyles()

  return <div className={clsx(classes.dialogActionsWrapper, className)}>{children}</div>
}

export default DialogActions
