import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Component, ErrorInfo, ReactNode } from "react"
import { connect } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import { RouteWithoutComponentType } from "src/routes/config"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { setToasterState } from "src/store/ui/uiSlice"
import { UNABLE_TO_HANDLE_DATA } from "src/utils/constants"
import { withRouter } from "./withRouter"

export type RouterHOCTypes = {
  location: ReturnType<typeof useLocation>
  params: ReturnType<typeof useParams>
  navigate: (to: string | number, options?: { replace: boolean }) => void
}

interface Props {
  children: ReactNode | ReactNode[]
  router: RouterHOCTypes
  currentRoute?: RouteWithoutComponentType
  setToasterState?: typeof setToasterState
}

interface State {
  hasError: boolean
}

const mapDispatchToProps = { setToasterState }

class AppContainerErrorBoundaries extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  shouldComponentUpdate(nextProps: Props) {
    return nextProps.router.location.pathname !== this.props.router.location.pathname
  }

  goBack = () => {
    const {
      router: { navigate },
    } = this.props
    this.setState({ hasError: false })
    navigate(-1, { replace: true })
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log("Error:", error)
    console.log("Error Info", errorInfo)
    this.props?.setToasterState?.({
      autoHide: false,
      type: "danger",
      message: UNABLE_TO_HANDLE_DATA,
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError === true) {
      return (
        <div className="error-boundary-root">
          <ENTextPassage className="text-align">
            <h3>Please visit any other menu and refresh the application.</h3>
            <div className="button-container">
              <ZtnaButton title="Go Back" startIcon={<ZtnaIcon name="enChevronLeft" />} onClick={this.goBack} />
            </div>
          </ENTextPassage>
        </div>
      )
    }
    return children
  }
}

export default connect(undefined, mapDispatchToProps)(withRouter(AppContainerErrorBoundaries))
