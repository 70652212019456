import { createUseStyles } from "react-jss"

const useRoutesStyles = createUseStyles({
  loader: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "center",
  },
})

export default useRoutesStyles
