import { SERVICE_CONNECTOR_NOTIFICATIONS } from "src/constants"
import useFetch from "src/hooks/useFetch"
import { NotificationsParsedType } from "./UpgradeBanner"

const mapStatus = {
  UPGRADE_PENDING: "Upgrade Pending",
  UPGRADE_IN_PROGRESS: "Upgrade In-Progress",
  UPGRADE_FAILED: "Upgrade Failed",
} as const

type ConnectorsNotificationsResponseType = {
  status: keyof typeof mapStatus
  message: string
  aboutToExpire: boolean
  instanceUpgradeFailedMessage?: { errMsg: string } | null
  instanceName?: string | null
  hostName?: string | null
}

type ConnectorsNotificationsParsedType = NotificationsParsedType & {
  instanceUpgradeFailedMessage: string
  instanceName: string
  hostName: string
}

type UseConnectorsNotifications = {
  shouldFetch?: boolean
}

const parser = (response: ConnectorsNotificationsResponseType): ConnectorsNotificationsParsedType => ({
  ...response,
  status: mapStatus[response?.status],
  instanceUpgradeFailedMessage: response?.instanceUpgradeFailedMessage?.errMsg || "",
  instanceName: response?.instanceName || "",
  hostName: response?.hostName || "",
})

export const useConnectorsNotifications = ({ shouldFetch }: UseConnectorsNotifications) => {
  const { data, mutate } = useFetch({
    apiUrl: shouldFetch ? SERVICE_CONNECTOR_NOTIFICATIONS : null,
    options: { refreshInterval: 10000 },
    parser,
  })

  return { connectorsNotificationsData: data, getConnectorsNotifications: mutate }
}
