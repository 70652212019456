import { yupResolver } from "@hookform/resolvers/yup"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { rolloutUpgradeValidation } from "src/craas/utils/validations"
import ErrorContainer from "src/shared/components/ErrorContainer"
import { CheckboxInput } from "src/shared/components/FormComponents"
import Modal from "src/shared/components/Modal"
import { closeModal } from "src/store/ui"
import { SWRResponse } from "swr"
import { rolloutUpgrade } from "./RolloutUpgrade.service"
import { useRolloutUpgradeStyles } from "./RolloutUpgrade.styles"
import { VersionsCheckResponseType } from "./useRolloutUpgrade"

interface RolloutUpgradeProps extends VersionsCheckResponseType {
  getRolloutUpgrade: SWRResponse["mutate"]
}

const RolloutUpgrade = ({ connectorVersion, radsecProxyVersion, getRolloutUpgrade }: RolloutUpgradeProps) => {
  const dispatch = useDispatch()
  const classes = useRolloutUpgradeStyles()

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const onClose = () => dispatch(closeModal("rolloutUpgrade"))

  const {
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    defaultValues: {
      connectorVersionCheck: false,
      proxyVersionCheck: false,
    },
    mode: "onChange",
    resolver: yupResolver(rolloutUpgradeValidation),
  })

  const { connectorVersionCheck, proxyVersionCheck } = watch()

  const onSubmit = async () => {
    try {
      setError("")
      setIsLoading(true)
      await rolloutUpgrade({
        ...(connectorVersionCheck ? { connectorVersion } : {}),
        ...(proxyVersionCheck ? { radsecProxyVersion } : {}),
      })
      getRolloutUpgrade()
      onClose()
    } catch (error: any) {
      setIsLoading(false)
      setError(error?.message)
    }
  }

  return (
    <Modal
      title="Rollout Upgrade"
      onOk={connectorVersion || radsecProxyVersion ? onSubmit : onClose}
      onOkProps={{
        title: connectorVersion || radsecProxyVersion ? "Rollout" : "Close",
        isLoading,
        disabled: !isValid || !isDirty || isLoading,
      }}
      onCancel={onClose}
      onCancelProps={{
        disabled: isLoading,
        visible: !!connectorVersion || !!radsecProxyVersion,
      }}
      contentClass={classes.root}
      disableMinHeight
      width="600px"
    >
      <ENTextPassage>
        <p className={classes.textWrapper}>
          New upgrade(s) available! Please rollout the upgrade(s) to all tenant administrators, enabling them to access
          the latest version.
        </p>
      </ENTextPassage>

      {connectorVersion && (
        <CheckboxInput
          id="connector-version"
          name="connectorVersionCheck"
          label={`Rollout upgrade for Service Connector version ${connectorVersion}`}
          control={control}
          className={classes.version}
        />
      )}

      {radsecProxyVersion && (
        <CheckboxInput
          id="proxy-version"
          name="proxyVersionCheck"
          label={`Rollout upgrade for RadSec Proxy version ${radsecProxyVersion}`}
          control={control}
          className={classes.version}
        />
      )}

      <ErrorContainer
        message={error}
        open={!!error}
        handleClose={() => setError("")}
        errorClass={classes.errorContainer}
      />
    </Modal>
  )
}

export default RolloutUpgrade
